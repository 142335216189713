import styled from 'styled-components';
import { palette } from 'Core/Styles/Theme';
import { BodyContent } from 'Core/Styles/CommonStyles';

export const BodyContainer = styled.div`
  padding: 1.5rem 1.5rem 0;
  display: flex;
  min-height: calc(100vh - 8.5rem);
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.textAndLine.PlataBanreservas100};
  padding: 16px;
  background: ${palette.semantic.InfoBanreservas100};
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  width: 100%;

    @media only screen and (min-width: 375px) {
    // properties for bigger screens
    position: relative;
  }
`;

export const SummaryTitle = styled.div`
  color: var(--BanReserva-Brand-Azul-Banreserva-900, #1e3e5a);
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const SummaryDescription = styled.div`
  align-self: stretch;
  color: ${palette.semantic.InfoBanreservas700};
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  margin-top: 4px;
`;

export const SummaryAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: center;

  & span {
    align-self: stretch;
    color: ${palette.brand.AzulBanreservas100B};
    /* Display/MD/Bold */
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  & span:first-of-type {
    display: flex;
    width: 52px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const InfoIconContainer = styled.div`
    @media only screen and (min-width: 395px) {
      // properties for bigger screens
      position: absolute;
      top: 8px;
      right: 14px; 
    }

    @media only screen and (min-width: 375px) and (max-width: 394px) {
      /* Propiedades para pantallas más grandes en el rango de ancho especificado */
      position: absolute;
      top: 8px;
      right: 14px; 
    }
`;

export const PerformanceBodyContent = styled(BodyContent)`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const PageSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  font-family: 'Open Sans';
  font-style: normal;
`;
export const PageTitle = styled.div`
  color: ${palette.semantic.InfoBanreservas700};
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
`;
export const PageDescription = styled.div`
  color: ${palette.semantic.InfoBanreservas700};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;
export const PerformanceSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${palette.semantic.InfoBanreservas100};
`;
export const PerformanceData = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;

  &:first-of-type {
    border-right: 1px solid ${palette.semantic.InfoBanreservas100};
    padding-right: 7px;
  }

  &:last-of-type {
    border-left: 1px solid ${palette.semantic.InfoBanreservas100};
    padding-left: 7px;
  }
`;
export const DataTitle = styled.div`
  color: ${palette.semantic.InfoBanreservas700};
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
export const DataAmmount = styled.div`
  color: ${palette.semantic.InfoBanreservas700};
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
export const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
export const TableHeaders = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${palette.textAndLine.PlataBanreservas700B};
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  & span:nth-child(1) {
    flex: 1 0 0;
    text-align: left;
  }

  & span:nth-child(2) {
    flex: 1 0 0;
  }

  & span:nth-child(3) {
    flex: 1 0 0;
    text-align: right;
  }
`;
export const TableRows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
export const TableRow = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  border-bottom: 1px solid ${palette.textAndLine.PlataBanreservas100};  
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  font-weight: 400;
  color: ${palette.textAndLine.PlataBanreservas700B};

  & span:nth-child(1) {
    flex: 1 0 0;
    text-align: left;
  }

  & span:nth-child(2) {
    flex: 1 0 0;
    color: ${palette.brand.AzulBanreservas100B};
    font-weight: 600;
  }

  & span:nth-child(3) {
    flex: 1 0 0;
    text-align: right;
  }
`;
