import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const CopyIcon = ({ color }) => (
  <svg width="20" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.6 14.55C3.26667 14.55 2.98333 14.4333 2.75 14.2C2.51667 13.9666 2.4 13.6833 2.4 13.35V2.14995C2.4 1.81662 2.51667 1.53328 2.75 1.29995C2.98333 1.06662 3.26667 0.949951 3.6 0.949951H12.4C12.7333 0.949951 13.0167 1.06662 13.25 1.29995C13.4833 1.53328 13.6 1.81662 13.6 2.14995V13.35C13.6 13.6833 13.4833 13.9666 13.25 14.2C13.0167 14.4333 12.7333 14.55 12.4 14.55H3.6ZM3.6 13.35H12.4V2.14995H3.6V13.35ZM1.2 16.95C0.866667 16.95 0.583333 16.8333 0.35 16.6C0.116667 16.3666 0 16.0833 0 15.75V4.28995C0 4.11662 0.0566667 3.97328 0.17 3.85995C0.283333 3.74662 0.426667 3.68995 0.6 3.68995C0.773333 3.68995 0.916667 3.74662 1.03 3.85995C1.14333 3.97328 1.2 4.11662 1.2 4.28995V15.75H10.08C10.2533 15.75 10.3967 15.8066 10.51 15.92C10.6233 16.0333 10.68 16.1766 10.68 16.35C10.68 16.5233 10.6233 16.6666 10.51 16.78C10.3967 16.8933 10.2533 16.95 10.08 16.95H1.2ZM3.6 2.14995V13.35V2.14995Z"
      fill={color}
    />
  </svg>
);

CopyIcon.defaultProps = {
  color: palette.brand.AzulBanreservas100B,
};

CopyIcon.propTypes = {
  color: PropTypes.string,
};

export default CopyIcon;
