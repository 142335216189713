/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  Subtitle, Paragraph,
} from 'Core/Styles/CommonStyles';

export const PageTitle = styled(Subtitle)`
    &.MuiTypography-root{
        margin-bottom: 1.5rem;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }
`;

export const PageSubtitle = styled(Paragraph)`
    &.MuiTypography-root{
        margin-bottom: 2.5rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
    }
`;
