import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const SellIconComp = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M16.8468 20.12C16.9265 20.12 16.9996 20.1067 17.066 20.08C17.1324 20.0533 17.1988 20.0067 17.2653 19.94L19.6364 17.56C19.7825 17.4133 19.8888 17.25 19.9552 17.07C20.0216 16.89 20.0548 16.7 20.0548 16.5C20.0548 16.0733 19.9054 15.71 19.6065 15.41C19.3076 15.11 18.9456 14.96 18.5205 14.96C18.2549 14.96 17.9892 15.0467 17.7235 15.22C17.4579 15.3933 17.1656 15.6733 16.8468 16.06C16.528 15.6733 16.2358 15.3933 15.9701 15.22C15.7044 15.0467 15.4388 14.96 15.1731 14.96C14.748 14.96 14.3861 15.11 14.0872 15.41C13.7883 15.71 13.6389 16.0733 13.6389 16.5C13.6389 16.7 13.6721 16.89 13.7385 17.07C13.8049 17.25 13.9112 17.4133 14.0573 17.56L16.4284 19.94C16.4948 20.0067 16.5612 20.0533 16.6276 20.08C16.6941 20.1067 16.7671 20.12 16.8468 20.12ZM16.6874 24C16.528 24 16.3753 23.9733 16.2291 23.92C16.083 23.8667 15.9435 23.78 15.8107 23.66L8.33873 16.16C8.21918 16.0267 8.13284 15.8867 8.0797 15.74C8.02657 15.5933 8 15.44 8 15.28V9.2C8 8.85333 8.11291 8.56667 8.33873 8.34C8.56455 8.11333 8.85015 8 9.19552 8H15.2528C15.4122 8 15.5716 8.02333 15.731 8.07C15.8904 8.11667 16.0365 8.20667 16.1694 8.34L23.6015 15.8C23.8672 16.0667 24 16.3633 24 16.69C24 17.0167 23.8672 17.3133 23.6015 17.58L17.5442 23.66C17.4379 23.7667 17.3084 23.85 17.1557 23.91C17.0029 23.97 16.8468 24 16.6874 24ZM16.6874 22.8L22.7447 16.72L15.2528 9.2H9.19552V15.28L16.6874 22.8ZM11.2877 12.32C11.5666 12.32 11.809 12.2167 12.0149 12.01C12.2208 11.8033 12.3238 11.56 12.3238 11.28C12.3238 11 12.2208 10.7567 12.0149 10.55C11.809 10.3433 11.5666 10.24 11.2877 10.24C11.0087 10.24 10.7663 10.3433 10.5604 10.55C10.3545 10.7567 10.2516 11 10.2516 11.28C10.2516 11.56 10.3545 11.8033 10.5604 12.01C10.7663 12.2167 11.0087 12.32 11.2877 12.32Z"
      fill={color}
    />
  </svg>
);

SellIconComp.defaultProps = {
  color: palette.brand.AzulBanreservas100B,
};

SellIconComp.propTypes = {
  color: PropTypes.string,
};
export default SellIconComp;
