import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  VALIDATE_ACCOUNT_DATA_PAYLOAD,
  VALIDATE_ACCOUNT_DATA_RESPONSE,
} from 'Constants/ValidateAccount';
import { sethasAccount } from 'Core/Store/Reducers/clientInfoSlice';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import usePost from './usePost';

const useValidateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { document, documentType } = useSelector((state) => state.clientInfo);
  const [payload, setPayload] = useState();
  const [accountValidated, setAccountValidated] = useState(false);
  const isCalled = useRef(false);
  const { data, callApi } = usePost(
    endpoints.validateAccount,
    API_HEADERS(),
    false,
    VALIDATE_ACCOUNT_DATA_RESPONSE[200],
  );

  const getValidateAccount = () => {
    const validateDataPayload = {
      ...VALIDATE_ACCOUNT_DATA_PAYLOAD,
      documentNumber: document,
      documentType,
    };
    setPayload({ ...validateDataPayload });
  };

  useEffect(() => {
    if (payload?.documentNumber && isCalled.current === false) {
      callApi(payload);
      isCalled.current = true;
    }
  }, [payload]);

  useEffect(() => {
    if (data) {
      dispatch(sethasAccount(data?.ok));
      setAccountValidated(true);
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    accountValidated,
    getValidateAccount,
  };
};

export default useValidateAccount;
