/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ProductSelect from 'Core/Utils/Assets/product-sa.png';
import useValidate from 'Services/api/useValidate';
import Header from 'Components/Header';
import InfoModal from 'Components/InfoModal';
import { SpinnerText2 } from 'Constants/SpinnerText';
import SavingFareInfo from 'Components/SavingsFareInfo';
import getTariffDetails from 'Constants/SavingsFareInfo';
import { ContinueButton, BoxBodyContainer } from 'Core/Styles/CommonStyles';
import useValidateToken from 'Services/api/useValidateToken';
import useValidateAccount from 'Services/api/useValidateAccount';
import SellIcon from 'Core/Utils/Assets/SellIcon';
import LoadingScreen from 'Components/LoadingScreen';
import useGetAppStatus from 'Services/api/useGetAppStatus';
import useStep from 'Services/useStep';
import useValidateContract from 'Services/api/useValidateContract';
import DotIcon from 'Core/Utils/Assets/Dot';
import { palette } from 'Core/Styles/Theme';
import MoneyPaperIcon from 'Core/Utils/Assets/MoneyPaper';
import {
  OpenNewWindow, TypographyBullets, AcceptButton, CardActionsContainer,
  CardDescription, CardProduct, CardSubtitle, BoxTitle, Row,
  IconContainer,
  ButtonContainerSelectProduct,
  StyledBodyContainer as BodyContainer,
  CardHead,
  CardBody,
  InfoBox,
  InfoBoxContainer,
  InfoBoxSeparator,
} from './style';

const SelectProduct = () => {
  const [isOpen, setIsOpen] = useState(false);
  const analytics = getAnalytics();
  const { generateToken } = useStep();
  const { token } = useParams();
  const { isValidToken, validateToken } = useValidateToken(token);
  const { getValidate } = useValidate();
  const { accountValidated, getValidateAccount } = useValidateAccount();
  const { getContract } = useValidateContract();
  const { hasAccount } = useSelector((state) => state.clientInfo);
  const [isloading, setIsloading] = useState(true);
  const { appStatus, getAppStatus } = useGetAppStatus();

  useEffect(() => {
    if (appStatus?.available) {
      validateToken();
    }
    getAppStatus();
  }, [appStatus]);

  useEffect(() => {
    if (isValidToken) {
      getValidateAccount();
    }

    if (accountValidated) {
      setIsloading(false);
    }
  }, [isValidToken, accountValidated]);

  return (
    ((isValidToken && !isloading) ? (
      <>
        <Header title="Cuenta Digital Banreservas" showBackButton={false} />
        <BodyContainer style={{ paddingTop: '24px' }}>
          <CardProduct sx={{ maxWidth: 345 }}>
            <CardHead>
              <CardMedia
                component="img"
                height="auto"
                image={ProductSelect}
                alt="Select Product"
              />
            </CardHead>

            <CardContent sx={{
              paddingTop: '0',
              paddingLeft: '0',
              paddingRight: '0px',
              paddingBottom: '32px',
            }}
            >
              <CardBody>
                <CardSubtitle variant="h6">
                  ¡Ahorrar nunca había sido tan fácil!
                </CardSubtitle>
                <CardDescription>
                  Abre y gestiona tu Cuenta de Ahorro 100% Digital desde cualquier lugar.
                </CardDescription>
              </CardBody>
              <BoxBodyContainer style={{ marginTop: '32px', padding: '24px' }}>
                <BoxTitle variant="h6">
                  <SellIcon />
                  Beneficios
                </BoxTitle>
                <Row>
                  <IconContainer>
                    <DotIcon />
                  </IconContainer>
                  <TypographyBullets variant="body2" color="text.secondary">
                    Ábrela en pocos pasos.
                  </TypographyBullets>
                </Row>
                <Row>
                  <IconContainer>
                    <DotIcon />
                  </IconContainer>
                  <TypographyBullets variant="body2" color="text.secondary">
                    Sin cobro de comisiones.
                  </TypographyBullets>
                </Row>
                <Row>
                  <IconContainer style={{ marginTop: '16px' }}>
                    <DotIcon />
                  </IconContainer>
                  <TypographyBullets variant="body2" color="text.secondary">
                    Solicita en oficina tu tarjeta de débito y podrás realizar compras, depositar y retirar 24/7.
                  </TypographyBullets>
                </Row>
              </BoxBodyContainer>
            </CardContent>
            <InfoBox>
              <InfoBoxContainer>
                <BoxTitle variant="h6">
                  <MoneyPaperIcon />
                  Tarifas
                </BoxTitle>
              </InfoBoxContainer>
              <TypographyBullets variant="body2" color="text.secondary" style={{ marginBottom: '16px' }}>
                Conoce el tarifario de productos y servicios asociadas a este producto
              </TypographyBullets>
              <InfoBoxSeparator />
              <CardActionsContainer>
                <AcceptButton
                  size="small"
                  onClick={() => {
                    setIsOpen(true);
                    logEvent(analytics, 'View Tariff', {
                      content_type: 'Button - Open the Tariff Info',
                      content_id: 'Digital Sale - See Tariff',
                    });
                  }}
                >
                  Ver tarifario
                  <OpenNewWindow />
                </AcceptButton>
              </CardActionsContainer>
            </InfoBox>

          </CardProduct>
          <InfoModal
            buttonText="ACEPTAR"
            modalTitle="Tarifario Cuenta Digital"
            modalSubtitle="Cuenta de ahorro en pesos dominicanos."
            onButtonClick={() => {
              setIsOpen(false);
              logEvent(analytics, 'Accept Fare Info', {
                content_type: 'Button - Accept Fare Info Terms',
                content_id: 'Digital Sale - Accept Fare Info',
              });
            }}
            onClose={() => setIsOpen(false)}
            isOpen={isOpen}
          >
            <SavingFareInfo fareDetails={getTariffDetails(hasAccount)} />
          </InfoModal>
        </BodyContainer>
        <ButtonContainerSelectProduct>
          <ContinueButton
            style={{ maxWidth: 345 }}
            variant="contained"
            disableElevation
            onClick={() => {
              generateToken();
              getValidate();
              setIsloading(true);
              logEvent(analytics, 'SelectProduct', {
                content_type: 'Button - Select the Digital Account Product',
                content_id: 'Digital Sale - Start Digital Account',
              });
            }}
          >
            ABRIR CUENTA DIGITAL
          </ContinueButton>
        </ButtonContainerSelectProduct>
      </>
    ) : <LoadingScreen isOpen text={SpinnerText2} isWhite />)
  );
};

export default SelectProduct;
