/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useDepositAmount from 'Services/api/useDepositAmount';
import { setdepositAmount } from 'Core/Store/Reducers/clientInfoSlice';
import {
  Subtitle, BodyContainer, BodyContent, ContinueButton, ButtonContainer,
} from 'Core/Styles/CommonStyles';
import SelectInput from 'Components/SelectInput';
import { MONTH_SAVING } from 'Core/Utils/MockData/MonthIncome';
import useStep from 'Services/useStep';
import { StyledParagraph } from './style';

const validationSchema = Yup.object().shape({
  amount: Yup.string()
    .required('Selecciona ahorro aproximado.'),
});

const ClientFinancialData = () => {
  const [dirtyCheck, SetdirtyCheck] = useState(false);
  const { amount } = useSelector((state) => state.clientInfo);
  const { saveDepositAmount } = useDepositAmount();
  const { validatePath, generateToken } = useStep();
  const dispatch = useDispatch();
  const analytics = getAnalytics();

  useEffect(() => {
    validatePath();
  }, []);

  return (
    <Formik
      initialValues={{
        amount,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        generateToken();
        dispatch(setdepositAmount(values.amount));
        saveDepositAmount(values.amount);
        logEvent(analytics, 'Client Financial Data', {
          content_type: 'Button - Submit Monthly Save Ammount',
          content_id: 'Digital Sale - Monthly Save Ammount Value',
        });
      }}
    >
      {({
        dirty, isValid, handleSubmit,
      }) => {
        if (!dirtyCheck && (dirty || '')) {
          SetdirtyCheck(true);
        }
        return (
          <Form>
            <BodyContainer>
              <BodyContent>
                <Subtitle variant="body2">
                  ¡Comencemos!
                </Subtitle>
                <StyledParagraph>
                  Déjanos saber un estimado de cuánto
                  desearías ahorrar de manera mensual. Este
                  monto no es un compromiso, por lo que puede
                  ser un aproximado.
                </StyledParagraph>
                <SelectInput
                  label="¿Cuánto ahorrarías cada mes? *"
                  labelId="2"
                  name="amount"
                  data={MONTH_SAVING}
                  defaultHelperText="Valor aproximado"
                />
              </BodyContent>
            </BodyContainer>
            <ButtonContainer>
              <ContinueButton
                variant="contained"
                disableElevation
                disabled={!(isValid && dirtyCheck)}
                onClick={handleSubmit}
              >
                CONTINUAR
              </ContinueButton>
            </ButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ClientFinancialData;
