import React from 'react';
import { palette } from 'Core/Styles/Theme';

const LegalProvision = () => (
  <svg width="91" height="113" viewBox="0 0 91 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.2476 74.1765C56.4689 74.2192 56.6941 74.2406 56.92 74.2406C57.8114 74.2406 58.715 73.8987 59.3988 73.2148L86.6781 45.9233C88.0457 44.5557 88.0457 42.3333 86.6781 40.9657C85.3105 39.598 83.0881 39.598 81.7205 40.9657L56.9137 65.7724L51.681 60.5397C50.3134 59.1721 48.091 59.1721 46.7234 60.5397C45.3435 61.9074 45.3435 64.1298 46.7234 65.4974L54.4407 73.2147C54.8896 73.6636 55.4279 73.9651 56.0004 74.1194C56.0822 74.1414 56.1646 74.1604 56.2476 74.1765Z" fill="#F89420" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33.74 3.73817C34.6308 2.84736 35.839 2.34692 37.0988 2.34692H76.1846C78.8079 2.34692 80.9346 4.47357 80.9346 7.09692V35.0969C80.9346 37.168 79.2556 38.8469 77.1846 38.8469C75.1135 38.8469 73.4346 37.168 73.4346 35.0969V9.84692H41.2204C41.2398 10.0441 41.2498 10.2456 41.2498 10.4512V27.0969C41.2498 30.8248 38.2277 33.8469 34.4998 33.8469H17.9871C17.6229 33.8469 17.2716 33.8155 16.9346 33.7561V79.8469H73.4346V66.0969C73.4346 64.0259 75.1135 62.3469 77.1846 62.3469C79.2556 62.3469 80.9346 64.0259 80.9346 66.0969V82.5969C80.9346 85.2203 78.8079 87.3469 76.1846 87.3469H14.1846C11.5612 87.3469 9.43457 85.2203 9.43457 82.5969V30.0111C9.43457 28.7514 9.93502 27.5432 10.8258 26.6524L33.74 3.73817ZM48.2206 33.3878H62.5693C64.6544 33.3878 66.3358 31.6477 66.3358 29.4899C66.3358 27.3322 64.6544 25.592 62.5693 25.592H48.2206C46.158 25.592 44.4541 27.3322 44.4541 29.4899C44.4541 31.6477 46.1356 33.3878 48.2206 33.3878ZM27.8183 51.1829H62.569C64.654 51.1829 66.3355 49.4428 66.3355 47.2851C66.3355 45.1273 64.654 43.3872 62.569 43.3872H27.8183C25.7332 43.3872 24.0518 45.1273 24.0518 47.2851C24.0518 49.4428 25.7332 51.1829 27.8183 51.1829ZM27.8183 69.0015H36.1592C38.2442 69.0015 39.9257 67.2614 39.9257 65.1037C39.9257 62.9459 38.2442 61.2058 36.1592 61.2058H27.8183C25.7332 61.2058 24.0518 62.9459 24.0518 65.1037C24.0518 67.2614 25.7332 69.0015 27.8183 69.0015ZM22.1895 26.3469L33.7498 14.6988V26.3469H22.1895Z" fill="#00AEF0" />
    <ellipse cx="45.5" cy="109.5" rx="45" ry="3.5" fill="#F4F5F7" />
  </svg>
);

LegalProvision.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

export default LegalProvision;
