import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title, Text } from './style';

const MonthlyYieldAmount = ({ shownAmount }) => (
  <Container>
    <Title>Rendimiento mensual</Title>
    <Text>{shownAmount}</Text>
  </Container>
);

MonthlyYieldAmount.propTypes = {
  shownAmount: PropTypes.string.isRequired,
};

export default MonthlyYieldAmount;
