/*eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { ButtonContainer, ContinueButton } from 'Core/Styles/CommonStyles';
import { formatCurrency } from 'Core/Utils/UtilityFunctions';
import {
  CDSBodyContainer,
  CDSIconSectionContainer,
  CDSIconSubtitle,
  CDSAmountContainer,
  CDSFrame,
  CDSFrameSection,
  CDSFrameIcon,
  CDSFrameBody,
  CDSFrameBodyTitle,
  CDSFrameBodyContent,
  NumbersIconContainer,
  PercentIconContainer,
  CheckIconContainer,
  RendimientoIconContainer,
  CoinsIconContainer,
} from './style';
import LoadingScreen from 'Components/LoadingScreen';
import { SpinnerText2 } from 'Constants/SpinnerText';
import SuccessPage from 'Core/Utils/Assets/SuccessPage';
import { StyledSubtitle } from 'Components/OpeningConfirmation/style';
import { Divider } from 'Components/Header/style';
import Balance from 'Core/Utils/Assets/CD/Balance';
import Rendimiento from 'Core/Utils/Assets/CD/Rendimiento';
import RendimientoMensual from 'Core/Utils/Assets/CD/RendimientoMensual';
import Plazo from 'Core/Utils/Assets/CD/Plazo';
import Cuenta from 'Core/Utils/Assets/CD/Cuenta';
import moment from 'moment';
import { interestTypeMap, productTypeMap } from 'Core/Utils/Maps';

const DigitalCertificateSummary = () => {
  const analytics = getAnalytics();
  const { cdOpened, amount } = useSelector((state) => state.certificateDepositOffer);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    logEvent(analytics, 'Digital Certificate Obtained', {
      content_type: 'Loading Screen - Digital Certificate End Flow',
      content_id: 'Digital Certificate Summary Screen - Digital Certificate End Flow',
    });
  }, []);

  return !isloading ? (
    <>
      <Divider />
      <CDSBodyContainer>
        <CDSIconSectionContainer>
          <SuccessPage />
          <CDSIconSubtitle>
            <strong>¡Ya tienes tu Depósito a Plazo Digital!</strong>
            <br />
            <div>
              {cdOpened?.date
                ? moment((cdOpened?.date).replace(/\s/g, '')).format('DD MMMM YYYY - h:mm A')
                : moment().format('DD MMMM YYYY - h:mm A')}
            </div>
          </CDSIconSubtitle>
        </CDSIconSectionContainer>
        <CDSAmountContainer>
          <div>Monto invertido</div>
          <div>
            <span>DOP </span>
            {formatCurrency(amount)}
          </div>
        </CDSAmountContainer>
        <CDSFrame>
          <CDSFrameSection>
            <CDSFrameIcon>
              <CoinsIconContainer />
            </CDSFrameIcon>
            <CDSFrameBody>
              <CDSFrameBodyTitle>No. de producto:</CDSFrameBodyTitle>
              <CDSFrameBodyContent>{cdOpened?.certificateProductNumber}</CDSFrameBodyContent>
            </CDSFrameBody>
          </CDSFrameSection>
          <CDSFrameSection>
            <CDSFrameIcon>
              <RendimientoIconContainer />
            </CDSFrameIcon>
            <CDSFrameBody>
              <CDSFrameBodyTitle>Rendimiento total:</CDSFrameBodyTitle>
              <CDSFrameBodyContent>
                DOP {formatCurrency(cdOpened?.totalProfitability)}
              </CDSFrameBodyContent>
            </CDSFrameBody>
          </CDSFrameSection>
          {cdOpened?.profitType !== 'Capitalizable' && (
            <CDSFrameSection>
              <CDSFrameIcon>
                <RendimientoMensual />
              </CDSFrameIcon>
              <CDSFrameBody>
                <CDSFrameBodyTitle>Rendimiento Mensual:</CDSFrameBodyTitle>
                <CDSFrameBodyContent>DOP {cdOpened?.monthlyProfitability}</CDSFrameBodyContent>
              </CDSFrameBody>
            </CDSFrameSection>
          )}
          <CDSFrameSection>
            <CDSFrameIcon>
              <Plazo />
            </CDSFrameIcon>
            <CDSFrameBody>
              <CDSFrameBodyTitle>Plazo:</CDSFrameBodyTitle>
              <CDSFrameBodyContent>{cdOpened?.term} meses</CDSFrameBodyContent>
            </CDSFrameBody>
          </CDSFrameSection>
          <CDSFrameSection>
            <CDSFrameIcon>
              <PercentIconContainer />
            </CDSFrameIcon>
            <CDSFrameBody>
              <CDSFrameBodyTitle>Tasa anual:</CDSFrameBodyTitle>
              <CDSFrameBodyContent>{cdOpened?.rate}% interés</CDSFrameBodyContent>
            </CDSFrameBody>
          </CDSFrameSection>
          <CDSFrameSection>
            <CDSFrameIcon>
              <CheckIconContainer />
            </CDSFrameIcon>
            <CDSFrameBody>
              <CDSFrameBodyTitle>Tipo de interés:</CDSFrameBodyTitle>
              <CDSFrameBodyContent>{interestTypeMap[cdOpened?.profitType]}</CDSFrameBodyContent>
            </CDSFrameBody>
          </CDSFrameSection>
          <CDSFrameSection>
            <CDSFrameIcon>
              <Cuenta />
            </CDSFrameIcon>
            <CDSFrameBody>
              <CDSFrameBodyTitle>No. cuenta:</CDSFrameBodyTitle>
              <CDSFrameBodyContent>
                {productTypeMap[cdOpened?.productType]} {cdOpened?.productNumber}
              </CDSFrameBodyContent>
            </CDSFrameBody>
          </CDSFrameSection>
          <CDSFrameSection>
            <CDSFrameIcon>
              <NumbersIconContainer />
            </CDSFrameIcon>
            <CDSFrameBody>
              <CDSFrameBodyTitle>No. transacción:</CDSFrameBodyTitle>
              <CDSFrameBodyContent>{cdOpened?.trnId}</CDSFrameBodyContent>
            </CDSFrameBody>
          </CDSFrameSection>
        </CDSFrame>
      </CDSBodyContainer>
    </>
  ) : (
    <LoadingScreen isOpen text={SpinnerText2} isWhite />
  );
};

export default DigitalCertificateSummary;
