// eslint-disable-next-line import/no-extraneous-dependencies
import Hotjar from '@hotjar/browser';

const hotjarInit = () => {
  const { pathname } = window.location;
  const siteId = pathname.includes('/deposito') ? process.env.REACT_APP_DPD_HOTJAR_SITE
    : process.env.REACT_APP_HOTJAR_SITE;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
};

export default hotjarInit;
