import CheckIcon from '@mui/icons-material/Check';
import PercentIcon from '@mui/icons-material/Percent';
import styled from 'styled-components';
import { palette } from 'Core/Styles/Theme';
import Rendimiento from 'Core/Utils/Assets/CD/Rendimiento';
import { Paragraph } from 'Core/Styles/CommonStyles';

export const CDSFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

export const CDSFrameSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

export const ConfirmationFrame = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: ${palette.semantic.InfoBanreservas100};
  margin-bottom: 32px;
`;

export const ConfirmationTitle = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  color: ${palette.brand.AzulBanreservas700B};
  width: 100%;
`;
export const ConfirmationBody = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${palette.brand.AzulBanreservas900};
  width: 100%;
`;
export const ConfirmationAmmount = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: ${palette.brand.AzulBanreservas100B};
  width: 100%;
`;

export const CDSFrameIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${palette.semantic.InfoBanreservas700};
  }
`;

export const CDSFrameBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 89%;
  border-bottom: 1px solid ${palette.textAndLine.PlataBanreservas100};
  padding-bottom: 16px;
`;

export const CDSFrameBodyTitle = styled.div`
  color: ${palette.textAndLine.PlataBanreservas700B};
  /* Texto/MD/Bold Auto */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 137.5% */
`;

export const CDSFrameBodyContent = styled.div`
  color: ${palette.semantic.InfoBanreservas700};
  /* Texto/MD/Regular Auto */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const PercentIconContainer = styled(PercentIcon)`
  color: ${palette.brand.AzulBanreservas300};
  width: 20px !important;
  height: 20px !important;
`;

export const CheckIconContainer = styled(CheckIcon)`
  color: ${palette.brand.AzulBanreservas300};
  width: 20px !important;
  height: 20px !important;
`;

export const RendimientoIconContainer = styled(Rendimiento)`
  color: ${palette.brand.AzulBanreservas300};
  width: 20px !important;
  height: 20px !important;
`;

export const PageTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: ${palette.brand.AzulBanreservas700B};
  margin-bottom: 16px;
`;

export const PageSubtitle = styled(Paragraph)`
  &.MuiTypography-root {
    margin-bottom: 24px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${palette.brand.AzulBanreservas900};
  }
`;
