import styled from 'styled-components';
import { BodyContainer } from '../../Core/Styles/CommonStyles';

export const BodyContainerError = styled(BodyContainer)`
    height: calc(100vh - 4.5rem);
`;

export const TitleContainer = styled.div`
    text-align: center;
    width: 327px;
    margin-left: auto;
    margin-right: auto;
     
    @media screen and (min-width: 600px) {
        width: 410px;
    }
`;

export const ButtonContainer = styled.div`
`;

export const ErrorImageContainer = styled.div`
    margin: 40px 0px;
`;

export const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0;
    flex: 1;
`;

export const Container = styled.div`
width: 100%;
height: 46px;
position: relative;
`;
