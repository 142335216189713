import React from 'react';
import TextWithContact from 'Components/TextWithNumber';

export const errorMessage1 = {
  errorType: 1,
  title: '¡Vaya! Algo salió mal',
  text: 'Inténtalo nuevamente.',
  hasClick: true,
};

export const errorMessage2 = {
  errorType: 1,
  title: 'Ha ocurrido un error',
  text: 'En este momento estamos trabajando para solucionar este inconveniente lo antes posible, inténtalo nuevamente más tarde.',
  hasClick: false,
};

export const errorMessage3 = {
  errorType: 3,
  title: 'No podemos continuar con tu solicitud por esta vía.',
  hasClick: false,
  subText: 'Para más información por favor visita la oficina comercial de tu preferencia.',
};

export const errorMessage4 = {
  title: 'Balance insuficiente',
  text: 'Asegúrate que dispones de DOP 100.00 en tu cuenta de ahorro o corriente.',
  hasClick: false,
  subText: 'Por favor deposita e inténtalo nuevamente.',
};

export const errorMessage5 = {
  title: 'Ya tienes una Cuenta Digital Banreservas',
  text: 'No podemos continuar con tu solicitud porque ya posees el límite de cuentas permitidas.',
  hasClick: false,
  subText: <TextWithContact
    text="Para más información por favor llama a nuestro Centro de Contacto al *."
  />,
};

export const errorMessage6 = {
  errorType: 2,
  title: 'Tarifario no disponible',
  text: 'No es posible consultar el tarifario en este momento. Por favor, intenta nuevamente más tarde.',
  hasBackBtn: true,
  hasClick: false,
  hasSubheader: false,
  headerTitle: 'Simulador',
};

export const errorMessage7 = {
  errorType: 1,
  title: 'No podemos continuar con tu solicitud por esta vía',
  text: <TextWithContact
    text="Para más información sobre nuestros productos por favor comunícate con el Centro de Contacto al *."
  />,
  hasBackBtn: false,
  hasClick: false,
  hasSubheader: false,
};

export const errorMessage10 = {
  errorType: 4,
  title: '¡Estamos trabajando para brindarte una mejor solución!',
  subText: 'Nuestro sistema no está disponible por el momento, por favor inténtalo más tarde.',
  hasClick: false,
  hasSubheader: true,
};

export const errorMessage11 = {
  title: 'Balance insuficiente',
  text: 'Asegúrate que dispones de USD 2.00 en tu cuenta de ahorro.',
  hasClick: false,
  subText: 'Por favor deposita e inténtalo nuevamente.',
};

export const errorMessage12 = {
  title: 'Balance insuficiente',
  text: 'Asegúrate que dispones de EUR 2.00 en tu cuenta de ahorro.',
  hasClick: false,
  subText: 'Por favor deposita e inténtalo nuevamente.',
};

export const errorMessage13 = {
  errorType: 2,
  title: 'No podemos continuar con tu solicitud por esta vía.',
  text: 'Por favor inténtalo más tarde.',
  hasClick: false,
};

export const errorMessage14 = {
  errorType: 3,
  title: 'No podemos continuar con tu solicitud por esta vía.',
  hasClick: false,
  subText: 'Para completar la apertura de una Cuenta Digital necesitas tener una cuenta de ahorros o corriente activa.',
};

export const errorMessage15 = {
  errorType: 1,
  title: 'Ha ocurrido un error',
  hasClick: false,
  subText: 'En este momento estamos trabajando para solucionar este inconveniente lo antes posible, inténtalo nuevamente más tarde.',
};

export const errorMessage16 = {
  errorType: 2,
  title: 'Lo sentimos',
  text: 'No podemos responder a tu solicitud en este momento. Por favor inténtalo más tarde.',
  hasClick: false,
};
