/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CloseCirle from 'Core/Utils/Assets/CloseCircle';
import { palette } from 'Core/Styles/Theme';
import {
  DarkTooltip,
  LightTooltip,
  TooltipTitle,
  TooltipContainer,
  TooltipText,
  TitleContainer,
} from './styled';

const CustomTooltip = ({
  title,
  isDark,
  children,
  text,
  hasCloseButton,
}) => {
  const { brand: { AzulBanreservas700B }, textAndLine: { BlancoBanreservas000 } } = palette;
  const Tooltip = isDark ? DarkTooltip : LightTooltip;
  const color = isDark ? BlancoBanreservas000 : AzulBanreservas700B;
  const arrowRef = React.useRef();

  const [open, setOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      title={
        (
          <TooltipContainer>
            <TitleContainer>
              <TooltipTitle colorText={color}>{ title }</TooltipTitle>
              {hasCloseButton && (
                <IconButton data-testid="close-button" style={{ fontSize: 'inherit', padding: 0 }} onClick={handleTooltipClose}>
                  <CloseCirle color={color} />
                </IconButton>
              )}
            </TitleContainer>
            <TooltipText>{ text }</TooltipText>
          </TooltipContainer>
        )
      }
      arrow
      open={open}
      disableFocusListener
      disableHoverListener
      PopperProps={{
        disablePortal: true,
        modifiers: [
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 4,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 4,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ],
      }}
    >
      <IconButton data-testid="open-button" style={{ fontSize: 'inherit', padding: 0 }} ref={arrowRef} onClick={handleTooltipOpen} onBlur={handleTooltipClose}>{children}</IconButton>
    </Tooltip>
  );
};

CustomTooltip.defaultProps = {
  title: ' ',
  text: ' ',
  isDark: true,
  hasCloseButton: true,
};

CustomTooltip.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  isDark: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hasCloseButton: PropTypes.bool,
};

export default CustomTooltip;
