import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import { sethasAContract, setContractDate } from 'Core/Store/Reducers/clientInfoSlice';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import { CONTRACT_DATA_RESPONSE, CONTRACT_DATA_PAYLOAD } from 'Constants/ValidateContract';
import usePost from './usePost';

const useValidateContract = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { document, documentType } = useSelector((state) => state.clientInfo);
  const [payload, setPayload] = useState();
  const { data, callApi } = usePost(
    endpoints.validateAccount,
    API_HEADERS(),
    true,
    CONTRACT_DATA_RESPONSE[200],
  );

  const getContract = () => {
    const validateDataPayload = {
      ...CONTRACT_DATA_PAYLOAD,
      documentNumber: document,
      documentType,
    };
    setPayload({ ...validateDataPayload });
  };

  useEffect(() => {
    if (payload?.documentNumber) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data?.ok) {
      dispatch(sethasAContract(data?.hasAContract));
      dispatch(setContractDate(data?.contractDate));
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    data,
    getContract,
  };
};

export default useValidateContract;
