// eslint-disable-next-line import/prefer-default-export
export const MONTH_INCOME = [
  {
    id: 'Categoria1',
    label: 'Menor o igual a DOP 15,000',
    value: 'Menor o igual a DOP 15,000',
  },
  {
    id: 'Categoria2',
    label: 'DOP 15,001 - DOP 35,000',
    value: 'DOP 15,001 - DOP 35,000',
  },
  {
    id: 'Categoria3',
    label: 'DOP 35,001 - DOP 50,000',
    value: 'DOP 35,001 - DOP 50,000',
  },
  {
    id: 'Categoria4',
    label: 'DOP 50,001 - DOP 80,000',
    value: 'DOP 50,001 - DOP 80,000',
  },
  {
    id: 'Categoria5',
    label: 'DOP 80,001 - DOP 100,000',
    value: 'DOP 80,001 - DOP 100,000',
  },
  {
    id: 'Categoria6',
    label: 'DOP 100,001 - DOP 135,000',
    value: 'DOP 100,001 - DOP 135,000',
  },
  {
    id: 'Categoria7',
    label: 'DOP 135,001 - DOP 200,000',
    value: 'DOP 135,001 - DOP 200,000',
  },
  {
    id: 'Categoria8',
    label: 'Mayor o igual a DOP 200,000',
    value: 'Mayor o igual a DOP 200,000',
  },
];

export const MONTH_SAVING = [
  {
    id: 'Categoria1',
    label: 'Menos de DOP 15,000',
    value: 'DOP$ 15,000.00',
  },
  {
    id: 'Categoria2',
    label: 'DOP 15,001 - DOP 125,000',
    value: 'DOP$ 125,000.00',
  },
  {
    id: 'Categoria3',
    label: 'DOP 125,001 - DOP 200,000',
    value: 'DOP$ 200,000.00',
  },
  {
    id: 'Categoria4',
    label: 'Más de DOP 200,001',
    value: 'DOP$ 200,001.00',
  },
];
