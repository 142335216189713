import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import { palette } from 'Core/Styles/Theme';

export const BpIcon = styled('span')({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: `${palette.brand.AzulBanreservas100B}`,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
});

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: `${palette.brand.AzulBanreservas100B}`,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: `${palette.brand.AzulBanreservas100B}`,
  },
});

export const RadioContainer = styled.div`
    border-radius: 8px;
    color: ${palette.brand.AzulBanreservas700B};
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: bold;
    height: 4rem;
    justify-content: flex-start;
    margin: 0  0 1.5rem 0;
    padding: 0 22px;
    text-transform: initial;
    background-color: ${palette.semantic.InfoBanreservas100};
    &:hover {
        background-color: ${palette.semantic.InfoBanreservas100};
    }

    & label {
      width: 100%;
      color: ${palette.brand.AzulBanreservas700B};
      margin-bottom: 0;
      & span {
        font-size: 1rem;
        line-height: 22px;
        font-weight: 700;
      }
    }
`;

export const StyledChip = styled(Chip)`
    background-color: ${palette.brand.AzulBanreservas100B};
    color: ${palette.textAndLine.BlancoBanreservas000};
    margin: 1.25rem 0;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
`;

export const DarkStyledChip = styled(Chip)`
    background-color: ${palette.brand.AzulBanreservas700B};
    color: ${palette.textAndLine.BlancoBanreservas000};
    margin: 1.25rem 0;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
`;
