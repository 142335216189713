/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  setDocument,
  setDocumentType,
  setTerminal,
  setUser,
  setAuthorization,
} from 'Core/Store/Reducers/clientInfoSlice';
import endpoints from 'Core/Utils/ApiEndpoints';
import { setCD } from 'Core/Store/Reducers/certificateDepositOfferSlice';
import usePost from './usePost';

const { REACT_APP_ENVIRONMENT, REACT_APP_CD_SEND_MS_OTP } = process.env;

const mockDataForDevEnv = {
  documentNumber: '40239753144',
  documentType: 'CEDULA',
  terminal: 'android 13 | 8.151.22.10.18 | Man',
  user: 'sfrances',
};

const useValidateToken = (token, documentType = '', documentNumber = '', terminal = '') => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isValidToken, setValidToken] = useState(false);
  const currentURL = window.location.href;

  const { data, callApi } = usePost(
    (documentType !== '' && REACT_APP_CD_SEND_MS_OTP === 'true'
      ? `${endpoints.validateAuthorization}${token}&documentType=${documentType}&documentNumber=${documentNumber}`
      : endpoints.validateToken + token),
    API_HEADERS(true),
    REACT_APP_ENVIRONMENT === 'DEV',
    REACT_APP_ENVIRONMENT === 'DEV' ? mockDataForDevEnv : {},
  );

  const validateToken = () => {
    callApi({});
  };

  useEffect(() => {
    if (data) {
      const shouldSetCD = currentURL.includes('deposito');
      const shouldSetToken = shouldSetCD && documentType !== '' && REACT_APP_CD_SEND_MS_OTP === 'true';

      if (shouldSetCD) {
        dispatch(setCD(true));
      }

      if (shouldSetToken) {
        dispatch(setAuthorization(token));
      }

      dispatch(setDocument(shouldSetToken ? documentNumber : data.documentNumber));
      dispatch(setDocumentType(shouldSetToken ? documentType : data.documentType));
      dispatch(setTerminal(shouldSetToken ? terminal : data.terminal));
      dispatch(setUser(data.user ?? ''));

      setValidToken(true);
    } else if (data !== undefined) {
      navigate('/validationError');
    }
  }, [data]);

  return {
    isValidToken,
    validateToken,
  };
};

export default useValidateToken;
