const DIGITAL_FARE = {
  openingAmount: 100.00,
  balanceMinimum: 0.00,
  monthlyChargeForMinimumBalance: 0.00,
  monthlyChargeForDebitCard: 20.00,
};

const ONBOARDING_FARE = {
  openingAmount: 0.00,
  balanceMinimum: 0.00,
  monthlyChargeForMinimumBalance: 0.00,
  monthlyChargeForDebitCard: 20.00,
};

const getFareDetails = (useDigitalFare) => {
  const tariff = useDigitalFare ? DIGITAL_FARE : ONBOARDING_FARE;

  return [
    {
      name: 'Monto de apertura',
      fare: tariff.openingAmount,
    },
    {
      name: 'Balance mínimo',
      fare: tariff.balanceMinimum,
    },
    {
      name: 'Cargo mensual por balance mínimo',
      fare: tariff.monthlyChargeForMinimumBalance,
    },
    {
      name: 'Cargo mensual por tarjeta de débito*',
      fare: tariff.monthlyChargeForDebitCard,
    },
  ];
};

export default getFareDetails;
