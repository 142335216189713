/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { store } from 'Core/Store/store';
import firebaseConfig from 'Core/Config/DBFirebase/firebaseConfig';
import hotjarInit from 'Core/Config/Hotjar/hotjarConfig';

const InitialConfigs = ({ children }) => {
  const rrfProps = {
    firebaseConfig,
    config: {
      userProfile: 'users',
    },
    dispatch: store.dispatch,
  };

  hotjarInit();
  const { pathname } = window.location;
  const title = pathname.includes('/deposito') ? 'Depósito a Plazo Digital' : 'Venta Digital';

  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider firebase={firebaseConfig} {...rrfProps}>
        <title>{title}</title>
        {children}
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default InitialConfigs;
