/* eslint-disable quote-props */
export const SAVE_ACCOUNT_DATA_PAYLOAD = {
  documentNumber: '',
};

export const SAVE_ACCOUNT_DATA_RESPONSE = {
  '200': {
    ok: true,
    accountNumber: '123456789',
  },
  '400': {
    description: 'Business error',
  },
  '500': {
    description: 'Server error',
  },
};
