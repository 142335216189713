/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled as styledmui } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import styled from 'styled-components';

export const LightTooltip = styledmui(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
    fontSize: 11,
    padding: 16,
    margin: '4px !important',
    maxWidth: 268,
  },
}));

export const DarkTooltip = styledmui(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 16,
    margin: '4px !important',
    maxWidth: 268,
  },
}));

export const TooltipContainer = styledmui(Typography)`
    & .MuiTypography-root {
        font-family: 'Open Sans';
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
    }
`;

export const TooltipTitle = styledmui(Typography)`
    &.MuiTypography-root {
        font-weight: 700;
        color: ${(props) => props.colorText};
    }
`;

export const TooltipText = styledmui(Typography)`
    &.MuiTypography-root {
        font-weight: 400;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
