import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  EXCHANGE_DATA_PAYLOAD,
  EXCHANGE_DATA_RESPONSE,
} from 'Constants/Exchange';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import usePost from './usePost';

const useExchange = () => {
  const navigate = useNavigate();
  const { data, callApi } = usePost(
    endpoints.exchange,
    API_HEADERS(),
    false,
    EXCHANGE_DATA_RESPONSE[200],
    true,
  );

  const [exchangeRate, setExchangeRate] = useState({
    divisa: '',
    tasa: -1,
  });
  let currencyDivisa;
  const getExchangeData = (tipoDivisa) => {
    currencyDivisa = tipoDivisa;
    const exchangeDataPayload = {
      ...EXCHANGE_DATA_PAYLOAD,
      divisa: {
        tipo: tipoDivisa,
      },
    };
    callApi(exchangeDataPayload);
  };

  useEffect(() => {
    if (data?.tasa) {
      setExchangeRate({
        divisa: currencyDivisa,
        tasa: data.tasa,
      });
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    exchangeRate,
    getExchangeData,
  };
};

export default useExchange;
