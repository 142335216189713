import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  SELECT_CARD_DATA_PAYLOAD,
  SELECT_CARD_DATA_RESPONSE,
  SELECT_ACCOUNT_DATA_PAYLOAD,
} from 'Constants/SelectCard';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import usePost from './usePost';

const { REACT_APP_ENVIRONMENT } = process.env;

const useSelectCard = () => {
  const navigate = useNavigate();
  const {
    document,
    documentType,
    authorization,
  } = useSelector((state) => state.clientInfo);
  const { CD } = useSelector((state) => state.certificateDepositOffer);
  const [payload, setPayload] = useState();
  const { data, callApi } = usePost(
    endpoints.accountSelect,
    {
      ...API_HEADERS(),
      Authorization: `Bearer ${authorization}`,
    },
    REACT_APP_ENVIRONMENT === 'DEV',
    SELECT_CARD_DATA_RESPONSE[200],
  );

  const getSelectCard = (accountId) => {
    const firmaDataPayload = CD
      ? {
        ...SELECT_ACCOUNT_DATA_PAYLOAD,
        documentNumber: document,
        documentType,
        accountId,
      }
      : {
        ...SELECT_CARD_DATA_PAYLOAD,
        documentNumber: document,
        documentType,
        accountId,
      };
    setPayload({ ...firmaDataPayload });
  };

  useEffect(() => {
    if (payload?.documentNumber) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data?.status) {
      if (CD) {
        navigate('/certificateConfirmation');
      } else {
        navigate('/termsAndConditions');
      }
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    data,
    getSelectCard,
  };
};

export default useSelectCard;
