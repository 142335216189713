import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Chevron2Up = ({ color }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2764 13.6354C10.0419 13.8102 9.70849 13.7916 9.49501 13.579C9.26019 13.3452 9.25937 12.9653 9.49318 12.7305L12.27 9.94166L12.3373 9.88327C12.5726 9.70808 12.9072 9.72754 13.1204 9.94166L15.8972 12.7305L15.9551 12.7979C16.1288 13.0332 16.1088 13.3665 15.8953 13.579L15.8279 13.6369C15.5926 13.8107 15.2593 13.7906 15.0468 13.5772L12.6944 11.216L10.3435 13.5772L10.2764 13.6354Z"
      fill={color}
    />
  </svg>

);

Chevron2Up.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

Chevron2Up.propTypes = {
  color: PropTypes.string,
};

export default Chevron2Up;
