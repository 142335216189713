/*eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  BodyContainer,
  BodyContent,
  ButtonContainer,
  ContinueButton,
} from 'Core/Styles/CommonStyles';
import { formatCurrency } from 'Core/Utils/UtilityFunctions';
import {
  CDSFrame,
  CDSFrameSection,
  CDSFrameIcon,
  CDSFrameBody,
  CDSFrameBodyTitle,
  CDSFrameBodyContent,
  PercentIconContainer,
  CheckIconContainer,
  RendimientoIconContainer,
  ConfirmationFrame,
  ConfirmationTitle,
  ConfirmationBody,
  ConfirmationAmmount,
  PageTitle,
  PageSubtitle,
} from './style';
import LoadingScreen from 'Components/LoadingScreen';
import { SpinnerText2 } from 'Constants/SpinnerText';
import RendimientoMensual from 'Core/Utils/Assets/CD/RendimientoMensual';
import Plazo from 'Core/Utils/Assets/CD/Plazo';
import Cuenta from 'Core/Utils/Assets/CD/Cuenta';
import Header from 'Components/Header';
import { useNavigate } from 'react-router-dom';
import { interestTypeMap } from 'Core/Utils/Maps';

const DigitalCertificateConfirmation = () => {
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const { cdOpened, amount, availableAccounts, cdSelectedAccount } = useSelector(
    (state) => state.certificateDepositOffer,
  );
  const [isloading, setIsloading] = useState(false);

  const getAccountLabel = (accountValue) => {
    return availableAccounts.find((account) => account.value === accountValue).label;
  };

  return !isloading ? (
    <>
      <Header title="Confirmar datos" />
      <BodyContainer>
        <BodyContent>
          <PageTitle>¡Ya casi terminamos!</PageTitle>
          <PageSubtitle>
            Valida que las condiciones de tu Depósito a Plazo Digital sean las deseadas.
          </PageSubtitle>

          <ConfirmationFrame>
            <ConfirmationTitle>Capital + Rendimientos</ConfirmationTitle>
            <ConfirmationBody>
              Tu tasa será de <strong>{(+cdOpened?.rate).toFixed(2)}%</strong> interés anual.
            </ConfirmationBody>
            <ConfirmationAmmount>
              DOP {formatCurrency(cdOpened?.totalProfitability)}
            </ConfirmationAmmount>
            <ConfirmationBody>El cálculo no contempla retención de impuestos.</ConfirmationBody>
          </ConfirmationFrame>

          <CDSFrame>
            <CDSFrameSection>
              <CDSFrameIcon>
                <RendimientoMensual />
              </CDSFrameIcon>
              <CDSFrameBody>
                <CDSFrameBodyTitle>Monto a invertir:</CDSFrameBodyTitle>
                <CDSFrameBodyContent>DOP {formatCurrency(amount)}</CDSFrameBodyContent>
              </CDSFrameBody>
            </CDSFrameSection>
            {cdOpened?.profitType !== 'CAP' && (
              <CDSFrameSection>
                <CDSFrameIcon>
                  <RendimientoMensual />
                </CDSFrameIcon>
                <CDSFrameBody>
                  <CDSFrameBodyTitle>Rendimiento mensual:</CDSFrameBodyTitle>
                  <CDSFrameBodyContent>DOP {cdOpened?.monthlyProfitability}</CDSFrameBodyContent>
                </CDSFrameBody>
              </CDSFrameSection>
            )}
            <CDSFrameSection>
              <CDSFrameIcon>
                <Plazo />
              </CDSFrameIcon>
              <CDSFrameBody>
                <CDSFrameBodyTitle>Plazo:</CDSFrameBodyTitle>
                <CDSFrameBodyContent>{cdOpened?.term} meses</CDSFrameBodyContent>
              </CDSFrameBody>
            </CDSFrameSection>
            <CDSFrameSection>
              <CDSFrameIcon>
                <CheckIconContainer />
              </CDSFrameIcon>
              <CDSFrameBody>
                <CDSFrameBodyTitle>Tipo de interés:</CDSFrameBodyTitle>
                <CDSFrameBodyContent>{interestTypeMap[cdOpened?.profitType]}</CDSFrameBodyContent>
              </CDSFrameBody>
            </CDSFrameSection>
            <CDSFrameSection>
              <CDSFrameIcon>
                <Cuenta />
              </CDSFrameIcon>
              <CDSFrameBody>
                <CDSFrameBodyTitle>Cuenta a debitar:</CDSFrameBodyTitle>
                <CDSFrameBodyContent>{getAccountLabel(cdSelectedAccount)}</CDSFrameBodyContent>
              </CDSFrameBody>
            </CDSFrameSection>
          </CDSFrame>
        </BodyContent>
      </BodyContainer>
      <ButtonContainer>
        <ContinueButton
          variant="contained"
          disableElevation
          onClick={() => {
            logEvent(analytics, 'Confirmation', {
              content_type: 'Button - Continue Confirmation ',
              content_id:
                'Continue Confirmation for the Digital Certificate and transition into Terms and Conditions Screen',
            });
            navigate('/termsAndConditionsSummary');
          }}
        >
          CONTINUAR
        </ContinueButton>
      </ButtonContainer>
    </>
  ) : (
    <LoadingScreen isOpen text={SpinnerText2} isWhite />
  );
};

export default DigitalCertificateConfirmation;
