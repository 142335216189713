import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
import { setSteps, setCurrentStep } from 'Core/Store/Reducers/appRouterSlice';

const useStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigationType = useNavigationType();

  const { step, initialStep, currentStep } = useSelector((state) => state.routerData);

  const validatePath = () => {
    if (step || navigationType === 'POP') {
      dispatch(setCurrentStep(location.pathname));
    } else if (currentStep !== location.pathname) {
      navigate(initialStep);
    }
  };

  const generateToken = () => {
    dispatch(setSteps(true));
  };

  return {
    validatePath,
    generateToken,
  };
};

export default useStep;
