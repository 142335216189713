import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Caducidad = ({ color }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Navegation/Caducidad">
      <path
        id="event_busy"
        d="M14.36 21.36L13.24 20.24L15.08 18.4L13.24 16.56L14.36 15.44L16.2 17.28L18.04 15.44L19.16 16.56L17.32 18.4L19.16 20.24L18.04 21.36L16.2 19.52L14.36 21.36ZM10.6 24C10.16 24 9.78333 23.8433 9.47 23.53C9.15667 23.2167 9 22.84 9 22.4V11.2C9 10.76 9.15667 10.3833 9.47 10.07C9.78333 9.75667 10.16 9.6 10.6 9.6H11.4V8H13V9.6H19.4V8H21V9.6H21.8C22.24 9.6 22.6167 9.75667 22.93 10.07C23.2433 10.3833 23.4 10.76 23.4 11.2V22.4C23.4 22.84 23.2433 23.2167 22.93 23.53C22.6167 23.8433 22.24 24 21.8 24H10.6ZM10.6 22.4H21.8V14.4H10.6V22.4ZM10.6 12.8H21.8V11.2H10.6V12.8Z"
        fill={color}
      />
    </g>
  </svg>
);

Caducidad.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

Caducidad.propTypes = {
  color: PropTypes.string,
};

export default Caducidad;
