/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import {
  CustomDialog, CustomDialogActions, DialogTitle, DialogSubTitle, IconContainer,
} from './style';

export default function AlertDialog({
  dialogIcon,
  title,
  subtitle,
  onClose,
  isOpen,
  onClick,
}) {
  return (
    <div>
      <CustomDialog
        open={isOpen}
        onClose={onClose}
      >

        <IconContainer>
          { dialogIcon }
        </IconContainer>

        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogSubTitle id="alert-dialog-description">
            {subtitle}
          </DialogSubTitle>
        </DialogContent>
        <CustomDialogActions>
          <Button data-testid="submit" onClick={onClose}>CERRAR</Button>
          <Button
            type="submit"
            onClick={onClick}
            autoFocus
          >
            CONTINUAR
          </Button>
        </CustomDialogActions>
      </CustomDialog>
    </div>
  );
}

AlertDialog.propTypes = {
  dialogIcon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
