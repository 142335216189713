/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  didAcceptTerms: false,
};

const termsAndConditionsSlice = createSlice({
  name: 'termsAndConditionsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetState: (state) => INITIAL_STATE,
    acceptTerms: (state) => {
      state.didAcceptTerms = true;
    },
  },
});

const { actions, reducer } = termsAndConditionsSlice;

export const { resetState, acceptTerms } = actions;

export default reducer;
