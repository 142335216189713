import styled from 'styled-components';

// Base styles for text components
const baseTextStyle = `
    align-self: stretch;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    line-height: 20px;
    word-wrap: break-word;
`;

// Container
const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px;
    margin-bottom: 8px;
    background: white;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
`;

const Title = styled.span`
    ${baseTextStyle}
    color: #264e72;
    font-weight: 600;
`;

const Text = styled.span`
    ${baseTextStyle}
    color: #626266;
    font-weight: 400;
`;

export { Container, Title, Text };
