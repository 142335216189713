/* eslint-disable quote-props */
export const PERFORMANCE_TABLE_DATA_PAYLOAD = {
  amount: 0,
  term: 0,
  rate: 0,
};

export const PERFORMANCE_TABLE_DATA_RESPONSE = {
  200: {
    status: true,
    message: 'OK',
    date: '2024-01-31 T 12:06:18',
    totalProfitability: 10102.7,
    amortization: [
      {
        term: 1,
        profitability: 100.35,
        total: 10100.35,
      },
      {
        term: 2,
        profitability: 102.35,
        total: 10102.7,
      },
    ],
  },
  400: {
    description: 'Business error',
  },
  500: {
    description: 'Server error',
  },
};
