/* eslint-disable no-unused-vars */
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import { palette } from 'Core/Styles/Theme';

// eslint-disable-next-line import/prefer-default-export
export const StyledFormControl = styled(FormControl)`
    margin: 8px 0 17px;
    width: 100%;

    .MuiInputLabel-root {
        top: -0.2rem;
    }
    .MuiInputLabel-shrink {
        line-height: 2rem;
    }
    .MuiInputBase-root {
        height: 3rem;
    }
    .MuiSvgIcon-root {
        margin-right: 5px;
    }
    .MuiBox-root {
        color: ${palette.textAndLine.PlataBanreservas700B};
    }
`;

export const StyledSelect = styled(Select)`
    .MuiSelect-select.MuiOutlinedInput-notchedOutline.Mui-focused {
        border-color: ${palette.semantic.ErrorBanreservas700};
    }
`;
