import TextField from '@mui/material/TextField';
import styled from 'styled-components';

import { palette } from 'Core/Styles/Theme';

const StyledTextField = styled(TextField)`
    margin-bottom: 1rem;

    & .MuiInputBase-input {
        height: 2rem;
    }
    & .MuiFormHelperText-root {
        text-align: ${(props) => (!props.error && (props.name === 'email' || props.name === 'amount') ? 'left' : 'right')};

        &.Mui-error {
            text-align: left;
        }
    }
    & .MuiFormLabel-root {
        line-height: 2rem;
    }
    & .Mui-error {
        & .MuiOutlinedInput-notchedOutline {
            border-color: #E43231;
        }
    }
    & .MuiInputLabel-root {
        &.Mui-error {
            color: #00AEF0;
        }
        &.Mui-error {
            color: #E43231;
        }
    }
    & .MuiLabelWithIcon {
        left: 22px;

        &.MuiFormLabel-filled, &.Mui-focused {
            left: 0;
        }
    }
    .MuiSvgIcon-root {
        margin-right: 5px;
    }
    .MuiInputBase-input {
        color: ${palette.textAndLine.PlataBanreservas700B};
    }
`;

export default StyledTextField;
