import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Rendimiento = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Productos/Certificado dep&#195;&#179;sito">
      <g id="Union">
        <path
          d="M12.5 13.6H10C9.77333 13.6 9.58333 13.5233 9.43 13.37C9.27667 13.2167 9.2 13.0267 9.2 12.8C9.2 12.5733 9.27667 12.3833 9.43 12.23C9.58333 12.0767 9.77333 12 10 12H12.5C12.5 12.23 12.5 12.45 12.5 12.74C12.5 13.03 12.5 13.2 12.5 13.6Z"
          fill={color}
        />
        <path
          d="M12.5 16.8H10C9.77333 16.8 9.58333 16.7233 9.43 16.57C9.27667 16.4167 9.2 16.2267 9.2 16C9.2 15.7733 9.27667 15.5833 9.43 15.43C9.58333 15.2767 9.77333 15.2 10 15.2H12.5C12.5 15.43 12.5 15.7333 12.5 16C12.5 16.2667 12.5 16.57 12.5 16.8Z"
          fill={color}
        />
        <path
          d="M6.47 19.53C6.78333 19.8433 7.16 20 7.6 20H12.5C12.5 19.7209 12.5 19.53 12.5 19.26C12.5 18.99 12.5 18.8 12.5 18.4H7.6V5.6H13.2V8.8C13.2 9.02667 13.2767 9.21667 13.43 9.37C13.5833 9.52333 13.7733 9.6 14 9.6H17.2V10.64C17.48 10.64 17.7 10.64 18.02 10.64C18.34 10.64 18.57 10.64 18.8 10.64V9.46C18.8 9.24667 18.76 9.04333 18.68 8.85C18.6 8.65667 18.4867 8.48667 18.34 8.34L14.46 4.46C14.3133 4.31333 14.1433 4.2 13.95 4.12C13.7567 4.04 13.5533 4 13.34 4H7.6C7.16 4 6.78333 4.15667 6.47 4.47C6.15667 4.78333 6 5.16 6 5.6V18.4C6 18.84 6.15667 19.2167 6.47 19.53Z"
          fill={color}
        />
        <path
          d="M15.6 19.6V19.2H14.8C14.5733 19.2 14.3833 19.1233 14.23 18.97C14.0767 18.8167 14 18.6267 14 18.4C14 18.1733 14.0767 17.9833 14.23 17.83C14.3833 17.6767 14.5733 17.6 14.8 17.6H17.2V16.8H14.8C14.5733 16.8 14.3833 16.7233 14.23 16.57C14.0767 16.4167 14 16.2267 14 16V13.6C14 13.3733 14.0767 13.1833 14.23 13.03C14.3833 12.8767 14.5733 12.8 14.8 12.8H15.6V12.4C15.6 12.2933 15.64 12.2 15.72 12.12C15.8 12.04 15.8933 12 16 12H16.8C16.9067 12 17 12.04 17.08 12.12C17.16 12.2 17.2 12.2933 17.2 12.4V12.8H18C18.2267 12.8 18.4167 12.8767 18.57 13.03C18.7233 13.1833 18.8 13.3733 18.8 13.6C18.8 13.8267 18.7233 14.0167 18.57 14.17C18.4167 14.3233 18.2267 14.4 18 14.4H15.6V15.2H18C18.2267 15.2 18.4167 15.2767 18.57 15.43C18.7233 15.5833 18.8 15.7733 18.8 16V18.4C18.8 18.6267 18.7233 18.8167 18.57 18.97C18.4167 19.1233 18.2267 19.2 18 19.2H17.2V19.6C17.2 19.7067 17.16 19.8 17.08 19.88C17 19.96 16.9067 20 16.8 20H16C15.8933 20 15.8 19.96 15.72 19.88C15.64 19.8 15.6 19.7067 15.6 19.6Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

Rendimiento.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

Rendimiento.propTypes = {
  color: PropTypes.string,
};

export default Rendimiento;
