const {
  REACT_APP_API_URL,
  REACT_APP_API_ENDPOINTS,
  REACT_APP_TOKEN_VALIDATION,
  REACT_APP_TOKEN_AUTHORIZATION,
} = process.env;

const AppURL = REACT_APP_API_URL + REACT_APP_API_ENDPOINTS;
const tokenURL = `${REACT_APP_API_URL + REACT_APP_TOKEN_VALIDATION}?token=`;
const tokenAuthorizationURL = `${REACT_APP_API_URL + REACT_APP_TOKEN_AUTHORIZATION}?token=`;

const endpoints = {
  saveAccount: `${AppURL}client/exist/account/save`,
  validate: `${AppURL}client/valid`,
  validateCertificate: `${AppURL}client/valid/certificate`,
  accountSelect: `${AppURL}client/account/select`,
  depositAmount: `${AppURL}client/depositAmount`,
  cDParameters: `${AppURL}certificate/parameters`,
  maximumProductAmmount: `${AppURL}product/maximum-product-amount`,
  getTariff: `${AppURL}certificate/getTariff`,
  cardData: `${AppURL}product/list`,
  performanceTable: `${AppURL}certificate/amortization`,
  client: `${AppURL}client`,
  exchange: `${AppURL}client/exist/account/exchange`,
  findContract: `${AppURL}client`,
  firma: `${AppURL}client/exist/contract/save`,
  cdTermsCond: `${AppURL}certificate/open`,
  createClient: `${AppURL}client/create`,
  generateContract: `${AppURL}client/sign`,
  validateBiometric: `${AppURL}client/validate`,
  validateDocumentNumber: `${AppURL}client`,
  validateToken: `${tokenURL}`,
  validateAuthorization: `${tokenAuthorizationURL}`,
  appStatus: `${AppURL}client/health`,
  digitalAccountSurvey: `${AppURL}survey/digital-account`,
  validateAccount: `${AppURL}client/hasAccount`,
  validateContract: `${AppURL}client/hasAContract`,

};

export default endpoints;
