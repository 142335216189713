import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  DEPOSIT_DATA_PAYLOAD,
  DEPOSIT_DATA_RESPONSE,
} from 'Constants/depositAmount';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import usePut from './usePut';

const useDepositAmount = () => {
  const navigate = useNavigate();
  const { document, documentType, hasAccount } = useSelector((state) => state.clientInfo);
  const [payload, setPayload] = useState();
  const { data, callApi } = usePut(
    endpoints.depositAmount,
    API_HEADERS(),
    false,
    DEPOSIT_DATA_RESPONSE[200],
  );

  const saveDepositAmount = (amount) => {
    const deposit = amount.replace('DOP$ ', '').replace(',', '');
    const DepositDataPayload = {
      ...DEPOSIT_DATA_PAYLOAD,
      documentNumber: document,
      documentType,
      monthlyAmount: Number(deposit),
    };
    setPayload({ ...DepositDataPayload });
  };

  useEffect(() => {
    if (payload?.monthlyAmount) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data) {
      if (data.message === 'client not found') {
        navigate('/clientInfoError');
      } else if (hasAccount) {
        console.log('Send data incorrectly');
        navigate('/FirstDeposit');
      } else {
        navigate('/termsAndConditions');
      }
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    data,
    saveDepositAmount,
  };
};

export default useDepositAmount;
