import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import { CARDS_DATA_PAYLOAD, CARDS_DATA_RESPONSE } from 'Constants/Cards';
import endpoints from 'Core/Utils/ApiEndpoints';
import { formatData, searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import { setAvailableAccounts } from 'Core/Store/Reducers/certificateDepositOfferSlice';
import useGet from './useGet';

const { REACT_APP_ENVIRONMENT } = process.env;

const useCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { amount } = useSelector((state) => state.certificateDepositOffer);
  const {
    document,
    documentType,
    authorization,
  } = useSelector((state) => state.clientInfo);
  const [payload, setPayload] = useState();
  const cardsUrl = CARDS_DATA_PAYLOAD(document, documentType, amount);
  const { data, callApi } = useGet(
    endpoints.cardData + cardsUrl,
    {
      ...API_HEADERS(),
      Authorization: `Bearer ${authorization}`,
    },
    REACT_APP_ENVIRONMENT === 'DEV',
    CARDS_DATA_RESPONSE[200],
  );

  const getCards = () => {
    setPayload(cardsUrl);
  };

  useEffect(() => {
    if (payload) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data) {
      if (amount > 0) {
        dispatch(setAvailableAccounts(formatData(data.products)));
        navigate('/accountSelection');
      }
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    cards: data,
    getCards,
  };
};

export default useCard;
