/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { Subtitle, BodyContainer, Paragraph } from 'Core/Styles/CommonStyles';

export const TermBodyContainer = styled(BodyContainer)`
  & > label {
    margin-top: 1.5rem;
  }
  height: calc(100vh - 8.5rem);
`;

export const CardContainer = styled(Card)`
  border-top: none;
  box-shadow: none;
  padding-top: 0;
`;

export const PageTitle = styled(Subtitle)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

export const IconButton = styled.button`
  border: 0;
  background: none;
  text-align: center;
  padding: 0;
  top: 3px;
  position: relative;
  left: 4px;
`;

export const CardFooterActions = styled(CardActions)`
  flex-direction: row-reverse;
`;

export const TextContainer = styled.div`
  height: 85%;
  overflow-x: hidden;
  overflow-y: auto;
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(181, 181, 181, 0.5), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(181, 181, 181, 0.5), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 81px, 100% 81px, 100% 27px, 100% 27px;
`;

export const BodySection = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  overflow-y: hidden;
  height: 100%;
`;

export const PageSubtitle = styled(Paragraph)`
  &.MuiTypography-root {
    margin-bottom: 2.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;
  }
`;

export const Frame = styled.div`
  margin: 24px 0;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #ecf7fb;
`;

export const FrameSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const FrameIcon = styled.div`
  width: 32px;
    height: 32px;

  svg {
    width: 32px;
    height: 32px;
    fill: black;
  }
`;

export const FrameBody = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

`;

export const FrameBodyTitle = styled.div`
  color: #1e3e5a;
  /* Texto/MD/Bold Auto */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

export const FrameBodyContent = styled.div`
  color: #626266;
  /* Texto/MD/Regular Auto */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

export const Separator = styled.div`
  height: 4px;
  align-self: stretch;
  background: #fff;
`;

export const DetailsLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: #00aef0;
  /* Texto/MD/SemiBold Auto Link */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  text-decoration-line: underline;

  svg {
    width: 25px;
    height: 25px;
  }
`;
