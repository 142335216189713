/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledTextAreaContainer = styled.div`
display: flex;
padding: 0px 24px;
flex-direction: column;
align-items: center;
gap: 8px;
align-self: stretch;
`;
