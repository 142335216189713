/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import './Core/Styles/global.css';

import ErrorMessage from 'Components/ErrorMessage';
import FirstDeposit from 'Components/FirstDeposit';
import OpeningConfirmation from 'Components/OpeningConfirmation';
import {
  errorMessage1,
  errorMessage2,
  errorMessage3,
  errorMessage4,
  errorMessage5,
  errorMessage10,
  errorMessage6,
  errorMessage7,
  errorMessage11,
  errorMessage12,
  errorMessage13,
  errorMessage14,
  errorMessage15,
  errorMessage16,
} from 'Constants/ErrorMessage';
import ClientFinancialData from 'Pages/ClientFinancialData';
import SelectProduct from 'Pages/SelectProduct';
import DigitalCertificate from 'Pages/DigitalCertificate';
import TermsAndConditions from 'Pages/TermsAndConditions';
import muiTheme from 'Core/Styles/MuiTheme';
import Calculator from 'Pages/Calculator';
import SelectAccount from 'Pages/SelectAccount/SelectAccount';
import DigitalCertificateSummary from 'Pages/DigitalCertificateSummary';
import DigitalCertificateConfirmation from 'Pages/DigitalCertificateConfirmation/DigitalCertificateConfirmation';
import PerformanceTable from 'Pages/PerformanceTable';

const themes = createTheme(muiTheme);

const App = () => {
  return (
    <ThemeProvider theme={themes}>
      <Routes>
        <Route path="/" element={<SelectProduct />} />
        <Route path="/:token" element={<SelectProduct />} />
        <Route path="/deposito/:token" element={<DigitalCertificate />} />
        <Route path="/deposito/:token/:p1/:p2/:p3" element={<DigitalCertificate />} />
        <Route path="/certificateConfirmation" element={<DigitalCertificateConfirmation />} />
        <Route path="/certificadoDigitalSummary" element={<DigitalCertificateSummary />} />
        <Route path="/clientfinancialdata" element={<ClientFinancialData />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/performanceTable" element={<PerformanceTable />} />
        <Route path="/accountSelection" element={<SelectAccount />} />
        <Route path="/FirstDeposit" element={<FirstDeposit />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route
          path="/termsAndConditionsSummary"
          element={<TermsAndConditions {...{ summary: true }} />}
        />
        <Route path="/OpeningConfirmation" element={<OpeningConfirmation />} />
        <Route path="*" element={<ErrorMessage />} />
        <Route path="/error" element={<ErrorMessage {...errorMessage1} />} />
        <Route path="/fatalError" element={<ErrorMessage {...errorMessage16} />} />
        <Route path="/fatalErrorCuenta" element={<ErrorMessage {...errorMessage2} />} />
        <Route path="/fatalErrorRetryLater" element={<ErrorMessage {...errorMessage13} />} />
        <Route path="/tarifError" element={<ErrorMessage {...errorMessage6} />} />
        <Route path="/validationError" element={<ErrorMessage {...errorMessage7} />} />
        <Route path="/insufficientBalance" element={<ErrorMessage {...errorMessage4} />} />
        <Route path="/insufficientBalanceDolars" element={<ErrorMessage {...errorMessage11} />} />
        <Route path="/insufficientBalanceEuros" element={<ErrorMessage {...errorMessage12} />} />
        <Route path="/clientInfoError" element={<ErrorMessage {...errorMessage15} />} />
        <Route path="/clientInfoErrorCuenta" element={<ErrorMessage {...errorMessage3} />} />
        <Route path="/clientHasAccount" element={<ErrorMessage {...errorMessage5} />} />
        <Route path="/clientNeedsAccount" element={<ErrorMessage {...errorMessage14} />} />
        <Route path="/systemDown" element={<ErrorMessage {...errorMessage10} />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
