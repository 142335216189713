import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';

const initialHeaders = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': '2cc3706d41a04dae833877a386cea963',
};

const usePut = (url, headersData = undefined, isMock = false, mock = {}) => {
  const [body, setBody] = useState(null);
  const [data, setData] = useState(undefined);
  const headers = headersData || initialHeaders;
  const navigate = useNavigate();

  const callApi = (payload) => {
    setBody(payload);
  };

  const getData = async () => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers,
      });
      const dataResponse = await response.json();

      if (response.status >= 500 && response.status < 600) {
        const responseError = searchErrorCodeRoute(dataResponse);
        navigate(responseError.errorRoute);
      }
      if (isMock) {
        setData(mock);
      } else {
        setData(dataResponse);
      }
    } catch {
      if (isMock) {
        console.log('mock');
        setData(mock);
      } else {
        navigate('/clientInfoError');
      }
    }
  };

  useEffect(() => {
    if (body) {
      getData();
    }
  }, [body]);

  return {
    callApi,
    data,
  };
};

export default usePut;
