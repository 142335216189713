import styled from 'styled-components';
import { palette } from 'Core/Styles/Theme';
import { Paragraph } from 'Core/Styles/CommonStyles';
import InputText from 'Components/InputText';

// eslint-disable-next-line import/prefer-default-export

export const StyledParagraph = styled(Paragraph)`
    margin-top: 1rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${palette.textAndLine.PlataBanreservas700B};
    margin: 1.5rem 0 1.938rem;
`;

export const StyledContainer = styled.div`
`;

export const StrongFareCell = styled.strong`
    font-size: 1rem;
`;

export const InfoDetailText = styled.p`
    font-size: 0.75rem;
    font-family: 'Open Sans';
    padding-right: 2rem;
    margin-left: 0.5rem;
    color: ${palette.textAndLine.PlataBanreservas700B};
`;

export const BlockElement = styled.div`
    position: absolute;
    bottom: 12%;
`;

export const StyledInputText = styled(InputText)`
    & .MuiFormHelperText-root {
        text-align: left;

        &.Mui-error {
            text-align: left;
        }
    }
`;
