export const interestTypeMap = {
  CAP: 'Capitalizable',
  CRED: 'Crédito a cuenta',
  Capitalizable: 'Capitalizable',
  CreditoCuenta: 'Crédito a cuenta',
};

export const productTypeMap = {
  CuentaAhorro: 'Cuenta de ahorro',
  CuentaCorriente: 'Cuenta Corriente',
};
