/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const errorBoundarySlice = createSlice({
  name: 'errorBoundary',
  initialState: {
    error: false,
    data: {},
  },
  reducers: {
    updateError: (state, actions) => {
      state.error = true;
      state.data = actions.payload;
    },
    cleanError: (state) => {
      state.error = false;
      state.data = {};
    },
  },
});

const { actions, reducer } = errorBoundarySlice;

export const { updateError, cleanError } = actions;

export default reducer;
