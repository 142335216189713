import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Calendar = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M22.0909 9.45455H21.3636V8H19.9091V9.45455H12.6364V8H11.1818V9.45455H10.4545C9.65455 9.45455 9 10.1091 9 10.9091V22.5455C9 23.3455 9.65455 24 10.4545 24H22.0909C22.8909 24 23.5455 23.3455 23.5455 22.5455V10.9091C23.5455 10.1091 22.8909 9.45455 22.0909 9.45455ZM22.0909 22.5455H10.4545V14.5455H22.0909V22.5455ZM22.0909 13.0909H10.4545V10.9091H22.0909V13.0909Z"
      fill={color}
    />
  </svg>
);

Calendar.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

Calendar.propTypes = {
  color: PropTypes.string,
};

export default Calendar;
