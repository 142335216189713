/* eslint-disable quote-props */
export const CARDS_DATA_PAYLOAD = (document, documentType, amountRequired = null) => {
  if (amountRequired) {
    return `?documentType=${documentType}&documentNumber=${document}&process=DigitalCertificate&amountRequired=${amountRequired}`;
  }
  return `?documentType=${documentType}&documentNumber=${document}`;
};

export const CARDS_DATA_RESPONSE = {
  200: {
    products: [
      {
        productLine: 'débito',
        accountNumber: '1234561652',
        currency: 'DOP',
        accountId: '1234561652',
      },
      {
        productLine: 'ahorro',
        accountNumber: '1234564444',
        currency: 'DOP',
        accountId: '1234564444',
      },
      {
        productLine: 'ahorro',
        accountNumber: '1234569504',
        currency: 'EUR',
        accountId: '1234569504',
      },
    ],
  },
  400: {
    description: 'Business error',
  },
  500: {
    description: 'Server error',
  },
};
