import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';

const initialHeaders = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': '2cc3706d41a04dae833877a386cea963',
};

const usePost = (url, headersData = undefined, isMock = false, mock = {}, customError = false) => {
  const [body, setBody] = useState(null);
  const [data, setData] = useState(undefined);
  const headers = headersData || initialHeaders;
  const navigate = useNavigate();
  const location = useLocation();

  const callApi = (payload) => {
    setBody(payload);
  };

  const getData = async () => {
    try {
      if (isMock) {
        setData(mock);
      } else {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers,
        });
        if (!customError && response.status >= 400 && response.status < 500) {
          navigate('/clientInfoError');
        }
        const dataResponse = await response.json();

        if (response.status >= 500 && response.status < 600) {
          const responseError = searchErrorCodeRoute(dataResponse);
          navigate(responseError.errorRoute);
        }

        setData(dataResponse);
      }
    } catch (e) {
      if (isMock) {
        setData(mock);
      } else if (
        e.toString().includes('SyntaxError')
        && location.pathname !== '/OpeningConfirmation'
      ) {
        navigate('/clientInfoError');
      }
    }
  };

  useEffect(() => {
    if (body) {
      getData();
    }
  }, [body]);

  return {
    callApi,
    data,
  };
};

export default usePost;
