/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import Sell from '@mui/icons-material/SellOutlined';
import {
  ButtonContainer, ContinueButton, Subtitle, Paragraph,
  BodyContainer,
} from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';

export const StyledBodyContainer = styled(BodyContainer)`
    @media only screen and (min-width: 600px) {
        margin-bottom: 64px;
    }
`;

export const CardProduct = styled(Card)`

    &.MuiPaper-root {
        border-radius: 20px;
        border-color: ${palette.textAndLine.BlancoBanreservas000};
        border-top: 0;
        background-color: ${palette.textAndLine.BlancoBanreservas000};
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
    }

    & div.MuiCardActions-root {
        padding-top: 1rem;
    }
`;

export const CardHead = styled.div`
    background-color:  ${palette.textAndLine.PlataBanreservas100};
`;

export const CardBody = styled.div`
    background-color: ${palette.textAndLine.PlataBanreservas100};
    padding: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
`;

export const SellIconContainer = styled(Sell)`
    margin-bottom: -8px;
    margin-right: 10px;
    color: #0398D1;
`;

export const OpenNewWindow = styled(OpenInNewIcon)`
    color: #264E72;
    padding-left: 5px;
    padding-bottom: 0px;
`;

export const TypographyBullets = styled(Typography)`
    color: ${palette.brand.AzulBanreservas700B};
    line-height: 20px !important;
    font-size: 14px;
    margin-bottom: 14px;
    margin: 9px 0px 3px 0px;
    text-align: left;
`;

export const AcceptButton = styled(Button)`
    text-underline-offset: auto;
    &.MuiButtonBase-root {
        text-transform: inherit;
        text-underline-offset: auto;
        font-size: 16px;
        line-height: 20px;
        color: #264E72;
    }
`;

export const OpenButton = styled(ContinueButton)`
    &.MuiButton-containedPrimary {
        margin-top: 1rem;
    }
`;

export const CardSubtitle = styled(Subtitle)`
    &.MuiTypography-root {
        margin: 0px 0px 0rem 0rem;
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 26px;
    }
`;

export const CardDescription = styled(Subtitle)`
    &.MuiTypography-root{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
`;

export const CardActionsContainer = styled(CardActions)`
    flex-direction: row-reverse;
    margin-top: -1rem;
    button:focus {
        outline: 0px dotted;
    }
`;

export const BorderSeparatorProduct = styled.div`
    width: 100%;
    border-bottom: 1px solid #CACCCD;
    margin-bottom: 1rem;
    margin-top: 2rem;
`;

export const BoxTitle = styled(Subtitle)`
    &.MuiTypography-root {
        margin: 0px 0px 12px 0px !important;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
    }
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 12% 88%;

    @media only screen and (min-width: 400px) {
        grid-template-columns: 12% 88%;
    }
`;

export const InfoBox = styled.div`
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 16px;
`;
export const InfoBoxContainer = styled.div`
    margin-bottom: 16px;
    height: 100%;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    margin-left: 8px;
    text-align: center;
    color: ${palette.brand.AzulBanreservas700B};
`;

export const InfoBoxSeparator = styled.div`
    border-top: 1px ${palette.textAndLine.PlataBanreservas300} solid;
    width: 100%;
    margin-bottom: 8px;
`;

export const BoxText = styled(Paragraph)`
    margin: 0.1rem 0 0.5rem 0;
    text-align: left;
`;

export const ButtonContainerSelectProduct = styled(ButtonContainer)`
    text-align: center;
    @media only screen and (min-width: 600px) {
        position: fixed;
        max-width: 100%;
    }
`;
