/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StyledNPSBodyContainer, TitleModalNPS, TitleModalNPSContainer } from 'Core/Styles/CommonStyles';
import InputText from 'Components/InputText';
import { StyledTextAreaContainer } from './style';

const validationSchema = Yup.object().shape({
  comment: Yup.string().required('Ingresa un comentario')
    .max(150, 'No puede tener mas de 150 caracteres.')
    .matches(/^[A-Za-z0-9áéíóúäëïöüÁÉÍÓÚÄËÏÖÜ.,\s]*$/, 'Ingresa solo caracteres válidos'),
});

const OptionalComment = ({ commentRef, setComment }) => (
  <Formik
    innerRef={commentRef}
    initialValues={{
      comment: '',
    }}
    validationSchema={validationSchema}
    validate={(values) => {
      setComment(values.comment);
    }}
    validateOnChange
  >
    {() => (
      <Form>
        <StyledNPSBodyContainer>
          <TitleModalNPSContainer>
            <TitleModalNPS data-testid="title">
              ¿Cómo calificarías tu experiencia al abrir
              la Cuenta Digital Banreservas?
            </TitleModalNPS>
          </TitleModalNPSContainer>
          <StyledTextAreaContainer>
            <InputText
              dataTest="optional-comment"
              name="comment"
              type="text"
              placeholder="Escribe aquí tus comentarios."
              countCharacters={150}
              multiline
            />
          </StyledTextAreaContainer>
        </StyledNPSBodyContainer>
      </Form>
    )}
  </Formik>
);

OptionalComment.propTypes = {
  commentRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  setComment: PropTypes.func.isRequired,
};
export default OptionalComment;
