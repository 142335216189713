import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import {
  setAmount,
  setAproxPerformanceAmmount,
  setCDOpened,
  setCDSelectedTerm,
  setPerformanceTable,
} from 'Core/Store/Reducers/certificateDepositOfferSlice';
import {
  PERFORMANCE_TABLE_DATA_PAYLOAD,
  PERFORMANCE_TABLE_DATA_RESPONSE,
} from 'Constants/PerformnaceTable';
import usePost from './usePost';

const { REACT_APP_ENVIRONMENT } = process.env;

const usePerformanceTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [payload, setPayload] = useState();
  const [performanceAmountValue, setPerformanceAmount] = useState();
  const { data, callApi } = usePost(
    endpoints.performanceTable,
    API_HEADERS(),
    REACT_APP_ENVIRONMENT === 'DEV',
    PERFORMANCE_TABLE_DATA_RESPONSE[200],
  );

  const getPerformanceTable = ({
    unmaskedAmount, selectedTerm, rate, performanceAmount,
  }) => {
    const PerformanceTablePayload = {
      ...PERFORMANCE_TABLE_DATA_PAYLOAD,
      amount: +unmaskedAmount,
      term: +selectedTerm,
      rate,
    };
    setPayload({ ...PerformanceTablePayload });
    setPerformanceAmount(performanceAmount);
  };

  useEffect(() => {
    if (payload?.term && payload?.rate) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data?.status) {
      dispatch(setAmount(payload.amount));
      dispatch(setCDSelectedTerm(payload.term));
      dispatch(setCDOpened({ rate: payload.rate, totalProfitability: performanceAmountValue }));
      dispatch(setPerformanceTable(data.amortization));
      dispatch(setAproxPerformanceAmmount(data.totalProfitability));
      navigate('/performanceTable');
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    performanceTable: data,
    getPerformanceTable,
  };
};

export default usePerformanceTable;
