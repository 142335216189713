/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useDispatch } from 'react-redux';
import useStep from 'Services/useStep';
import Checkbox from 'Components/Checkbox';
import { acceptTerms } from 'Core/Store/Reducers/TermsAndConditionsSlice';
import { BodyContent, ContinueButton, ButtonContainer } from 'Core/Styles/CommonStyles';

import Caducidad from 'Core/Utils/Assets/CD/Caducidad';
import ChevronRight from 'Core/Utils/Assets/CD/ChevronRight';
import Calendar from 'Core/Utils/Assets/CD/Calendar';
import Balance from 'Core/Utils/Assets/CD/Balance';
import useCDTermsCond from 'Services/api/useCDOpenCertification';
import InfoModal from 'Components/InfoModal';
import TermsAndConditionsText from 'Core/Utils/TermsAndConditionsText';
import LoadingScreen from 'Components/LoadingScreen';
import { SpinnerText1 } from 'Constants/SpinnerText';
import {
  PageTitle,
  TermBodyContainer,
  PageSubtitle,
  FrameBody,
  Frame,
  FrameSection,
  FrameIcon,
  FrameBodyTitle,
  FrameBodyContent,
  DetailsLink,
  Separator,
} from './style';

const validationSchema = Yup.object().shape({
  termsAndConditions: Yup.boolean().oneOf([true], 'Campo requerido').required('Campo requerido'),
});

const TermsAndConditionsSummary = () => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const analytics = getAnalytics();
  const [didAccept, setDidAccept] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const { getCDTermsCond } = useCDTermsCond();

  const { validatePath, generateToken } = useStep();

  useEffect(() => {
    validatePath();
  }, []);

  useEffect(() => {
    if (isloading) {
      return;
    }
    const { termsAndConditions } = formikRef.current.values;
    if (termsAndConditions) {
      formikRef.current.setFieldValue('termsAccepted', true);
      setDidAccept(true);
    } else {
      formikRef.current.setFieldValue('termsAccepted', false);
      setDidAccept(false);
    }
  }, [didAccept]);

  return !isloading ? (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{
        termsAndConditions: false,
        termsAccepted: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        generateToken();
        dispatch(acceptTerms(didAccept));
        getCDTermsCond();
        logEvent(analytics, 'Terms and Conditions', {
          content_type: 'Button - Open Deposit',
          content_id:
            'Checkbox - Accept Terms And Conditions Contract to Transition into Opening Confirmation Screen',
        });
      }}
    >
      {({
        dirty, isValid, isSubmitting, handleSubmit, setFieldValue,
      }) => (
        <Form>
          <TermBodyContainer>
            <BodyContent>
              <PageTitle>Condiciones importantes</PageTitle>
              <PageSubtitle>Antes de continuar requerimos aceptes lo siguiente:</PageSubtitle>
              <Frame>
                <FrameSection>
                  <FrameIcon>
                    <Caducidad />
                  </FrameIcon>
                  <FrameBody>
                    <FrameBodyTitle>Cancelación anticipada</FrameBodyTitle>
                    <FrameBodyContent>
                      Al cancelar el depósito antes de tiempo, se aplicará una penalización del 2.5%
                      sobre los intereses pendientes hasta el vencimiento.
                    </FrameBodyContent>
                  </FrameBody>
                </FrameSection>
                <Separator />
                <FrameSection>
                  <FrameIcon>
                    <Calendar />
                  </FrameIcon>
                  <FrameBody>
                    <FrameBodyTitle>Cambio de condiciones</FrameBodyTitle>
                    <FrameBodyContent>
                      No podrás modificar el monto, plazo o tasa de interés mientras el depósito
                      esté vigente.
                    </FrameBodyContent>
                  </FrameBody>
                </FrameSection>
                <Separator />
                <FrameSection>
                  <FrameIcon>
                    <Balance />
                  </FrameIcon>
                  <FrameBody>
                    <FrameBodyTitle>Renovación</FrameBodyTitle>
                    <FrameBodyContent>
                      Al renovar tu depósito a plazo es posible que la tasa de interés varíe según
                      las condiciones del mercado.
                    </FrameBodyContent>
                  </FrameBody>
                </FrameSection>
              </Frame>
              <DetailsLink
                onClick={() => {
                  setIsOpen(true);
                  setFieldValue('termsAndConditions', false);
                  setDidAccept(false);
                  logEvent(analytics, 'Terms and Conditions', {
                    content_type: 'Button - Details Link Terms and Conditions Button',
                    content_id:
                      'Digital Certificate Terms and Conditions Screen -  Open the Details Link for the Terms and Conditions Information',
                  });
                }}
              >
                Ver más detalle
                <ChevronRight color="#00AEF0" />
              </DetailsLink>
            </BodyContent>
            <Checkbox
              label="Acepto los términos y condiciones"
              name="termsAccepted"
              onClick={() => {
                setFieldValue('termsAndConditions', !didAccept);
                setDidAccept(!didAccept);
                logEvent(analytics, 'Terms and Conditions', {
                  content_type: 'Button - Checkbox Terms and Conditions Button',
                  content_id:
                    'Digital Certificate Terms and Conditions Screen -  Check the Terms and Conditions Button',
                });
              }}
            />
          </TermBodyContainer>
          <ButtonContainer>
            <ContinueButton
              variant="contained"
              disableElevation
              disabled={!(isValid && dirty && !isSubmitting && didAccept)}
              onClick={() => {
                setIsloading(true);
                handleSubmit();
              }}
            >
              abrir depósito
            </ContinueButton>
          </ButtonContainer>

          <InfoModal
            buttonText="ACEPTAR"
            modalTitle="Términos y condiciones"
            onButtonClick={() => {
              setIsOpen(false);
              setFieldValue('termsAndConditions', !didAccept);
              setDidAccept(!didAccept);
              logEvent(analytics, 'Terms and Conditions', {
                content_type: 'Button - Open Terms and Conditions modal',
                content_id:
                  'Digital Certificate Terms and Conditions Screen -  Open Terms and Conditions Modal',
              });
            }}
            onClose={() => setIsOpen(false)}
            isOpen={isOpen}
          >
            <TermsAndConditionsText certificate />
          </InfoModal>
        </Form>
      )}
    </Formik>
  ) : (
    <LoadingScreen isOpen text={SpinnerText1} isWhite />
  );
};

export default TermsAndConditionsSummary;
