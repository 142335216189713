import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const DoneCircleIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.30469 16C8.30469 11.5822 11.8869 8 16.3047 8C20.7225 8 24.3047 11.5822 24.3047 16C24.3047 20.4178 20.7225 24 16.3047 24C11.8869 24 8.30469 20.4178 8.30469 16ZM10.0825 16C10.0825 19.4311 12.8736 22.2222 16.3047 22.2222C19.7358 22.2222 22.5269 19.4311 22.5269 16C22.5269 12.5689 19.7358 9.77778 16.3047 9.77778C12.8736 9.77778 10.0825 12.5689 10.0825 16ZM15.7749 18.4817L19.9014 14.4415C20.2268 14.1229 20.2291 13.5998 19.9064 13.2784C19.5894 12.9626 19.0774 12.9604 18.7577 13.2734L15.0792 16.875L13.8567 15.6563C13.5398 15.3404 13.0277 15.3381 12.708 15.6511C12.3826 15.9696 12.3802 16.4925 12.7027 16.8139L14.3693 18.4754C14.7571 18.862 15.3836 18.8648 15.7749 18.4817Z"
      fill={color}
    />
  </svg>
);

DoneCircleIcon.defaultProps = {
  color: palette.brand.AzulBanreservas100B,
};

DoneCircleIcon.propTypes = {
  color: PropTypes.string,
};
export default DoneCircleIcon;
