/* eslint-disable quote-props */
export const CONTRACT_DATA_PAYLOAD = {
  documentType: 'CEDULA',
  documentNumber: '',
};

export const CONTRACT_DATA_RESPONSE = {
  '200': {
    ok: true,
    hasAContract: false,
    contractDate: '12 de marzo 2023',
  },
  '400': {
    description: 'Business error',
  },
  '500': {
    description: 'Server error',
  },
};
