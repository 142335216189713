/* eslint-disable quote-props */
export const DEPOSIT_DATA_PAYLOAD = {
  documentType: 'CEDULA',
  documentNumber: '',
  monthlyAmount: '',
};

export const DEPOSIT_DATA_RESPONSE = {
  '200': {
    ok: true,
  },
  '400': {
    description: 'Business error',
  },
  '500': {
    description: 'Server error',
  },
};
