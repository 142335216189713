/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ErrorImage from 'Core/Utils/Assets/SystemError';
import Header from 'Components/Header';
import {
  Subtitle, Paragraph, ContinueButton, BodysubTitle,
} from '../../Core/Styles/CommonStyles';
import {
  TitleContainer,
  ButtonContainer,
  BodyContent,
  ErrorImageContainer,
  Container,
  BodyContainerError,
} from './style';

const handlerNavigate = () => {
  const navigate = useNavigate();
  navigate(-1);
};

const boldMessage = (text) => {
  const boldArr = text.split('*');
  return (
    <>
      {boldArr[0]}
      {' '}
      <span style={{ fontWeight: 700 }}>{boldArr[1]}</span>
      {' '}
      {boldArr[2]}
    </>
  );
};

const ErrorMessage = ({
  errorType,
  headerTitle,
  title,
  text,
  hasClick,
  hasBackBtn,
  handlerClick,
  subText,
  bodyHasBold,
  hasSubheader,
}) => (
  <>
    {hasClick || hasBackBtn ? (
      <Header title={headerTitle} showBackButton />
    ) : hasSubheader ? (
      <Header title={headerTitle} showBackButton={false} />
    ) : (
      <Container />
    )}
    <BodyContainerError style={{ marginTop: '1.25rem' }}>
      <BodyContent>
        <TitleContainer>
          <ErrorImageContainer>
            <ErrorImage errorType={errorType} />
          </ErrorImageContainer>
          <Subtitle variant="h6" customMargin=".5rem">
            {title}
          </Subtitle>
          {text && (
            <BodysubTitle sx={{ marginBottom: '24px' }}>
              {bodyHasBold ? boldMessage(text) : text}
            </BodysubTitle>
          )}
          {subText && <Paragraph sx={{ marginTop: text === '' ? '24px' : 0 }}>{subText}</Paragraph>}
        </TitleContainer>
        {hasClick && (
          <ButtonContainer>
            <ContinueButton variant="contained" onClick={handlerClick}>
              REINTENTAR
            </ContinueButton>
          </ButtonContainer>
        )}
      </BodyContent>
    </BodyContainerError>
  </>
);
export default ErrorMessage;

ErrorMessage.defaultProps = {
  errorType: 1,
  headerTitle: 'Onboarding Banreservas',
  title: '¡Vaya! algo salió mal',
  text: '',
  hasClick: true,
  bodyHasBold: false,
  handlerClick: handlerNavigate,
  subText: '',
  hasBackBtn: false,
  hasSubheader: false,
};

ErrorMessage.propTypes = {
  errorType: PropTypes.number,
  headerTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  hasClick: PropTypes.bool,
  hasBackBtn: PropTypes.bool,
  handlerClick: PropTypes.func,
  subText: PropTypes.string,
  bodyHasBold: PropTypes.bool,
  hasSubheader: PropTypes.bool,
};
