/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Subtitle } from 'Core/Styles/CommonStyles';

export const Container = styled.div`
  text-align: center;
`;

export const PageTitle = styled(Subtitle)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-top: 32px;
`;
