import React from 'react';
import PropTypes from 'prop-types';
import Header from 'Components/Header';
import TermsAndConditionsForm from 'Components/Forms/TermsAndConditions';
import TermsAndConditionsSummary from 'Components/Forms/TermsAndConditionsSummary';

const TermsAndConditions = ({ summary = false }) => (
  <>
    <Header title={summary ? 'Términos y condiciones' : 'Firma de contrato'} />
    {summary ? <TermsAndConditionsSummary /> : <TermsAndConditionsForm />}
    {' '}
  </>
);

export default TermsAndConditions;

TermsAndConditions.defaultProps = {
  summary: false,
};

TermsAndConditions.propTypes = {
  summary: PropTypes.bool,
};
