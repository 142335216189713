/* eslint-disable quote-props */
export const EXCHANGE_DATA_PAYLOAD = {
  divisa: {
    tipo: 'USD',
  },
};

export const EXCHANGE_DATA_RESPONSE = {
  '200': {
    ok: true,
  },
  '400': {
    description: 'Business error',
  },
  '500': {
    description: 'Server error',
  },
};
