/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { PropTypes } from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import {
  BodyTableCell,
  InfoDetailText,
  BlockElement,
  FareCell,
  StrongFareCell,
} from './style';

const SavingsFareInfo = ({ fareDetails }) => (
  <>
    <Table>
      <TableBody>
        {fareDetails.map((row) => (
          <TableRow
            key={row.name}
          >
            <BodyTableCell description align="left">
              {row.name}
            </BodyTableCell>
            <BodyTableCell align="right">
              <FareCell>
                <StrongFareCell>{`RD$${row.fare}.`}</StrongFareCell>
                00
                {' '}
              </FareCell>
            </BodyTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <BlockElement>
      <InfoDetailText>
        * Aplica para tarjeta
        <strong> Mastercard Débito Standard</strong>
        .
        Puedes adquirirla en cualquier oficina comercial Banreservas.
      </InfoDetailText>
    </BlockElement>
  </>
);

SavingsFareInfo.propTypes = {
  fareDetails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      fare: PropTypes.number,
    }),
  ).isRequired,
};

export default SavingsFareInfo;
