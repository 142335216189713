/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CircleIcon from '@mui/icons-material/Circle';
import ChevronUp from 'Core/Utils/Assets/CD/ChevronUp.svg';
import ChevronDown from 'Core/Utils/Assets/CD/ChevronDown.svg';
import ListItem from 'Core/Utils/Assets/ListItem.svg';
import { palette } from 'Core/Styles/Theme';
import { ContinueButton, Subtitle, BodysubTitle } from 'Core/Styles/CommonStyles';

export const CardProduct = styled(Card)`
  &.MuiPaper-root {
    border-radius: 20px;
    border-top: 0;
    box-shadow: none;
    margin-bottom: -20px;
  }

  & div.MuiCardActions-root {
    padding-top: 1rem;
  }
`;

export const TypographyBullets = styled(Typography)`
  padding-left: 8px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: ${palette.semantic.InfoBanreservas700};
`;

export const OpenButton = styled(ContinueButton)`
  &.MuiButton-containedPrimary {
    margin-top: 1rem;
  }
`;

export const ContainerImage = styled.div`
  background-color: ${palette.textAndLine.PlataBanreservas100};
`;

export const ContainerTitle = styled.div`
  background-color: ${palette.textAndLine.PlataBanreservas100};
  padding: 24px;
  margin-bottom: 32px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  
`;

export const CardSubtitle = styled(Subtitle)`
  &.MuiTypography-root {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }
`;

export const CardSubtitleDescription = styled(BodysubTitle)`
  &.MuiTypography-root {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
    font-weight: 400;
    color: ${palette.semantic.InfoBanreservas700};
  }
`;

export const CardSectionTitle = styled(CardSubtitle)`
  &.MuiTypography-root {
    font-size: 16px;
  }
`;
export const CardSectionDescription = styled(BodysubTitle)`
  &.MuiTypography-root {
    font-size: 16px;
    margin-left: 8px;
    color: ${palette.semantic.InfoBanreservas700};
    font-style: normal;
    line-height: 22px;
    font-weight: 700;
  }
`;

export const PapersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 40px;
  margin-top: 16px;
`;

export const LuStyled = styled.ul`
  padding: 0px;
  margin-bottom: 40px;
`;

export const LiStyled = styled.li`
  font-size: 30px;
  list-style-type: none;
  margin-bottom: 4px;
  background: url(${ListItem}) no-repeat left top;
  padding: 0px 0 3px 24px;
`;

export const BorderSeparatorProduct = styled.div`
  border-bottom: 1px solid ${palette.textAndLine.PlataBanreservas100};
  width: 96%;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  width: 327px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  border-radius: 16px;
  background: ${palette.semantic.InfoBanreservas100};
  margin-bottom: 32px;
`;

export const RequirementContainer = styled.div`
  display: flex;
  width: 327px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
  border-radius: 16px;
  background: ${palette.semantic.InfoBanreservas100};
  margin-bottom: 32px;
`;

export const BenefitsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${palette.semantic.InfoBanreservas700};
`;

export const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 8px; */
  align-self: stretch;
`;

export const BenefitsListItem = styled.div`
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${palette.semantic.InfoBanreservas700};
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const BenefitsText = styled.div`
  padding-left: 9px;
`;

export const BenefitsIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${palette.semantic.InfoBanreservas700};
  }
`;

export const RequirementTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${palette.semantic.InfoBanreservas700};
`;

export const RequerementIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  padding-left: 10px;

  svg {
    fill: ${palette.semantic.InfoBanreservas700};
  }
`;

export const CheckIconContainer = styled(CircleIcon)`
  width: 8px !important;
  height: 8px !important;
  fill: ${palette.brand.AzulBanreservas100B} !important;
`;

export const QAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 0 -24px; */
  padding: 0px;
  max-width: 327px;
`;

export const QADetails = styled.details`
  width: 100%;
  display: flex;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: ${palette.textAndLine.PlataBanreservas700B};
  border-top: 1px solid ${palette.textAndLine.PlataBanreservas100};

  ul {
    padding-top: 16px;
  }

  li:first-of-type {
    padding-bottom: 16px;
  }

  summary {
    display: block;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${palette.semantic.InfoBanreservas700};
    margin-bottom: 0px;
    width: 100%;
    padding-right: 32px;
    position: relative;
    list-style: none;
    transition: all 0.35s;
  }
  summary::-webkit-details-marker {
    display: none;
  }

  summary::after {
    margin-left: 1ch;
    display: inline-block;
    content: url(${ChevronDown});
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  &[open] > summary::after {
    content: url(${ChevronUp});

  }

  &[open] > summary {
    margin-bottom: 8px;

  }
`;
