import React from 'react';
import FormClientFinancialData from 'Components/Forms/ClientFinancialData';
import Header from 'Components/Header';

const ClientFinancialData = () => (
  <>
    <Header title="Datos financieros" />
    <FormClientFinancialData />
  </>
);

export default ClientFinancialData;
