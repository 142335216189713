/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  Subtitle,
  BodyContainer,
} from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';

export const StyledBodyContainer = styled(BodyContainer)`
    padding: 2.5rem 1.5rem 0;
    text-align: center;
`;

export const StyledSubtitle = styled(Subtitle)`
    margin-top: 1.5rem;
    &.MuiTypography-root {
        font-weight: normal;
    }
`;

export const StyledDate = styled.div`
    font-size: 16px;
    color: ${palette.textAndLine.PlataBanreservas700B};
    line-height: 22px;
    padding-top: 8px;
`;
