/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import React from 'react';
import { useSelector } from 'react-redux';
import ShareIcon from 'Core/Utils/Assets/ShareIcon';
import { CustomStyledLink, ButtonShare } from './style';

const ShareButton = () => {
  const { document, name, accountNumber } = useSelector((state) => state.clientInfo);
  const personalData = `¡Ya tengo mi Cuenta Digital Banreservas!


Cuenta de Ahorro DOP: ${accountNumber}
Cédula: ${document.replaceAll('-', '')}
${name}`;
  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      navigator
        .share({
          title: 'Compartir Cuenta',
          text: personalData,
        })
        .then(() => {
          console.log(personalData);
        })
        .catch(() => {
          console.log('Sharing failed');
        });
    } else {
      console.log('Sorry! Your browser does not support Web Share API');
    }
  };
  return (
    <ButtonShare sx={{ textAlign: 'center', height: '38px' }} onClick={() => handleShareButton()}>
      <CustomStyledLink>
        COMPARTIR
      </CustomStyledLink>
      <ShareIcon />
    </ButtonShare>
  );
};

export default ShareButton;
