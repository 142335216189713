const currencyMessages = [
  {
    currency: 'DOP',
    message: '',
    isDifferent: false,
  },
  {
    currency: 'USD',
    message: '*Desde una cuenta en dólares debes depositar USD 2.00.',
    isDifferent: true,
  },
  {
    currency: 'EUR',
    message: '*Desde una cuenta en euros debes depositar EUR 2.00.',
    isDifferent: true,
  },
];

export default currencyMessages;
