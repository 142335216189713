/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { setFirstDepositCard } from 'Core/Store/Reducers/clientInfoSlice';
import { SpinnerText2 } from 'Constants/SpinnerText';
import useSelectCard from 'Services/api/useSelectCard';
import useStep from 'Services/useStep';
import RadioButtonGroup from 'Components/RadioButtonGroup';
import LoadingScreen from 'Components/LoadingScreen';
import Header from 'Components/Header';
import useCard from 'Services/api/useCard';
import currencyMessages from 'Constants/CardMessages';
import AlertDialog from 'Components/AlertDialog';
import useExchange from 'Services/api/useExchange';
import {
  BodyContainer, BodyContent,
  ContinueButton, ButtonContainer, BottomParagraph,
} from 'Core/Styles/CommonStyles';
import MoneyExchange from 'Core/Utils/Assets/MoneyExchange';
import {
  PageTitle, PageSubtitle,
} from './style';

const FirstDeposit = () => {
  const analytics = getAnalytics();
  const { validatePath, generateToken } = useStep();
  const dispatch = useDispatch();
  const { cards, getCards } = useCard();
  const { getSelectCard } = useSelectCard();
  const [cardsData, setCardsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDifferentCurrency, setDifferentCurrency] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [isAlertLoading, setIsAlertloading] = useState(false);
  const [helpMessage, setHelpMessage] = useState('');
  const [depositCurrency, setDepositCurrency] = useState('DOP');
  const [dirtyCheck, SetdirtyCheck] = useState(false);
  const { firstDepositCard } = useSelector((state) => state.clientInfo);

  const { exchangeRate, getExchangeData } = useExchange();

  const formatData = (data) => {
    const cardsFormat = data.map(({
      accountId, accountNumber, productLine, currency,
    }) => {
      const cardType = (productLine.indexOf('horro') >= 0 ? 'Cuenta de ahorro' : 'Cuenta corriente');
      return {
        value: accountId,
        label: `${accountNumber.slice(4)} ${cardType}`,
        accountCurrency: currency || 'DOP',
      };
    });
    setCardsData(cardsFormat);
  };

  useEffect(() => {
    validatePath();
    getCards();
  }, []);

  useEffect(() => {
    if (cards?.products) {
      formatData(cards.products);
    }
  }, [cards]);

  useEffect(() => {
    if (cardsData.length) {
      setIsloading(false);
    }
  }, [cardsData]);

  useEffect(() => {
    if (exchangeRate.tasa !== -1) {
      setIsAlertloading(false);
      setIsOpen(true);
    }
  }, [exchangeRate]);

  const formSubmit = (values) => {
    console.log(values);
    logEvent(analytics, 'Send First Deposit', {
      content_type: 'Button - Send First Deposit Radio Button',
      content_id: 'Digital Sale RadioButton Account Select - Send First Deposit to Transition into Accept Terms and Conditions Screen',
    });
    dispatch(setFirstDepositCard(values.firstDepositCard));
    getSelectCard(values.firstDepositCard);
    generateToken();
  };

  const setNewCurrency = (card) => {
    const newCurrency = currencyMessages.find((cardMessage) => cardMessage.currency === card.accountCurrency);
    setHelpMessage(newCurrency.message);
    setDifferentCurrency(newCurrency.isDifferent);
    setDepositCurrency(newCurrency.currency);
  };

  return (
    <>
      <Header title="Primer depósito" showBackButton />
      {!isloading ? (
        <Formik
          enableReinitialize
          initialValues={{
            firstDepositCard,
          }}
          onSubmit={formSubmit}
        >
          {({
            handleSubmit,
            dirty,
            handleChange,
            values,
          }) => {
            if (!dirtyCheck && (dirty || firstDepositCard)) {
              SetdirtyCheck(true);
            }
            return (
              <Form>
                <BodyContainer>
                  <BodyContent>
                    <PageTitle>
                      ¡Estamos casi listos!
                    </PageTitle>
                    <PageSubtitle>
                      Debes realizar un depósito de
                      {' '}
                      <strong>DOP 100.00 </strong>
                      ¿Desde cuál cuenta quieres hacer el depósito?

                    </PageSubtitle>
                    <RadioButtonGroup
                      name="firstDepositCard"
                      radioGroup={cardsData}
                      handleChange={(e) => {
                        setNewCurrency(cardsData.find((card) => card.value === e.target.value));
                        handleChange(e);
                      }}
                      values={values}
                      defaultValue={firstDepositCard}
                    />
                  </BodyContent>
                </BodyContainer>
                <ButtonContainer>
                  {helpMessage === '' ? null
                    : (<BottomParagraph>{helpMessage}</BottomParagraph>) }
                  {' '}
                  <ContinueButton
                    variant="contained"
                    disabled={!values?.firstDepositCard}
                    onClick={() => {
                      if (isDifferentCurrency) {
                        setIsAlertloading(true);
                        getExchangeData(depositCurrency);
                      } else {
                        setIsloading(true);
                        handleSubmit();
                      }
                    }}
                  >
                    CONTINUAR
                  </ContinueButton>
                  {!isAlertLoading ? (
                    <AlertDialog
                      dialogIcon={<MoneyExchange />}
                      title="Seleccionaste una cuenta en moneda extranjera"
                      subtitle={['Debido a la tasa de cambio del día serán depositados ',
                        <strong>{`DOP ${exchangeRate.tasa}`}</strong>,
                      ]}
                      onClose={() => setIsOpen(false)}
                      isOpen={isOpen}
                      onClick={() => {
                        setIsloading(true);
                        handleSubmit();
                      }}
                    />) : <LoadingScreen isOpen={isAlertLoading} text={SpinnerText2} />}
                </ButtonContainer>
              </Form>
            );
          }}
        </Formik>) : <LoadingScreen isOpen={isloading} text={SpinnerText2} isWhite />}
    </>
  );
};

export default FirstDeposit;
