import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import { palette } from 'Core/Styles/Theme';
import InfoIcon from 'Core/Utils/Assets/InfoIcon';

// eslint-disable-next-line import/prefer-default-export
export const BodyContainer = styled.div`
    padding: 1.5rem 1.5rem 0;
    /* padding: 24px 24px 0; */
    display: flex;
    min-height: calc(100vh - 8.5rem);
    flex-direction: column;
    overflow: scroll;
`;

export const BodyContent = styled.div`
    flex: 1;
`;

export const Subtitle = styled(Typography)`
    font-family: 'Open Sans';
    font-style: normal;
    margin-bottom: ${(props) => props.customMargin || '.5rem'} !important;
    &.MuiTypography-body2 {
        margin-bottom: .5rem;
    }
    &.MuiTypography-root {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: ${(props) => props.colorSubtitle || palette.brand.AzulBanreservas700B};
    }
`;

export const Paragraph = styled(Typography)`
    font-size: 16px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    color: ${palette.textAndLine.PlataBanreservas700B};
    margin-bottom: 2rem;
`;

export const BottomParagraph = styled(Typography)`
    font-size: 14px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    color: ${palette.textAndLine.PlataBanreservas700B};
    padding: 1.5rem 0;
`;

export const BodyUserText = styled(Typography)`
    &.MuiTypography-root {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        color: #264E72;
    }
`;

export const BodysubTitle = styled(Typography)`
    &.MuiTypography-root {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #626266;
    }
`;

export const BoxBodyContainer = styled.div`
    padding: 16px 16px;
    gap: 16px;
    height: auto;
    background-color: #ECF7FB;
    border-radius: 16px;
    margin: 40px 0 0;
`;

export const BorderSeparator = styled.div`
    width: 100%;
    border-bottom: 1px solid #F4F5F7;
    margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 1.5rem;
    padding-top: 1rem;
    z-index: 5;
    box-shadow: 0px -20px 10px 0px rgba(255, 255, 255, .5);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 52%, rgba(255,255,255,0.6307116596638656) 100%); 
`;

export const ContinueButton = styled(Button)`
    text-align: center;
    background-color: ${palette.brand.AzulBanreservas100B};
    color: ${(props) => props.buttoncolor || palette.textAndLine.BlancoBanreservas000} !important;
    width: 100%;
    max-width: calc(100vw - 1rem);
    

    &.Mui-disabled{
        background-color: ${palette.textAndLine.PlataBanreservas100};
        color: ${(props) => props.buttoncolor || palette.textAndLine.PlataBanreservas300} !important;
    }

    &.MuiButton-containedPrimary {
        border-radius: 8px;
        font-weight: 700;
        height: 40px;
    }

    &.MuiButton-textPrimary {
        background-color: ${palette.textAndLine.BlancoBanreservas000};
        color: ${palette.brand.AzulBanreservas100B} !important;
        margin-top: 1rem;
        border-radius: 8px;
        font-weight: 700;
        height: 40px;
    }
    &:focus {
        outline: unset;
    }
`;

export const IconButton = styled(Button)`
    border-radius: 8px;
    color: #fff;
    color: ${palette.brand.AzulBanreservas700B};
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: bold;
    height: 4rem;
    justify-content: flex-start;
    margin: 0  0 1.5rem 0;
    padding: 0 22px;
    text-transform: initial;
    width: 100%;

    &.MuiButton-outlined {
        background-color: transparent;
        border: 1px solid ${palette.textAndLine.PlataBanreservas300};
        &:hover {
            background-color: transparent;
        }
    } 

    &.MuiButton-contained {
        box-shadow: none;
        background-color: ${palette.semantic.InfoBanreservas100};
        &:hover {
            background-color: ${palette.semantic.InfoBanreservas100};
        }
    }
    
    .MuiButton-startIcon {
        display: flex;
        justify-content: center;
        margin-right: 1rem;
        width: 22px;
    }
`;

export const StyledLink = styled(Link)`
    font-family: 'Open Sans';
    font-weight: bold;
    color: ${palette.brand.AzulBanreservas700B};
    margin-left: 5px;
`;

export const TitleContainer = styled.div`
    text-align: center;
`;

export const TitleModalNPS = styled(Typography)`
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px;
text-align: center;
color: ${palette.brand.AzulBanreservas700B};
`;

export const TitleModalNPSContainer = styled.div`
display: flex;
padding: 0px 24px;
align-items: flex-start;
gap: 8px;
align-self: stretch;
`;

export const StyledNPSBodyContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 24px;
`;

export const InfoIconHelper = styled(InfoIcon)`
    position: 'absolute' !important;
    bottom: '10px' !important;
    right: '10px' !important;
`;
