import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BodyUserText,
} from 'Core/Styles/CommonStyles';
import ShareButton from 'Components/ShareButton';
import CustomTooltip from 'Components/CustomTooltip/CustomTooltip';
import CopyIcon from 'Core/Utils/Assets/CopyIcon';
import {
  StyledBorderSeparator, BigStyledLink,
  StyledSeparator, TransactionContainer,
  TransactionItem, StyledBoxBodyContainer,
  Description,
} from './style';

const copyToClipboard = (message) => {
  navigator.clipboard.writeText(message).then(() => {
    console.log('Cuenta copiada en clipboard');
  }).catch((err) => console.log('ERROR: ', err));
};

const isIphone = () => navigator.userAgent.toLowerCase().includes('iphone');

const AccountNumberCard = ({ accountNumber, transactionNumber, openingAmount }) => {
  // eslint-disable-next-line no-unused-vars
  const shareMessage = `${accountNumber}`;
  const { hasAccount } = useSelector((state) => state.clientInfo);

  const handlerCopy = () => {
    if (isIphone()) {
      copyToClipboard(shareMessage);
    }
  };
  return (
    <>
      <StyledBoxBodyContainer hasSquaredBorders={hasAccount}>
        <BodyUserText>
          <div>
            Número de cuenta
          </div>
          <br />

          <BigStyledLink onClick={handlerCopy} data-testid="copy-button">
            {accountNumber}
            {' '}
            {hasAccount && isIphone()
              ? (
                <CustomTooltip
                  title="Copiado"
                  isDark
                  hasCloseButton={false}
                >
                  <CopyIcon />
                </CustomTooltip>
              )
              : null}
          </BigStyledLink>
        </BodyUserText>
        {hasAccount && isIphone()
          ? (
            <>
              <StyledBorderSeparator />
              <ShareButton />
            </>
          )
          : null}
      </StyledBoxBodyContainer>
      {hasAccount
        && (
          <TransactionContainer>
            <TransactionItem>
              <div>Número transacción</div>
              <Description>{transactionNumber}</Description>
            </TransactionItem>
            <StyledSeparator />
            <TransactionItem>
              <div>Monto apertura</div>
              <Description>{openingAmount}</Description>
            </TransactionItem>
          </TransactionContainer>
        )}
    </>
  );
};

AccountNumberCard.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  transactionNumber: PropTypes.string.isRequired,
  openingAmount: PropTypes.string.isRequired,
};

export default AccountNumberCard;
