/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { StyledLink, IconButton } from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';

export const CustomStyledLink = styled(StyledLink)`
      color: ${palette.brand.AzulBanreservas100B} !important;
      text-decoration: underline !important;
      text-decoration-color: rgba(0,174,240,0.4) !important;
      
      padding-right: .5rem;
      & + button.MuiButtonBase-root {
        width: 24px;
        height: 20px;
        min-width: 24px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        & span {
          display: none;
        }
      }
  `;

export const ButtonShare = styled(IconButton)`
    display: flow-root !important;
  `;
