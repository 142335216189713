import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const measurementIdDPD = (isDPD = false) => {
  const measurementID = isDPD ? process.env.REACT_APP_DPD_FIREBASE_MEASUREMENT_ID
    : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
  return measurementID;
};

const appIdDPD = (isDPD = false) => {
  const appID = isDPD ? process.env.REACT_APP_DPD_FIREBASE_APP_ID
    : process.env.REACT_APP_FIREBASE_APP_ID;
  return appID;
};

const firebaseConfig = (path = '/') => {
  const isDPD = path.includes('/deposito');

  return {
    apiKey: process.env.REACT_APP_J1,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: appIdDPD(isDPD),
    measurementId: measurementIdDPD(isDPD),
  };
};

const { pathname } = window.location;

const app = initializeApp(firebaseConfig(pathname));
const analytics = getAnalytics(app);

export default analytics;
