/*eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Header from 'Components/Header';
import { BodyContent, ButtonContainer, ContinueButton } from 'Core/Styles/CommonStyles';
import useDebounce from 'Hooks/useDebounce';
import useCard from 'Services/api/useCard';
import {
  setAmount,
  setCDOpened,
  setCDSelectedTerm,
  setInterestType,
} from 'Core/Store/Reducers/certificateDepositOfferSlice';
import { calculatePerformanceAmmount, formatNumber } from 'Core/Utils/UtilityFunctions';
import CDInputText from 'Components/CDInputText';
import CD_CALCULATOR_CONFIG from 'Constants/CDCalculator';
import {
  AmountContainer,
  BodyContainer,
  DepositContainer,
  DepositInput,
  DepositTitle,
  HelperText,
  InterestType,
  InterestTypeContainer,
  InterestTypeRadio,
  InterestTypeSection,
  PerformanceTableBtn,
  PerformanceTableBtnContainer,
  SectionTitle,
  SummaryAmount,
  SummaryContainer,
  SummaryDescription,
  SummaryTitle,
  TermButton,
  TermContainer,
  TermSection,
  ToggleAmount,
  ToggleContainer,
  ToggleDescription,
  ToggleTitle,
} from './style';
import SheetInfo from 'Components/SheetInfo';
import BaseDrawer from 'Components/Drawer/Drawer';
import { InfoIconContainer } from './style';
import InfoIcon from 'Core/Utils/Assets/InfoIcon';
import LoadingScreen from 'Components/LoadingScreen';
import { SpinnerText2 } from 'Constants/SpinnerText';
import usePerformanceTable from 'Services/api/usePerformanceTable';
import InterestCreditInput from 'Components/InterestCreditInput';
import { number } from 'prop-types';

const { REACT_APP_SHOW_TARIFF } = process.env;

const Calculator = () => {
  const analytics = getAnalytics();
  const dispatch = useDispatch();
  const { getCards } = useCard();
  const { getPerformanceTable } = usePerformanceTable();
  const { defaultTerm, interestTypes, defaultInterestType, defaultPerformanceAmount } =
    CD_CALCULATOR_CONFIG;
  const {
    amount,
    currency,
    ratesTable,
    interestRate,
    interestType,
    terms,
    cdSelectedTerm,
    minInvestmentAmount,
    maxInvestmentAmount,
    accountBalance,
    cdOpened,
    availableAmount
  } = useSelector((state) => state.certificateDepositOffer);
  const [performanceAmount, setPerformanceAmount] = useState(defaultPerformanceAmount);
  const [rate, setRate] = useState(interestRate);
  /* Valores a usarse en para el calculo */
  const [selectedTerm, setSelectedTerm] = useState(terms[0]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedType, setSelectedType] = useState(defaultInterestType);
  const [isloading, setIsloading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isValidated, setIsValidated] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [interestAmount, setInterestAmount] = useState(0);
  const amountInput = useRef(null);
  const formRef = useRef();
  const toggleRef = useRef();

  const handleEdit = () => {
    amountInput.current.children[0].focus();
  };

  const handleScroll = () => {
    const position = window.scrollY;
    let toggleElement;
    setScrollPosition(position);

    if (toggleRef.current) {
      toggleElement = toggleRef.current;
      position >= 50
        ? toggleElement.classList.add('toggle-up')
        : toggleElement.classList.remove('toggle-up');
    }
  };

  function calculateMonthlyPerformance(investmentAmount, interestRate) {
    const interestRateDecimal = interestRate / 100;
    const numericInvestmentAmount = Number(investmentAmount.replace(/,/g, ''));

    const monthlyPerformance = (numericInvestmentAmount * interestRateDecimal) / 12;
    
    return monthlyPerformance;
  }

  const decimalPrecision = (num, precision) => {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  };

  const getUnmaskedAmount = (value) => {
    if (!Number.isNaN(value)) return parseFloat(value?.replace(/,/g, ''));
    return value;
  };

  const setValueForConfirmation = (values) => {
    let cdToConfirm = {
      totalProfitability: '',
      monthlyProfitability: '',
      rate: '',
      term: '',
      profitType: '',
      productType: '',
    };
    // Capital + Rendimiento
    cdToConfirm.totalProfitability = performanceAmount;
    // Rate
    cdToConfirm.rate = rate;
    // Monto
    const unmaskedAmount = getUnmaskedAmount(values.selectedAmount);
    dispatch(setAmount(unmaskedAmount));
    // Rendimineto Mensual
    cdToConfirm.monthlyProfitability = decimalPrecision(
      (performanceAmount - unmaskedAmount) / selectedTerm,
      2,
    );
    //Plazo
    cdToConfirm.term = selectedTerm;
    dispatch(setCDSelectedTerm(selectedTerm));
    //Tipo de interés
    cdToConfirm.profitType = selectedType;
    dispatch(setInterestType(selectedType));

    dispatch(setCDOpened(cdToConfirm));
  };

  const amountDebounce = useDebounce((value) => {
    if (!value) return;
    setSelectedAmount(value);
  }, 350);

  function formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    return value?.toLocaleString('en-US', { minimumFractionDigits, maximumFractionDigits });
  }

  const validationSchema = Yup.object().shape({
    selectedAmount: Yup.string()
      .required(
        `El monto solicitado es inferior al monto permitido ${minInvestmentAmount?.toLocaleString(
          'en-US',
        )}.`,
      )
      .when({
        is: (value) => getUnmaskedAmount(value) < minInvestmentAmount,
        then: Yup.string().length(
          0,
          `El monto solicitado es inferior al monto permitido ${minInvestmentAmount?.toLocaleString(
            'en-US',
          )}.`,
        ),
      })
      .when({
        is: (value) => getUnmaskedAmount(value) > maxInvestmentAmount,
        then: Yup.string().length(
          0,
          `El monto solicitado es superior al máximo permitido ${maxInvestmentAmount?.toLocaleString(
            'en-US',
          )}.`,
        ),
      })
      .when({
        is: (value) => getUnmaskedAmount(value) > accountBalance,
        then: Yup.string().length(0, `Balance en cuentas insuficiente`),
      })
      .when({
        is: (value) => getUnmaskedAmount(value) > availableAmount,
        then: Yup.string().length(
          0,
          'El monto a invertir no puede superar el tope disponible',
        ),
      }),
  });

  const getRate = (term, amount) => {
    const ratesByTerm = ratesTable.find((rateByTerm) => rateByTerm.term === term);
    const rateByAmount = ratesByTerm?.rates?.find(
      (rateByAmount) => amount >= rateByAmount.minAmount && amount <= rateByAmount.maxAmount,
    );
    if (rateByAmount) {
      setRate(rateByAmount.rate);
    }
  };

  const submitForm = (values) => {
    setValueForConfirmation(values);
    getCards();
    logEvent(analytics, 'Simulator', {
      content_type: 'Button - Continue to next screen',
      content_id: 'Simulator - Continuar',
    });
  };

   // Efecto para colocar valores del store
   useEffect(() => {
    if (!cdOpened || !interestType || !cdSelectedTerm || !amount) return;

    setPerformanceAmount(cdOpened?.totalProfitability);
    setRate(cdOpened?.rate);
    setSelectedTerm(cdSelectedTerm);
  }, [cdOpened, interestType, cdSelectedTerm, amount]);

  // Efecto para actualizar el rate
  useEffect(() => {
    if (!selectedAmount || !selectedTerm || !isValidated) {
      setInterestAmount(0);
      return;
    }

    const unmaskedAmount = getUnmaskedAmount(selectedAmount);
    getRate(selectedTerm, unmaskedAmount);
    setInterestAmount(calculateMonthlyPerformance(selectedAmount, rate));
  }, [selectedTerm, selectedAmount]);

  // Effecto para actualizar el rendimiento
  useEffect(() => {
    if (!selectedAmount || !selectedTerm || !selectedType || !rate ) return;
    const unmaskedAmount = getUnmaskedAmount(selectedAmount);
    if(unmaskedAmount > maxInvestmentAmount) return ;
    setPerformanceAmount( 
      calculatePerformanceAmmount(unmaskedAmount, rate, selectedTerm, selectedType),
    );
    setInterestAmount(calculateMonthlyPerformance(selectedAmount, rate));
  }, [selectedTerm, selectedAmount, selectedType, rate]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const hasAvailableAmount = availableAmount >= 10000;

  return !isloading ? (
    <Formik
      enableReinitialize
      initialValues={{ selectedAmount: amount, term: defaultTerm, type: defaultInterestType }}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ isValid, handleSubmit, handleChange, values }) => (
        <Form ref={formRef}>
          {setIsValidated(isValid)}
          <Header title="Simulador" />
          <BodyContainer>
            <BodyContent>
              <SummaryContainer>
                <SummaryTitle>Capital + rendimientos</SummaryTitle>
                <SummaryDescription>
                  Tu tasa será de <strong>{(+rate).toFixed(2)}%</strong> interés anual.
                </SummaryDescription>
                <SummaryAmount>
                  <span>DOP</span>
                  <span>{formatCurrency(performanceAmount)}</span>
                </SummaryAmount>
                <SummaryDescription>
                  Este cálculo es una estimación y no contempla la retención de impuestos.
                </SummaryDescription>
                <InfoIconContainer onClick={() => setIsDrawerOpen(true)} data-testid="info-icon">
                  <InfoIcon/>
              </InfoIconContainer>
              </SummaryContainer>
              <DepositContainer>
                <DepositTitle>Monto a invertir</DepositTitle>
                {hasAvailableAmount ? 
                  (<HelperText>Tope disponible DOP {formatNumber(availableAmount)}</HelperText>) : 
                  (<HelperText color='red'>El total acumulado de tus depósitos supera el máximo permitido</HelperText>)
                }
                <DepositInput>
                  <AmountContainer>
                    <span>{currency}</span>
                    <CDInputText
                      inputRef={amountInput}
                      label=""
                      name="selectedAmount"
                      type="tel"
                      customMask={{
                        mask: Number,
                        thousandsSeparator: ',',
                      }}
                      placeholder="000,000"
                      onChange={(event, selectedAmount) => {  
                          amountDebounce(selectedAmount);
                      }}
                    />
                  </AmountContainer>
                </DepositInput>
              </DepositContainer>
              <TermSection>
                <SectionTitle>
                  <div>Elige tu plazo</div>
                </SectionTitle>
                <TermContainer
                  value={selectedTerm}
                  name="term"
                  exclusive
                  onChange={(event, term) => {
                    if (term) {
                      setSelectedTerm(term);
                      handleChange({ target: { name: 'term', value: term } });
                    }
                  }}
                  aria-label="plazos"
                >
                  {terms.map((term) => (
                    <TermButton key={`term.${term}`} value={term} aria-label={term}>
                      {term}
                      <small>Meses</small>
                    </TermButton>
                  ))}
                </TermContainer>
              </TermSection>
              <InterestTypeSection>
                <SectionTitle>
                  <div>Selecciona el tipo de interés</div>
                </SectionTitle>
                <InterestTypeContainer value={selectedType} name="type">
                  {interestTypes.map((type) => (
                    <InterestType
                      key={`${type.name}${type.value}`}
                      onClick={() => {
                        setSelectedType(type.value);
                        handleChange({ target: { name: 'type', value: type.value } });
                      }}
                    >
                      <InterestTypeRadio>
                        <Field type="radio" name={type.name} value={type.value} />
                        <label>{type.title}</label>
                      </InterestTypeRadio>
                      <div>{type.description}</div>
                      {(selectedType === type.value && type.hasInput) && <InterestCreditInput data-testid="interest-input" shownAmount={`DOP ${formatNumber(interestAmount, true)}`}/>}
                    </InterestType>
                  ))}
                </InterestTypeContainer>
              </InterestTypeSection>
              <PerformanceTableBtnContainer>
                {REACT_APP_SHOW_TARIFF === 'true' && selectedType === defaultInterestType ? (
                  <PerformanceTableBtn
                    variant="contained"
                    disableElevation
                    disabled={!isValid}
                    onClick={() => {
                      const unmaskedAmount = getUnmaskedAmount(values.selectedAmount);
                      getPerformanceTable({ unmaskedAmount, selectedTerm, rate, performanceAmount });
                      setIsloading(true);
                    }}
                    style={{ marginBottom: 16 }}
                  >
                    Ver tabla de rendimientos
                  </PerformanceTableBtn>
                ) : null}
              </PerformanceTableBtnContainer>
            </BodyContent>
          </BodyContainer>

          <ButtonContainer>
            <ToggleContainer ref={toggleRef}>
              <ToggleTitle>Capital + rendimientos</ToggleTitle>
              <ToggleAmount>
                <span>DOP</span>
                <span>{formatCurrency(performanceAmount)}</span>
              </ToggleAmount>
              <ToggleDescription>El cálculo no contempla retención de impuestos</ToggleDescription>
            </ToggleContainer>
            <ContinueButton
              variant="contained"
              disableElevation
              disabled={!isValid || !hasAvailableAmount}
              onClick={handleSubmit}
            >
              CONTINUAR
            </ContinueButton>
          </ButtonContainer>
          <BaseDrawer isOpen={isDrawerOpen} toggleDrawer={setIsDrawerOpen}>
            <SheetInfo />
          </ BaseDrawer>
        </Form>
      )}
    </Formik>
  ) : (
    <LoadingScreen isOpen text={SpinnerText2} isWhite />
  );
};

export default Calculator;
