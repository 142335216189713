import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { CARDS_DATA_PAYLOAD } from 'Constants/Cards';
import { setAccountBalance, setAvailableAmount } from 'Core/Store/Reducers/certificateDepositOfferSlice';
import { CD_MAXIMUM_PRODUCT_AMOUNT } from 'Constants/CDCalculator';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import endpoints from 'Core/Utils/ApiEndpoints';
import useGet from './useGet';

const { REACT_APP_ENVIRONMENT } = process.env;

const useMaximumProductAmount = () => {
  const [isValidMaximumProductAmount, setValidMaximumProductAmount] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    document,
    documentType,
    authorization,
  } = useSelector((state) => state.clientInfo);
  const cardsUrl = CARDS_DATA_PAYLOAD(document, documentType);
  const { data, callApi } = useGet(
    endpoints.maximumProductAmmount + cardsUrl,
    {
      ...API_HEADERS(),
      Authorization: `Bearer ${authorization}`,
    },
    REACT_APP_ENVIRONMENT === 'DEV',
    CD_MAXIMUM_PRODUCT_AMOUNT[200],
  );
  const isCalled = useRef(false);

  const getMaximumProductAmount = () => {
    if (isCalled.current === false) {
      isCalled.current = true;
      callApi();
      // appInsights.trackPageView('Get App Status API');
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setAccountBalance(data.amount));
      dispatch(setAvailableAmount(data.available));
      setValidMaximumProductAmount(true);
    } else if (data !== undefined) {
      setValidMaximumProductAmount(false);
      navigate('/clientInfoError');
    }
  }, [data]);

  return {
    isValidMaximumProductAmount,
    getMaximumProductAmount,
  };
};

export default useMaximumProductAmount;
