import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { useField } from 'formik';

import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';

import { palette } from 'Core/Styles/Theme';
import Chevron2Up from 'Core/Utils/Assets/Chevron-2-Up';
import Chevron2Down from 'Core/Utils/Assets/Chevron-2-Down';
import { StyledFormControl } from './style';

const SelectInput = ({
  label,
  name,
  labelId,
  data,
  placeholder,
  displayEmpty,
  customOnchange,
  defaultHelperText,
}) => {
  const [field, meta, helpers] = useField({ label, name });
  const { onChange, onBlur, value: selectedValue } = field;
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { touched, error } = meta;
  const { setTouched, setValue } = helpers;
  const hasError = touched && !!error;
  const inputOnchange = (e) => {
    const selectedData = data.find((item) => item.value === e.target.value);
    customOnchange(e, selectedData);
    onChange(e);
  };

  const ITEM_MAX_HEIGHT = 48;
  const ITEM_PADDING_TOP = 6;
  const TOTAL_OF_ITEMS_TO_SHOW = 5;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_MAX_HEIGHT * TOTAL_OF_ITEMS_TO_SHOW + ITEM_PADDING_TOP,
        width: 198,
      },
      sx: {
        '& .MuiMenuItem-root.Mui-selected': {
          backgroundColor: palette.brand.AzulBanreservas100B,
          borderRadius: '4px',
          color: palette.textAndLine.BlancoBanreservas000,
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: palette.brand.AzulBanreservas100B,
          borderRadius: '4px',
          color: palette.textAndLine.BlancoBanreservas000,
        },
        '& .MuiMenuItem-root.Mui-selected:hover': {
          backgroundColor: palette.brand.AzulBanreservas100B,
          borderRadius: '4px',
          color: palette.textAndLine.BlancoBanreservas000,
        },
      },
    },
  };

  const filterValue = (selected) => {
    const filteredItem = data.find((element) => element.value === selected);
    return filteredItem.label;
  };

  useEffect(() => {
    setValue('');
  }, [data]);
  return (
    <StyledFormControl error={hasError}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        data-testid="select-input"
        MenuProps={MenuProps}
        displayEmpty={displayEmpty}
        labelId={labelId}
        name={name}
        value={selectedValue}
        label={label}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        IconComponent={isOpen ? Chevron2Up : Chevron2Down}
        onChange={inputOnchange}
        renderValue={(value) => {
          if (value) {
            return (
              <Box sx={{ display: 'flex' }}>
                <Check fontSize="small" color="primary" />
                {filterValue(value)}
              </Box>
            );
          }
          if (!value && showPlaceholder) {
            return <em>{placeholder}</em>;
          }

          return value;
        }}
        onFocus={() => {
          if (!selectedValue) {
            setShowPlaceholder(true);
          } else {
            setShowPlaceholder(false);
          }
        }}
        onClickCapture={() => {
          setTouched(true);
        }}
        onBlur={(e) => {
          if (!selectedValue) {
            setShowPlaceholder(false);
          }
          setTouched(true);
          onBlur(e);
        }}
      >
        {data.map((status) => (
          <MenuItem key={status.id} value={status.value}>{status.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText error={hasError}>{hasError ? error : defaultHelperText}</FormHelperText>
    </StyledFormControl>
  );
};

SelectInput.defaultProps = {
  displayEmpty: false,
  placeholder: '',
  customOnchange: () => {},
  defaultHelperText: '',
};

SelectInput.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  displayEmpty: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  customOnchange: PropTypes.func,
  defaultHelperText: PropTypes.string,
};

export default SelectInput;
