/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { palette } from 'Core/Styles/Theme';
import { CONTACT_NUMBER, CONTACT_NUMBER_DISPLAY_VALUE } from 'Constants/ContactNumber';
import { StyledAnchor } from './style';

const TextWithContact = ({ text }) => {
  const textArr = text.split('*');

  return (
    <div>
      {textArr && (
        <div style={{ color: palette.textAndLine.PlataBanreservas700B, fontWeight: 400 }}>
          {textArr[0]}
          <StyledAnchor href={`tel:${CONTACT_NUMBER}`}>{CONTACT_NUMBER_DISPLAY_VALUE}</StyledAnchor>
          {textArr[1]}
        </div>
      ) }
    </div>
  );
};

TextWithContact.defaultProps = {
  text: '',
};

TextWithContact.propTypes = {
  text: PropTypes.string,
};

export default TextWithContact;
