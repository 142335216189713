/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Header from 'Components/Header';
import { SpinnerText2 } from 'Constants/SpinnerText';
import {
  BodyContainer,
  BodyContent,
  BottomParagraph,
  ButtonContainer,
} from 'Core/Styles/CommonStyles';
import LoadingScreen from 'Components/LoadingScreen';
import useStep from 'Services/useStep';
import useSelectCard from 'Services/api/useSelectCard';
import useExchange from 'Services/api/useExchange';
import MoneyExchange from 'Core/Utils/Assets/MoneyExchange';
import AlertDialog from 'Components/AlertDialog';
import RadioButtonGroup from 'Components/RadioButtonGroup';
import { PageTitle } from 'Components/FirstDeposit/style';
import currencyMessages from 'Constants/CardMessages';
import { SectionTitle } from 'Pages/Calculator/style';
import { setCDSelectedAccount } from 'Core/Store/Reducers/certificateDepositOfferSlice';
import { OpenButton, Disclaimer, PageSubtitle } from './style';

const SelectAccount = () => {
  const { availableAccounts, interestType } = useSelector((state) => state.certificateDepositOffer);
  const analytics = getAnalytics();
  const { generateToken } = useStep();
  const dispatch = useDispatch();
  const { getSelectCard } = useSelectCard();
  const [isOpen, setIsOpen] = useState(false);
  const [isDifferentCurrency, setDifferentCurrency] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [isAlertLoading, setIsAlertloading] = useState(false);
  const [helpMessage, setHelpMessage] = useState('');
  const [depositCurrency, setDepositCurrency] = useState('DOP');
  const [dirtyCheck, SetdirtyCheck] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(availableAccounts[0].value);
  const { exchangeRate, getExchangeData } = useExchange();

  useEffect(() => {
    if (exchangeRate.tasa !== -1) {
      setIsAlertloading(false);
      setIsOpen(true);
    }
  }, [exchangeRate]);

  const formSubmit = (values) => {
    dispatch(setCDSelectedAccount(values.selectedAccount));
    getSelectCard(values.selectedAccount);
    generateToken();
    logEvent(analytics, 'Founds Origin', {
      content_type: 'Button - Continue to next screen',
      content_id:
        'Boton de continuar de la pantalla first Deposit',
    });
  };

  const setNewCurrency = (card) => {
    const newCurrency = currencyMessages.find(
      (cardMessage) => cardMessage.currency === card.accountCurrency,
    );
    setHelpMessage(newCurrency.message);
    setDifferentCurrency(newCurrency.isDifferent);
    setDepositCurrency(newCurrency.currency);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        selectedAccount,
      }}
      onSubmit={formSubmit}
    >
      {({
        handleSubmit, dirty, handleChange, values,
      }) => {
        if (!dirtyCheck && (dirty || selectedAccount)) {
          SetdirtyCheck(true);
        }
        return (
          <Form>
            <Header title="Origen de fondos" />
            <BodyContainer>
              <BodyContent>
                <PageTitle>¡Estamos casi listos!</PageTitle>
                <PageSubtitle>
                  Elige la cuenta desde donde haremos el débito del capital para abrir tu Depósito a
                  Plazo Digital.
                </PageSubtitle>
                <SectionTitle style={{ marginBottom: '16px' }}>
                  <div>Selecciona la cuenta</div>
                </SectionTitle>
                <RadioButtonGroup
                  name="selectedAccount"
                  radioGroup={availableAccounts}
                  handleChange={(e) => {
                    setNewCurrency(
                      availableAccounts.find((account) => account.value === e.target.value),
                    );
                    handleChange(e);
                  }}
                  values={values}
                  defaultValue={selectedAccount}
                  darkColor
                />
                <Disclaimer>* Solo se presentan las cuentas con el balance suficiente.</Disclaimer>
                {interestType === 'CRED' && (
                  <Disclaimer>
                    * En la cuenta seleccionada se pagarán los intereses del Depósito a Plazo
                    Digital.
                  </Disclaimer>
                )}
              </BodyContent>
            </BodyContainer>
            <ButtonContainer>
              {helpMessage === '' ? null : <BottomParagraph>{helpMessage}</BottomParagraph>}
              {' '}
              <OpenButton
                variant="contained"
                disabled={!values?.selectedAccount}
                onClick={() => {
                  if (isDifferentCurrency) {
                    setIsAlertloading(true);
                    getExchangeData(depositCurrency);
                  } else {
                    setIsloading(true);
                    handleSubmit();
                  }
                }}
              >
                CONTINUAR
              </OpenButton>
              {!isAlertLoading ? (
                <AlertDialog
                  dialogIcon={<MoneyExchange />}
                  title="Seleccionaste una cuenta en moneda extranjera"
                  subtitle={[
                    'Debido a la tasa de cambio del día serán depositados ',
                    <strong>{`DOP ${exchangeRate.tasa}`}</strong>,
                  ]}
                  onClose={() => setIsOpen(false)}
                  isOpen={isOpen}
                  onClick={() => {
                    setIsloading(true);
                    handleSubmit();
                    logEvent(analytics, 'Founds Origin', {
                      content_type: 'Button - Continue - Foreign Currency',
                      content_id:
                      'Boton de continuar de la pantalla first Deposit Moneda Extranjera',
                    });
                    logEvent(analytics, 'Founds Origin', {
                      content_type: 'Button - Continue - National Currency',
                      content_id:
                      'Boton de continuar de la pantalla first Deposit Moneda Nacional',
                    });
                  }}
                />
              ) : (
                <LoadingScreen isOpen={isAlertLoading} text={SpinnerText2} />
              )}
            </ButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SelectAccount;
