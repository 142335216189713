import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const CloseCircle = ({ color }) => (
  <svg width="25" height="23" viewBox="0 3 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 7.582 17.4183 4 13 4C8.58224 4 5 7.58224 5 12C5 16.4178 8.58224 20 13 20C17.4183 20 21 16.418 21 12ZM6.2 12C6.2 8.24496 9.24496 5.2 13 5.2C16.7555 5.2 19.8 8.24472 19.8 12C19.8 15.7553 16.7555 18.8 13 18.8C9.24496 18.8 6.2 15.755 6.2 12ZM16.0428 9C16.1213 9 16.1855 9.06421 16.1855 9.14269C16.1855 9.17658 16.1748 9.21046 16.1534 9.23543L13.8329 12L16.1498 14.7628C16.1712 14.7895 16.1837 14.8216 16.1837 14.8555C16.1837 14.9358 16.1195 14.9982 16.041 14.9982L14.8639 14.9929L13.0927 12.8811L11.3216 14.9946L10.1427 15C10.0642 15 10 14.9358 10 14.8573C10.0004 14.8234 10.0123 14.7907 10.0339 14.7646L12.3543 12.0018L10.0339 9.23722C10.0125 9.21046 10 9.17836 10 9.14447C10 9.06421 10.0642 9.00178 10.1427 9.00178L11.3216 9.00713L13.0927 11.1189L14.8656 9.00535L16.0428 9Z"
      fill={color}
    />
  </svg>
);

CloseCircle.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

CloseCircle.propTypes = {
  color: PropTypes.string,
};

export default CloseCircle;
