/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { palette } from 'Core/Styles/Theme';

export const StyledRatingButtonGroup = styled.div`
display: flex;
padding: 0px 24px;
justify-content: space-around;
align-items: flex-start;
gap: 40px;
align-self: stretch;
width: 325px;
`;

export const StyledButtonContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
`;

export const StyledAvatar = styled(Avatar)`
background-color: ${palette.textAndLine.BlancoBanreservas000};
border: 3px solid ${(props) => (props.isSelected ? palette.brand.AzulBanreservas100B
    : palette.textAndLine.PlataBanreservas100)};
width: 48px;
height: 48px;
`;

export const StyledSpan = styled.span`
color: #FFF;
text-align: center;
font-family: Open Sans;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 20px;
`;

export const StyledRatingText = styled(Typography)`
font-family: Open Sans;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 16px; 
color: ${palette.brand.AzulBanreservas700B};
`;
