import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const ChevronRight = ({ color }) => (
  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Vectors">
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.16179 7.7171C0.704066 7.7171 0.333008 8.08816 0.333008 8.54589C0.333008 9.7918 1.32147 10.6966 2.40944 11.2406C3.54699 11.8094 5.06049 12.1373 6.687 12.1373C8.01711 12.1373 9.26526 11.9182 10.2935 11.532C10.722 11.3711 10.9389 10.8933 10.778 10.4648C10.6171 10.0363 10.1392 9.81938 9.71073 9.9803C8.8921 10.2878 7.84457 10.4797 6.687 10.4797C5.26202 10.4797 4.01292 10.1892 3.15073 9.75806C2.23896 9.30218 1.99057 8.82572 1.99057 8.54589C1.99057 8.08816 1.61951 7.7171 1.16179 7.7171Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.16179 3.42929C0.704066 3.42929 0.333008 3.80035 0.333008 4.25807V12.4135C0.333008 13.6594 1.32147 14.5643 2.40944 15.1083C3.54699 15.677 5.06049 16.0049 6.687 16.0049C8.01724 16.0049 9.26534 15.7856 10.2935 15.3995C10.722 15.2385 10.9389 14.7607 10.778 14.3322C10.6171 13.9037 10.1392 13.6868 9.71073 13.8478C8.89201 14.1552 7.8445 14.3474 6.687 14.3474C5.26202 14.3474 4.01292 14.0568 3.15073 13.6257C2.23896 13.1698 1.99057 12.6934 1.99057 12.4135V4.25807C1.99057 3.80035 1.61951 3.42929 1.16179 3.42929Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2122 3.42929C11.7545 3.42929 11.3834 3.80035 11.3834 4.25807V7.5732C11.3834 8.03092 11.7545 8.40198 12.2122 8.40198C12.6699 8.40198 13.041 8.03092 13.041 7.5732V4.25807C13.041 3.80035 12.6699 3.42929 12.2122 3.42929Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0525 10.2878C20.5948 10.2878 20.2238 10.6589 20.2238 11.1166V14.6236C20.2238 14.9035 19.9754 15.3799 19.0636 15.8358C18.2014 16.2669 16.9523 16.5574 15.5273 16.5574C14.1024 16.5574 12.8533 16.2669 11.9911 15.8358C11.0793 15.3799 10.8309 14.9035 10.8309 14.6236V11.1166C10.8309 10.6589 10.4598 10.2878 10.0021 10.2878C9.5444 10.2878 9.17334 10.6589 9.17334 11.1166V14.6236C9.17334 15.8695 10.1618 16.7744 11.2498 17.3184C12.3873 17.8871 13.9008 18.215 15.5273 18.215C17.1538 18.215 18.6673 17.8871 19.8049 17.3184C20.8929 16.7744 21.8813 15.8695 21.8813 14.6236V11.1166C21.8813 10.6589 21.5103 10.2878 21.0525 10.2878Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0525 9.37467C20.5948 9.37467 20.2238 9.74573 20.2238 10.2034C20.2238 10.4833 19.9754 10.9597 19.0636 11.4156C18.2014 11.8467 16.9523 12.1373 15.5273 12.1373C14.1024 12.1373 12.8533 11.8467 11.9911 11.4156C11.0793 10.9597 10.8309 10.4833 10.8309 10.2034C10.8309 9.74573 10.4598 9.37467 10.0021 9.37467C9.5444 9.37467 9.17334 9.74573 9.17334 10.2034C9.17334 11.4494 10.1618 12.3542 11.2498 12.8982C12.3873 13.467 13.9008 13.7948 15.5273 13.7948C17.1538 13.7948 18.6673 13.467 19.8049 12.8982C20.8929 12.3542 21.8813 11.4494 21.8813 10.2034C21.8813 9.74573 21.5103 9.37467 21.0525 9.37467Z"
          fill={color}
        />
        <path
          d="M21.0525 10.2878C20.5948 10.2878 20.2238 10.6589 20.2238 11.1166L20.2244 12.6796H21.882L21.8813 11.1166C21.8813 10.6589 21.5103 10.2878 21.0525 10.2878Z"
          fill={color}
        />
        <path
          d="M10.8309 12.6796L10.8309 11.1166C10.8309 10.6589 10.4598 10.2878 10.0021 10.2878C9.5444 10.2878 9.17334 10.6589 9.17334 11.1166L9.17334 12.6796H10.8309Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2233 3.0459C11.135 3.50178 11.3834 3.97824 11.3834 4.25807C11.3834 4.53791 11.135 5.01436 10.2233 5.47025C9.36108 5.90134 8.11198 6.1919 6.687 6.1919C5.26202 6.1919 4.01292 5.90134 3.15073 5.47025C2.23897 5.01436 1.99057 4.53791 1.99057 4.25807C1.99057 3.97824 2.23897 3.50178 3.15073 3.0459C4.01292 2.61481 5.26202 2.32425 6.687 2.32425C8.11198 2.32425 9.36108 2.61481 10.2233 3.0459ZM10.9646 1.56333C9.82701 0.99456 8.31351 0.666687 6.687 0.666687C5.06049 0.666687 3.54699 0.99456 2.40944 1.56333C1.32147 2.10732 0.333008 3.01216 0.333008 4.25807C0.333008 5.50398 1.32147 6.40883 2.40944 6.95281C3.54699 7.52158 5.06049 7.84946 6.687 7.84946C8.31351 7.84946 9.82701 7.52158 10.9646 6.95281C12.0525 6.40883 13.041 5.50398 13.041 4.25807C13.041 3.01216 12.0525 2.10732 10.9646 1.56333Z"
          fill={color}
        />
      </g>
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0638 8.57129C19.9746 9.02672 20.2235 9.50268 20.2239 9.78263C20.2239 9.78283 20.2239 9.78303 20.2239 9.78323L20.2239 9.78346C20.2239 9.78378 20.2239 9.7841 20.2239 9.78441C20.2234 10.0644 19.9745 10.5403 19.0638 10.9956C18.2016 11.4267 16.9525 11.7173 15.5275 11.7173C14.1025 11.7173 12.8534 11.4267 11.9912 10.9956C11.0795 10.5398 10.8311 10.0633 10.8311 9.78346C10.8311 9.50363 11.0795 9.02717 11.9912 8.57129C12.8534 8.1402 14.1025 7.84964 15.5275 7.84964C16.9525 7.84964 18.2016 8.1402 19.0638 8.57129ZM20.2239 12.2468C20.0873 12.3301 19.9471 12.4072 19.8051 12.4782C18.6675 13.047 17.154 13.3748 15.5275 13.3748C13.901 13.3748 12.3875 13.047 11.25 12.4782C11.1079 12.4072 10.9675 12.3299 10.8307 12.2466L10.8305 14.1707C10.8309 14.1815 10.8311 14.1924 10.8311 14.2034C10.8311 14.4832 11.0795 14.9597 11.9912 15.4156C12.8534 15.8466 14.1025 16.1372 15.5275 16.1372C16.9525 16.1372 18.2016 15.8466 19.0638 15.4156C19.9756 14.9597 20.2239 14.4832 20.2239 14.2034C20.2239 14.203 20.2239 14.2025 20.2239 14.2021V12.2468ZM21.8679 14.4546H21.8815V14.2034V9.78646C21.8815 9.78546 21.8815 9.78446 21.8815 9.78346C21.8815 8.53755 20.893 7.63271 19.8051 7.08872C18.6675 6.51995 17.154 6.19208 15.5275 6.19208C13.901 6.19208 12.3875 6.51995 11.25 7.08872C10.1625 7.63246 9.17443 8.53673 9.17353 9.78176C9.17353 9.78225 9.17353 9.78274 9.17353 9.78323L9.17285 14.4546H9.18715C9.30939 15.5715 10.2365 16.3914 11.25 16.8981C12.3875 17.4669 13.901 17.7948 15.5275 17.7948C17.154 17.7948 18.6675 17.4669 19.8051 16.8981C20.8185 16.3914 21.7456 15.5715 21.8679 14.4546Z"
        fill={color}
      />
    </g>
  </svg>
);

ChevronRight.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

ChevronRight.propTypes = {
  color: PropTypes.string,
};

export default ChevronRight;
