import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import { CD_TARIFF_RESPONSE } from 'Constants/CDCalculator';
import {
  setInterestRate,
  setRateTable,
  setTerms,
} from 'Core/Store/Reducers/certificateDepositOfferSlice';
import endpoints from 'Core/Utils/ApiEndpoints';
import { CARDS_DATA_PAYLOAD } from 'Constants/Cards';
import useGet from './useGet';

const { REACT_APP_ENVIRONMENT } = process.env;

const useTariff = () => {
  const [isValidTariff, setValidTariff] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    document,
    documentType,
  } = useSelector((state) => state.clientInfo);
  const documentURL = CARDS_DATA_PAYLOAD(document, documentType);
  const { data, callApi } = useGet(
    endpoints.getTariff + documentURL,
    API_HEADERS(),
    REACT_APP_ENVIRONMENT === 'DEV',
    CD_TARIFF_RESPONSE[200],
  );
  const isCalled = useRef(false);

  const getTariff = () => {
    if (isCalled.current === false) {
      isCalled.current = true;
      callApi();
      // appInsights.trackPageView('Get App Status API');
    }
  };

  const setTermsData = (tariffData) => tariffData.map((tariffValue) => tariffValue.term);

  const setInteretRateData = (tariffData) => tariffData[0].rates[0].rate;

  useEffect(() => {
    if (data && data.ratesTable) {
      dispatch(setRateTable(data.ratesTable));
      dispatch(setTerms(setTermsData(data.ratesTable)));
      dispatch(setInterestRate(setInteretRateData(data.ratesTable)));
      setValidTariff(true);
    } else if (data !== undefined) {
      setValidTariff(false);
      navigate('/tarifError');
    }
  }, [data]);

  return {
    isValidTariff,
    getTariff,
  };
};

export default useTariff;
