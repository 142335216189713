/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import endpoints from 'Core/Utils/ApiEndpoints';
// import { appInsights } from 'appInsights';
import { isObjectEmpty } from 'Core/Utils/UtilityFunctions';
import useGet from './useGet';

const useGetAppStatus = () => {
  const navigate = useNavigate();
  const { data, callApi } = useGet(endpoints.appStatus, API_HEADERS());
  const isCalled = useRef(false);

  const getAppStatus = () => {
    if (isCalled.current === false) {
      isCalled.current = true;
      callApi();
      // appInsights.trackPageView('Get App Status API');
    }
  };

  useEffect(() => {
    if (data?.available === false) {
      navigate('/systemDown');
    } else if (!data && isObjectEmpty(data)) {
      navigate('/clientInfoError');
    }
  }, [data]);

  return {
    appStatus: data,
    getAppStatus,
  };
};

export default useGetAppStatus;
