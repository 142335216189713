/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  BpCheckedIcon, BpIcon, DarkStyledChip, RadioContainer, StyledChip,
} from './style';

const BpRadio = (props) => (
  <Radio disableRipple checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />
);

const RadioButtonGroup = ({
  name, radioGroup, handleChange, values, defaultValue, darkColor,
}) => {
  const change = (e) => {
    handleChange(e);
  };

  return (
    <RadioGroup
      onChange={change}
      value={values.inCompliance}
      name={name}
      defaultValue={defaultValue}
      sx={{ width: '100%' }}
    >
      {radioGroup.map(({ value, label, accountCurrency }) => (
        <RadioContainer key={`${name}-${label}`}>
          <FormControlLabel value={value} control={<BpRadio />} label={label} />
          {darkColor ? (
            <DarkStyledChip label={accountCurrency} size="small" />
          ) : (
            <StyledChip label={accountCurrency} size="small" />
          )}
        </RadioContainer>
      ))}
    </RadioGroup>
  );
};

RadioButtonGroup.defaultProps = {
  darkColor: false,
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  darkColor: PropTypes.bool,
  values: PropTypes.shape({
    inCompliance: PropTypes.string,
  }).isRequired,
  radioGroup: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
    }),
  ).isRequired,
};

export default RadioButtonGroup;
