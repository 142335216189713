/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { FlareSharp } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  CD: false,
  offerId: '',
  amount: '',
  currency: 'DOP',
  ratesTable: [],
  performanceTable: [],
  availableAccounts: [],
  interestRate: 0,
  interestType: 'CAP',
  terms: [2, 6, 12, 18, 24],
  cdSelectedTerm: 0,
  cdSelectedAccount: '',
  minInvestmentAmount: 10000,
  maxInvestmentAmount: 2000000,
  accountBalance: 0,
  availableAmount: 0,
  aproxPerformanceAmount: '',
  benefits: [],
  cdOpened: {
    date: '',
    file: '',
    message: '',
    monthlyProfitability: '',
    productNumber: '',
    productType: '',
    profitType: '',
    rate: '',
    status: '',
    term: '',
    totalProfitability: '',
    trnId: '',
    certificateProductNumber: '',
  },
};

const CertificateDepositOfferSlice = createSlice({
  name: 'CertificateDepositOfferSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setCD: (state, action) => {
      state.CD = action.payload;
    },
    setOfferId: (state, action) => {
      state.offerId = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setRateTable: (state, action) => {
      state.ratesTable = action.payload;
    },
    setAvailableAccounts: (state, action) => {
      state.availableAccounts = action.payload;
    },
    setInterestRate: (state, action) => {
      state.interestRate = action.payload;
    },
    setInterestType: (state, action) => {
      state.interestType = action.payload;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
    },
    setCDSelectedTerm: (state, action) => {
      state.cdSelectedTerm = action.payload;
    },
    setCDSelectedAccount: (state, action) => {
      state.cdSelectedAccount = action.payload;
    },
    setMinInvestmentAmount: (state, action) => {
      state.minInvestmentAmount = action.payload;
    },
    setMaxInvestmentAmount: (state, action) => {
      state.maxInvestmentAmount = action.payload;
    },
    setAccountBalance: (state, action) => {
      state.accountBalance = action.payload;
    },
    setAvailableAmount: (state, action) => {
      state.availableAmount = action.payload;
    },
    setBenefits: (state, action) => {
      state.benefits = action.payload;
    },
    setAproxPerformanceAmmount: (state, action) => {
      state.aproxPerformanceAmount = action.payload;
    },
    setCDOpened: (state, action) => {
      state.cdOpened = action.payload;
    },
    setPerformanceTable: (state, action) => {
      state.performanceTable = action.payload;
    },
  },
});

const { actions, reducer } = CertificateDepositOfferSlice;

export const {
  setCD,
  setOfferId,
  setAmount,
  setCurrency,
  setRateTable,
  setInterestRate,
  setAvailableAccounts,
  setInterestType,
  setTerms,
  setCDSelectedTerm,
  setCDSelectedAccount,
  setMinInvestmentAmount,
  setMaxInvestmentAmount,
  setAccountBalance,
  setAvailableAmount,
  setBenefits,
  setAproxPerformanceAmmount,
  setCDOpened,
  setPerformanceTable,
} = actions;

export default reducer;
