import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Cuenta = ({ color }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="General">
      <g id="Union">
        <path
          d="M15.7295 12.9418L15.7726 13.149L16.1645 13.1036C16.3602 13.0788 16.5373 13.2217 16.5622 13.4174C16.5839 13.6131 16.4441 13.7902 16.2484 13.815L15.2667 13.93H15.2574C15.2201 13.9362 15.1922 13.9517 15.1735 13.9828C15.1456 14.0201 15.1362 14.076 15.1487 14.1319C15.1766 14.2655 15.3009 14.368 15.422 14.3556L15.9905 14.2903C16.4814 14.2344 16.9412 14.5793 17.0468 15.0763C17.0996 15.331 17.0468 15.592 16.9008 15.7908C16.7774 15.9636 16.5983 16.0782 16.3935 16.122L16.4254 16.2752C16.4658 16.4678 16.3415 16.6573 16.1489 16.6977C16.1241 16.7039 16.0992 16.7039 16.0743 16.7039L16.0775 16.707C15.9128 16.707 15.7637 16.5921 15.7264 16.4212L15.6822 16.2085L15.158 16.2692H15.1176V16.2723C14.9374 16.2723 14.7821 16.1388 14.7635 15.9555C14.7417 15.7598 14.8815 15.5827 15.0772 15.5578L16.2298 15.4242C16.2701 15.418 16.3012 15.4025 16.3229 15.3714C16.3509 15.3342 16.3602 15.2782 16.3478 15.2223C16.3198 15.0887 16.1863 14.9862 16.0744 14.9986L15.5059 15.0639C15.0182 15.1198 14.5553 14.775 14.4497 14.2748C14.3969 14.0201 14.4497 13.7591 14.5957 13.5603C14.7108 13.3992 14.8743 13.2886 15.062 13.2389L15.0306 13.0878C14.9902 12.8952 15.1144 12.7057 15.3071 12.6653C15.4997 12.6249 15.6892 12.7492 15.7295 12.9418Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.764 11.2164L23.8263 16.0837V16.0867C24.0238 16.403 24.0547 16.7614 23.9096 17.0746C23.7645 17.3879 23.4682 17.6017 23.0946 17.665L22.9496 17.6891L23.3107 18.3939C23.5176 18.7071 23.5515 19.0715 23.4064 19.3848C23.2613 19.698 22.9588 19.9119 22.5791 19.9691L9.50571 22.1648C9.44397 22.1738 9.37914 22.1799 9.3174 22.1799C8.70309 22.1799 8.18448 21.7522 8.08261 21.1618L8.00852 12.6019C8.00543 12.5447 8.00543 12.4905 8.00543 12.4363C7.98382 12.2646 8.02704 12.0929 8.12891 11.9513C8.132 11.9468 8.13586 11.9431 8.13972 11.9393C8.14357 11.9355 8.14743 11.9318 8.15052 11.9273C8.15824 11.9152 8.16441 11.9032 8.17059 11.8911C8.17676 11.8791 8.18293 11.867 8.19065 11.855C8.38204 11.5809 8.69383 11.4062 9.04575 11.3761L19.2853 10.4936C19.3254 10.4875 19.3625 10.4875 19.4026 10.4875C19.9336 10.4875 20.4923 10.7857 20.764 11.2164ZM11.1202 19.6528C10.5491 19.6528 10.0151 19.2703 9.85454 18.7402V18.7372L9.4378 17.3728L9.45632 20.7642L21.8105 18.674L21.4771 17.93L11.3332 19.6348C11.2622 19.6468 11.1912 19.6528 11.1202 19.6528ZM9.5088 12.7284L11.1912 18.2523L22.3414 16.3789L19.5508 11.9453C19.5323 11.9212 19.4458 11.876 19.4057 11.876L9.5088 12.7284Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

Cuenta.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

Cuenta.propTypes = {
  color: PropTypes.string,
};

export default Cuenta;
