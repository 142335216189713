import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as Error1 } from 'Core/Utils/Assets/System-Error-1.svg';
import { ReactComponent as Error2 } from 'Core/Utils/Assets/System-Error-2.svg';
import { ReactComponent as Error3 } from 'Core/Utils/Assets/System-Error-3.svg';
import { ReactComponent as Error4 } from 'Core/Utils/Assets/System-down.svg';

const ErrorImage = ({ errorType }) => {
  let error;
  switch (errorType) {
  case 1:
    error = <Error1 />;
    break;
  case 2:
    error = <Error2 />;
    break;
  case 3:
    error = <Error3 />;
    break;
  case 4:
    error = <Error4 />;
    break;
  default:
    error = <Error1 />;
  }
  return error;
};

ErrorImage.defaultProps = {
  errorType: 1,
};

ErrorImage.propTypes = {
  errorType: PropTypes.number,
};

export default ErrorImage;
