import * as React from 'react';
import {
  StyledBParagraph as BottomParagraph,
  CustomDivider,
  StyledSubtitle as Subtitle,
} from './style';

const SheetInfo = () => (
  <>
    <Subtitle>
      ¿Por qué me retienen impuestos?
    </Subtitle>
    <CustomDivider data-testid="custom-divider" />
    <BottomParagraph>
      Cada vez que se acrediten intereses generados por tu Depósito a Plazo Digital
      a tu cuenta de ahorros o corriente, por ley se retendrá el 10% por concepto de impuestos.
    </BottomParagraph>
  </>
);

export default SheetInfo;
