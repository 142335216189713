/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useSelector } from 'react-redux';
import CFDBanner from 'Core/Utils/Assets/CFD_Banner.png';
import Header from 'Components/Header';
import { SpinnerText2 } from 'Constants/SpinnerText';
import { BodyContainer, ButtonContainer } from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';
import SellIconComp from 'Core/Utils/Assets/SellIconAux';
import DoneCircleIcon from 'Core/Utils/Assets/DoneCircle';
import HelpIcon from 'Core/Utils/Assets/CD/Help';
import LoadingScreen from 'Components/LoadingScreen';
import useTariff from 'Services/api/useCDTariff';
import useCDParameters from 'Services/api/useCDParameters';
import useMaximumProductAmount from 'Services/api/useCDMaximumProductAmount';
import useValidateToken from 'Services/api/useValidateToken';
import useGetAppStatus from 'Services/api/useGetAppStatus';
import useValidateUser from 'Services/api/useValidateUser';
import ListCheckIcon from 'Core/Utils/Assets/ListCheck';
import {
  TypographyBullets,
  BorderSeparatorProduct,
  OpenButton,
  CardProduct,
  CardSubtitle,
  CardSectionTitle,
  CardSubtitleDescription,
  CardSectionDescription,
  LiStyled,
  LuStyled,
  CheckIconContainer,
  BenefitsContainer,
  BenefitsTitle,
  BenefitsList,
  BenefitsListItem,
  BenefitsIcon,
  QAContainer,
  QADetails,
  ContainerImage,
  ContainerTitle,
  RequirementContainer,
  BenefitsText,
  RequerementIcon,
  RequirementTitle,
} from './style';

const DigitalCertificate = () => {
  const analytics = getAnalytics();
  const {
    token, p1, p2, p3,
  } = useParams();

  const { isValidToken, validateToken } = useValidateToken(token, p1, p2, p3);
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const { appStatus, getAppStatus } = useGetAppStatus();
  const { isValidUser, validateUser } = useValidateUser();
  const { getTariff, isValidTariff } = useTariff();
  const { getCDParameters, isValidCDParameters } = useCDParameters();
  const { getMaximumProductAmount, isValidMaximumProductAmount } = useMaximumProductAmount();
  const { document, documentType } = useSelector((state) => state.clientInfo);
  const [showCancelation, setShowCancelation] = useState(false);

  useEffect(() => {
    if (appStatus?.available) {
      validateToken();
    }
    getAppStatus();
  }, [appStatus]);

  useEffect(() => {
    if (isValidToken && document && documentType) {
      validateUser();
    }
  }, [isValidToken, document, documentType]);

  useEffect(() => {
    if (isValidUser) {
      getTariff();
      getCDParameters();
      getMaximumProductAmount();
    }
  }, [isValidUser]);

  return isValidTariff && isValidCDParameters && isValidMaximumProductAmount && !isloading ? (
    <>
      <Header title="Depósito a Plazo Digital" showBackButton={false} />
      <BodyContainer
        style={{
          paddingTop: '2.5rem',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CardProduct sx={{ maxWidth: 327, minWidth: 327 }}>
          <ContainerImage>
            <CardMedia
              component="img"
              height="auto"
              image={CFDBanner}
              alt="Depósito a Plazo Digital"
            />
          </ContainerImage>
          <CardContent
            sx={{
              padding: '0px',
            }}
          >
            <ContainerTitle>
              <ContainerImage>
                <CardSubtitle variant="h6">¡Saca el mayor provecho a tu dinero!</CardSubtitle>
                <CardSubtitleDescription>
                  Abre y gestiona tu Depósito a Plazo 100% Digital desde cualquier lugar.
                </CardSubtitleDescription>
              </ContainerImage>
            </ContainerTitle>
            <BenefitsContainer>
              <BenefitsTitle>
                <SellIconComp />
                Beneficios
              </BenefitsTitle>
              <BenefitsList>
                <BenefitsListItem>
                  <BenefitsIcon>
                    <CheckIconContainer />
                  </BenefitsIcon>
                  Tasa de interés anual competitiva
                </BenefitsListItem>
                {showCancelation && (
                  <BenefitsListItem>
                    <BenefitsIcon>
                      <CheckIconContainer />
                    </BenefitsIcon>
                    Renueva o cancela desde el App
                  </BenefitsListItem>
                )}
                <BenefitsListItem>
                  <BenefitsIcon>
                    <CheckIconContainer />
                  </BenefitsIcon>
                  Seguridad y garantía de inversión
                </BenefitsListItem>
              </BenefitsList>
            </BenefitsContainer>
            <RequirementContainer>
              <RequirementTitle>
                <ListCheckIcon />
                Requisito
              </RequirementTitle>
              <BenefitsList>
                <BenefitsListItem>
                  <RequerementIcon>
                    <CheckIconContainer />
                  </RequerementIcon>
                  <BenefitsText>
                    Tener una cuenta activa con un balance mínimo de DOP 10,000
                  </BenefitsText>
                </BenefitsListItem>
              </BenefitsList>
            </RequirementContainer>
            <CardSectionTitle>
              <HelpIcon />
              <CardSectionDescription>Preguntas frecuentes</CardSectionDescription>
            </CardSectionTitle>
          </CardContent>
        </CardProduct>
        <QAContainer>
          <QADetails>
            <summary>¿Qué es un Depósito a Plazo Digital? </summary>
            Es un instrumento de inversión a plazos, que te permite garantizar un crecimiento
            rentable de tu capital con un mayor rendimiento por un tiempo específico.
          </QADetails>
          <QADetails>
            <summary>¿Qué es la tasa de interés del Depósito a Plazo Digital?</summary>
            Es el rendimiento que recibes a modo de recompensa por invertir a un plazo definido en
            un Depósito a Plazo Digital.
          </QADetails>
          <QADetails>
            <summary>
              ¿Cada qué tiempo recibiré el interés que va a generar mi Depósito a Plazo Digital?
            </summary>
            Recibirás tus intereses de manera mensual y se acreditará según la modalidad que elijas:
            <ul>
              <li>
                <strong>Crédito a cuenta: </strong>
                El interés generado se acreditará mensualmente a tu cuenta de ahorros o corriente.
              </li>
              <li>
                <strong>Capitalizable: </strong>
                El interés generado se acreditará mensualmente al capital invertido, incrementando
                mes tras mes los intereses generados y se acreditará a tu cuenta de ahorros o
                corriente al vencimiento del plazo establecido.
              </li>
            </ul>
          </QADetails>
          <QADetails>
            <summary>
              ¿Cuál es el monto máximo que puedo invertir en un Depósito a Plazo Digital?
            </summary>
            El límite de inversión establecido para un Depósito a Plazo Digital es de DOP 2,000,000.
          </QADetails>
          <QADetails>
            <summary>¿Cuántos Depósitos a Plazo Digital puedo tener al mismo tiempo?</summary>
            Puedes tener varios Depósitos a Plazo Digital al mismo tiempo, siempre y cuando la
            sumatoria de todos no exceda el monto máximo establecido de DOP 2,000,000.
          </QADetails>
          {showCancelation && (
            <QADetails>
              <summary>
                ¿Puedo cancelar mi Depósito a Plazo Digital en cualquier momento desde el App?
              </summary>
              Sí, puedes cancelar el Depósito a Plazo Digital en cualquier momento desde el App
              Banreservas. Sin embargo, esto conlleva una penalización por cancelación anticipada si
              la inversión no ha llegado a su vencimiento.
            </QADetails>
          )}
          <QADetails>
            <summary>
              ¿Cuál sería la penalidad por cancelación anticipada de mi Depósito a Plazo Digital?
            </summary>
            Al cancelar tu Depósito a Plazo Digital antes de su vencimiento, se aplicará una
            penalización del 2.5% sobre los intereses pendientes.
          </QADetails>
        </QAContainer>
        <BorderSeparatorProduct />
      </BodyContainer>

      <ButtonContainer>
        <OpenButton
          variant="contained"
          onClick={() => {
            logEvent(analytics, 'Digital Certificate', {
              content_type: 'Button - Start the Certificate Process',
              content_id: 'Digital Certificate - Continuar',
            });
            navigate('/calculator');
          }}
          buttoncolor={palette.textAndLine.BlancoBanreservas000}
          disableElevation
        >
          CONTINUAR
        </OpenButton>
      </ButtonContainer>
    </>
  ) : (
    <LoadingScreen isOpen text={SpinnerText2} isWhite />
  );
};

export default DigitalCertificate;
