import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  setMaxInvestmentAmount,
  setMinInvestmentAmount,
} from 'Core/Store/Reducers/certificateDepositOfferSlice';
import { CD_PARAMETERS } from 'Constants/CDCalculator';
import endpoints from 'Core/Utils/ApiEndpoints';
import useGet from './useGet';

const { REACT_APP_ENVIRONMENT } = process.env;

const useCDParameters = () => {
  const [isValidCDParameters, setValidCDParameters] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, callApi } = useGet(
    endpoints.cDParameters,
    API_HEADERS(),
    REACT_APP_ENVIRONMENT === 'DEV',
    CD_PARAMETERS[200],
  );
  const isCalled = useRef(false);

  const getCDParameters = () => {
    if (isCalled.current === false) {
      isCalled.current = true;
      callApi();
      // appInsights.trackPageView('Get App Status API');
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setMinInvestmentAmount(data.minimumAmount));
      dispatch(setMaxInvestmentAmount(data.maximumCumulativeAmount));
      setValidCDParameters(true);
    } else if (data !== undefined) {
      navigate('/clientInfoError');
      setValidCDParameters(false);
    }
  }, [data]);

  return {
    isValidCDParameters,
    getCDParameters,
  };
};

export default useCDParameters;
