import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const InfoIcon = ({ color }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="info">
      <mask id="mask0_6749_3729" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect id="Bounding box" x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6749_3729)">
        <path
          id="info_2"
          d="M8.50016 11.3333C8.68905 11.3333 8.84738 11.2695 8.97516 11.1417C9.10294 11.0139 9.16683 10.8556 9.16683 10.6667V8.00001C9.16683 7.81112 9.10294 7.65279 8.97516 7.52501C8.84738 7.39723 8.68905 7.33334 8.50016 7.33334C8.31127 7.33334 8.15294 7.39723 8.02516 7.52501C7.89738 7.65279 7.8335 7.81112 7.8335 8.00001V10.6667C7.8335 10.8556 7.89738 11.0139 8.02516 11.1417C8.15294 11.2695 8.31127 11.3333 8.50016 11.3333ZM8.50016 6.00001C8.68905 6.00001 8.84738 5.93612 8.97516 5.80834C9.10294 5.68057 9.16683 5.52223 9.16683 5.33334C9.16683 5.14445 9.10294 4.98612 8.97516 4.85834C8.84738 4.73057 8.68905 4.66668 8.50016 4.66668C8.31127 4.66668 8.15294 4.73057 8.02516 4.85834C7.89738 4.98612 7.8335 5.14445 7.8335 5.33334C7.8335 5.52223 7.89738 5.68057 8.02516 5.80834C8.15294 5.93612 8.31127 6.00001 8.50016 6.00001ZM8.50016 14.6667C7.57794 14.6667 6.71127 14.4917 5.90016 14.1417C5.08905 13.7917 4.3835 13.3167 3.7835 12.7167C3.1835 12.1167 2.7085 11.4111 2.3585 10.6C2.0085 9.7889 1.8335 8.92223 1.8335 8.00001C1.8335 7.07779 2.0085 6.21112 2.3585 5.40001C2.7085 4.5889 3.1835 3.88334 3.7835 3.28334C4.3835 2.68334 5.08905 2.20834 5.90016 1.85834C6.71127 1.50834 7.57794 1.33334 8.50016 1.33334C9.42238 1.33334 10.2891 1.50834 11.1002 1.85834C11.9113 2.20834 12.6168 2.68334 13.2168 3.28334C13.8168 3.88334 14.2918 4.5889 14.6418 5.40001C14.9918 6.21112 15.1668 7.07779 15.1668 8.00001C15.1668 8.92223 14.9918 9.7889 14.6418 10.6C14.2918 11.4111 13.8168 12.1167 13.2168 12.7167C12.6168 13.3167 11.9113 13.7917 11.1002 14.1417C10.2891 14.4917 9.42238 14.6667 8.50016 14.6667ZM8.50016 13.3333C9.98905 13.3333 11.2502 12.8167 12.2835 11.7833C13.3168 10.75 13.8335 9.4889 13.8335 8.00001C13.8335 6.51112 13.3168 5.25001 12.2835 4.21668C11.2502 3.18334 9.98905 2.66668 8.50016 2.66668C7.01127 2.66668 5.75016 3.18334 4.71683 4.21668C3.6835 5.25001 3.16683 6.51112 3.16683 8.00001C3.16683 9.4889 3.6835 10.75 4.71683 11.7833C5.75016 12.8167 7.01127 13.3333 8.50016 13.3333Z"
          fill={color}
        />
      </g>
    </g>
  </svg>

);

InfoIcon.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

InfoIcon.propTypes = {
  color: PropTypes.string,
};

export default InfoIcon;
