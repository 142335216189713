import styled from 'styled-components';
import { palette } from 'Core/Styles/Theme';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
width: 100%;
min-height: 46px;
max-height: 70px;
background: ${palette.textAndLine.PlataBanreservas100};
position: relative;  
`;

export const Divider = styled.div`
height: 4px;
background: ${palette.brand.AzulBanreservas100B};
`;

export const Title = styled.h3`
text-align:center;
font-size: 16px;
line-height: 22px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
color: ${palette.brand.AzulBanreservas900};
margin: 0 auto;
padding-top: 0.75rem;
padding-bottom: 0.75rem;
max-width: 100%;
`;

export const ContentContainer = styled.div`
display: flex;
margin-right: 4rem;
margin-left: 0.6rem;
& button:focus {
    outline: unset;
}
`;
