/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  step: false,
  initialStep: '',
  currentStep: '',
};

const appRouterSlice = createSlice({
  name: 'appRouterSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetStep: (state) => INITIAL_STATE,
    setSteps: (state, action) => {
      state.step = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.step = false;
      state.currentStep = action.payload;
    },
    setInitialStep: (state, action) => {
      state.initialStep = action.payload;
    },
  },
});

const { actions, reducer } = appRouterSlice;

export const {
  resetStep,
  setSteps,
  setInitialStep,
  setCurrentStep,
} = actions;

export default reducer;
