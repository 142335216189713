import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useGet = (url, headers, isMock = false, mock = {}, customError = false) => {
  const [body, setBody] = useState(null);
  const [data, setData] = useState(undefined);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      if (isMock) {
        setData(mock);
      } else {
        const response = await fetch(url, {
          method: 'GET',
          headers,
        });
        if (!customError && response.status >= 400 && response.status < 500) {
          navigate('/clientInfoError');
        } else if (response.status >= 500 && response.status < 600) {
          if (url.includes('getTariff')) {
            navigate('/tarifError');
          } else {
            navigate('/fatalError');
          }
        }
        const dataResponse = await response.json();
        setData(dataResponse);
      }
    } catch {
      if (isMock) {
        setData(mock);
      } else {
        navigate('/clientInfoError');
      }
    }
  };

  const callApi = (payload = null) => {
    if (payload === body) {
      getData();
    } else {
      setBody(payload);
    }
  };

  useEffect(() => {
    if (body) {
      getData();
    }
  }, [body]);

  return {
    callApi,
    data,
  };
};

export default useGet;
