import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Plazo = ({ color }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="General">
      <path
        id="Vector"
        d="M18.6364 22.033V20.5785H21.5455V13.3057L10.9091 13.3058V16.2149H9.45455V10.3967C9.45455 9.99673 9.59697 9.65431 9.88182 9.36946C10.1667 9.08461 10.5091 8.94219 10.9091 8.94219H11.6364V7.48764H13.0909V8.94219L19.3636 8.94209V7.48755H20.8182V8.94209H21.5455C21.9455 8.94209 22.2879 9.08452 22.5727 9.36937C22.8576 9.65422 23 9.99664 23 10.3966V20.5785C23 20.9785 22.8576 21.3209 22.5727 21.6057C22.2879 21.8906 21.9455 22.033 21.5455 22.033H18.6364ZM13.0909 23.4876L12.0727 22.4695L13.9455 20.5786H8V19.124H13.9455L12.0727 17.2331L13.0909 16.2149L16.7273 19.8513L13.0909 23.4876ZM10.9091 11.8513L21.5455 11.8512V10.3966L10.9091 10.3967V11.8513Z"
        fill={color}
      />
    </g>
  </svg>
);

Plazo.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

Plazo.propTypes = {
  color: PropTypes.string,
};

export default Plazo;
