/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { BodysubTitle } from 'Core/Styles/CommonStyles';
import { palette } from '../../Core/Styles/Theme';

const LoadingScreen = ({
  text,
  isOpen,
  isWhite,
  isIconWhite,
}) => (

  <Dialog
    open={isOpen}
    BackdropProps={{ style: isWhite ? { backgroundColor: 'rgba(255, 255, 255, 0.8)' } : { backgroundColor: 'rgba(38, 78, 114, 0.8)' } }}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderStyle: 'none',
        overflowY: 'unset',
      },
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <CircularProgress data-testid="circular-progress" disableShrink size="3rem" style={{ color: isIconWhite ? palette.textAndLine.BlancoBanreservas000 : palette.brand.AzulBanreservas100B }} />
    </div>
    <div style={{ textAlign: 'center' }}>
      <BodysubTitle data-testid="info-text" padding="0.8rem 4.5rem 0 4.5rem" style={{ color: isWhite ? palette.textAndLine.PlataBanreservas700B : palette.textAndLine.BlancoBanreservas000 }}>
        {text}
      </BodysubTitle>
    </div>

  </Dialog>

);

LoadingScreen.defaultProps = {
  text: '',
  isOpen: false,
  isWhite: false,
  isIconWhite: false,
};

LoadingScreen.propTypes = {
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  isWhite: PropTypes.bool,
  isIconWhite: PropTypes.bool,
};

export default LoadingScreen;
