/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  document: '',
  documentType: '',
  name: '',
  firstDepositCard: '',
  workActivity: {
    type: '',
    activity: '',
  },
  phoneNumber: '',
  password: '',
  address: {
    provincia: '',
    ciudad: '',
    sector: '',
    calle: '',
    numero: '',
    type: '',
  },
  companyData: {
    startDate: '',
    company: '',
  },
  financialData: {
    earnings: '',
    amount: '',
  },
  clientData: {
    email: '',
    landline: '',
  },
  depositCertificateData: {
    ammount: '',
    term: '',
    rateType: '',
  },
  accountNumber: '',
  depositAmount: '',
  terminal: '',
  user: '',
  authorization: '',
  hasAccount: true,
  hasAContract: false,
  contractDate: '',
};

const clientInfoSlice = createSlice({
  name: 'clientInfoSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetState: (state) => INITIAL_STATE,
    resetCompanyData: (state) => {
      state.companyData = {
        startDate: '',
        company: '',
      };
    },
    resetAddress: (state) => {
      state.address = {
        provincia: '',
        ciudad: '',
        sector: '',
        calle: '',
        numero: '',
        type: '',
      };
    },
    resetDepositCertificateData: (state) => {
      state.depositCertificateData = {
        ammount: '',
        term: '',
        rateType: '',
      };
    },
    setDocument: (state, action) => {
      state.document = action.payload;
    },
    setDocumentType: (state, action) => {
      state.documentType = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setWorkActivity: (state, action) => {
      state.workActivity = action.payload;
    },
    setCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
    setFinancialData: (state, action) => {
      state.financialData = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setClientData: (state, action) => {
      state.clientData = action.payload;
    },
    setDepositCertificateData: (state, action) => {
      state.depositCertificateData = action.payload;
    },
    setAccountNumber: (state, action) => {
      state.accountNumber = action.payload;
    },
    setFirstDepositCard: (state, action) => {
      state.firstDepositCard = action.payload;
    },
    setdepositAmount: (state, action) => {
      state.depositAmount = action.payload;
    },
    setTerminal: (state, action) => {
      state.terminal = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthorization: (state, action) => {
      state.authorization = action.payload;
    },
    sethasAccount: (state, action) => {
      state.hasAccount = action.payload;
    },
    sethasAContract: (state, action) => {
      state.hasAContract = action.payload;
    },
    setContractDate: (state, action) => {
      state.contractDate = action.payload;
    },
  },
});

const { actions, reducer } = clientInfoSlice;

export const {
  resetState,
  setDocument,
  setDocumentType,
  setName,
  setAddress,
  setWorkActivity,
  setCompanyData,
  setFinancialData,
  setPhoneNumber,
  setPassword,
  setClientData,
  resetAddress,
  resetCompanyData,
  setAccountNumber,
  setFirstDepositCard,
  setdepositAmount,
  setTerminal,
  setUser,
  setAuthorization,
  setDepositCertificateData,
  resetDepositCertificateData,
  sethasAccount,
  sethasAContract,
  setContractDate,
} = actions;

export default reducer;
