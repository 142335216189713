/* eslint-disable quote-props */
export const DIGITAL_ACCOUNT_SURVEY_PAYLOAD = {
  documentType: 'Cedula', // "Cedula" | "Pasaporte"
  documentNumber: '00200746089',
  experience: 'Positive', // "Positive" | "Neutral" | "Negative"
  comment: 'all good', // Para "Negative" expirience Required
  productType: 'Cuenta ahorro digital',
  process: 'DigitalOnboarding', // "DigitalOnboarding" | "DigitalSell"
};

export const DIGITAL_ACCOUNT_SURVEY_RESPONSE = {
  '200': {
    trnId: null,
    date: '2023-10-13 T 15:54:57',
    status: true,
    message: 'Satisfactorio',
  },
  '400': {
    description: 'Business error',
  },
  '500': {
    description: 'Server error',
  },
};
