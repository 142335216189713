const INITIAL_RATING = [
  {
    id: 1,
    ratingLabel: 'Mala',
    ratingValue: 'Negative',
    isSelected: false,
    emoji: '☹️',
  },
  {
    id: 2,
    ratingLabel: 'Regular',
    ratingValue: 'Neutral',
    isSelected: false,
    emoji: '😐',
  },
  {
    id: 3,
    ratingLabel: 'Buena',
    ratingValue: 'Positive',
    isSelected: false,
    emoji: '😁',
  },
];

export default INITIAL_RATING;
