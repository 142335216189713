/* eslint-disable import/prefer-default-export */
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import { palette } from 'Core/Styles/Theme';

export const StyledCheckbox = styled(Checkbox)`
	color: ${palette.brand.AzulBanreservas100B};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.375rem;
	color: ${palette.brand.AzulBanreservas700B};
`;
