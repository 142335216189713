const errorDictionary = [
  {
    id: 1,
    epic: '102',
    errorCode: '101',
    fullErrorCode: '02102101',
    errorRoute: '/clientHasAccount',
    fullMessage: '02102101|El cliente ya poseé una cuenta de ahorro digital',
  },
  {
    id: 2,
    epic: '102',
    errorCode: '102',
    fullErrorCode: '02102102',
    errorRoute: '/insufficientBalance',
    fullMessage: '02102102|Balance insuficiente',
  },
  {
    id: 3,
    epic: '102',
    errorCode: '103',
    fullErrorCode: '02102103',
    errorRoute: '/clientNeedsAccount',
    fullMessage: '02102103|El cliente no cuenta con una cuenta de ahorro banreservas para iniciar el proceso',
  },
  {
    id: 4,
    epic: '102',
    errorCode: '104',
    fullErrorCode: '02102104',
    errorRoute: '/clientInfoErrorCuenta',
    fullMessage: '02102104|No supera la validación de CRM',
  },
  {
    id: 5,
    epic: '102',
    errorCode: '105',
    fullErrorCode: '02102105',
    errorRoute: '/clientInfoErrorCuenta',
    fullMessage: '02102105|No supera la validación de FATCA',
  },
  {
    id: 6,
    epic: '102',
    errorCode: '106',
    fullErrorCode: '02102106',
    errorRoute: '/clientInfoErrorCuenta',
    fullMessage: '02102106|La calificación de riesgo actual del cliente está por debajo de lo permitido',
  },
  {
    id: 7,
    epic: '105',
    errorCode: '107',
    fullErrorCode: '02105107',
    errorRoute: '/fatalErrorRetryLater',
    fullMessage: '02102107|Tuvimos un inconveniente al consultar las cuentas activas. Por favor reintente después de 1 hora.',
  },
  {
    id: 8,
    epic: '105',
    errorCode: '108',
    fullErrorCode: '02105108',
    errorRoute: '/fatalErrorRetryLater',
    fullMessage: '02102108|Tuvimos un inconveniente al consultar las cuentas activas. Por favor reintente después de 1 hora.',
  },
  {
    id: 9,
    epic: '105',
    errorCode: '109',
    fullErrorCode: '02105109',
    errorRoute: '/insufficientBalance',
    fullMessage: '02102109|Balance insuficiente',
  },
  {
    id: 10,
    epic: '105',
    errorCode: '110',
    fullErrorCode: '02105110',
    errorRoute: '/insufficientBalanceDolars',
    fullMessage: '02102110|Balance insuficiente',
  },
  {
    id: 11,
    epic: '105',
    errorCode: '111',
    fullErrorCode: '02105111',
    errorRoute: '/insufficientBalanceEuros',
    fullMessage: '02102111|Balance insuficiente',
  },
  {
    id: 12,
    epic: '105',
    errorCode: '112',
    fullErrorCode: '02105112',
    errorRoute: '/fatalErrorCuenta',
    fullMessage: '02102112|Tuvimos un inconveniente a crear tu cuenta. Por favor dirigite a una oficina.',
  },
  {
    id: 13,
    epic: '107',
    errorCode: '113',
    fullErrorCode: '02107113',
    errorRoute: '/fatalErrorRetryLater',
    fullMessage: '02107113|Tuvimos un inconveniente. Favor intentalo mas tarde.',
  },
  {
    id: 14,
    epic: '108',
    errorCode: '114',
    fullErrorCode: '02108114',
    errorRoute: '/fatalErrorRetryLater',
    fullMessage: '02107113|Tuvimos un inconveniente. Favor intentalo mas tarde.',
  },
  {
    id: 15,
    epic: '103',
    errorCode: '115',
    fullErrorCode: '02103115',
    errorRoute: '/fatalErrorRetryLater',
    fullMessage: '02107113|Tuvimos un inconveniente. Favor intentalo mas tarde.',
  },
  {
    id: 16,
    epic: '103',
    errorCode: '116',
    fullErrorCode: '02103116',
    errorRoute: '/fatalErrorCuenta',
    fullMessage: '02107113|Tuvimos un inconveniente. Favor intentalo mas tarde.',
  },
  {
    id: 17,
    epic: '104',
    errorCode: '117',
    fullErrorCode: '02104117',
    errorRoute: '/fatalErrorRetryLater',
    fullMessage: '02107113|Tuvimos un inconveniente. Favor intentalo mas tarde.',
  },
  {
    id: 18,
    epic: '102',
    errorCode: '004',
    fullErrorCode: '02102004',
    errorRoute: '/clientInfoErrorCuenta',
    fullMessage: '02102004|Cliente es invalido - Listas Negras',
  },
  {
    id: 19,
    epic: '135',
    errorCode: '106',
    fullErrorCode: '04135106',
    errorRoute: '/clientInfoErrorCuenta',
    fullMessage: '04135106|Cliente con nivel de riesgo alto',
  },
];

export default errorDictionary;
