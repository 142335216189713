/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useSaveAccount from 'Services/api/useSaveAccount';
import AccountNumberCard from 'Components/AccountNumberCard';
import { SpinnerText2 } from 'Constants/SpinnerText';
import LoadingScreen from 'Components/LoadingScreen';
import { BodyContent } from 'Core/Styles/CommonStyles';
import SuccessPage from 'Core/Utils/Assets/SuccessPage';
import { setAccountNumber } from 'Core/Store/Reducers/clientInfoSlice';
import NPSModal from 'Components/NPSModal';
import {
  StyledSubtitle, StyledBodyContainer, StyledDate,
} from './style';

const {
  REACT_APP_SHOW_NPS,
} = process.env;

const OpeningConfirmation = ({ fixedDate }) => {
  const analytics = getAnalytics();
  const { saveData, SaveAccount } = useSaveAccount();
  const [isloading, setIsloading] = useState(true);
  const dispatch = useDispatch();
  const [date, setDate] = useState('');
  const [data, setData] = useState({
    transactionNumber: '',
    openingAmount: '',
  });
  // eslint-disable-next-line no-unused-vars
  const handlerContinue = () => {
    SaveAccount();
  };

  useEffect(() => {
    handlerContinue();
  }, []);

  useEffect(() => {
    console.log(saveData, 'DATA');
    if (saveData?.accountNumber) {
      setData(saveData);
      dispatch(setAccountNumber(saveData.accountNumber));
      setDate(fixedDate ? moment(fixedDate).format('DD MMMM YYYY - h:mm A') : moment().format('DD MMMM YYYY - h:mm A'));
      setIsloading(false);
      logEvent(analytics, 'Opening Confirmation Data', {
        content_type: 'Opening Confirmation Screen - Complete Flow',
        content_id: 'Opening Confirmation Data Screen - The Onboarding has been completed',
      });
    }
  }, [saveData]);

  return (
    <StyledBodyContainer>
      {!isloading ? (
        <BodyContent>
          { REACT_APP_SHOW_NPS === 'true' ? <NPSModal /> : null }
          <SuccessPage />
          <StyledSubtitle>
            <strong>¡Ya tienes tu Cuenta Digital!</strong>
            <br />
            <StyledDate>
              {date}
            </StyledDate>
          </StyledSubtitle>
          <AccountNumberCard
            accountNumber={data?.accountNumber || ''}
            transactionNumber={data?.clientTRNid || ''}
            openingAmount={`DOP ${data?.amount}` || ''}
          />
        </BodyContent>
      ) : <LoadingScreen isOpen={isloading} text={SpinnerText2} isWhite />}
    </StyledBodyContainer>
  );
};

OpeningConfirmation.defaultProps = {
  fixedDate: null,
};

OpeningConfirmation.propTypes = {
  fixedDate: PropTypes.string,
};

export default OpeningConfirmation;
