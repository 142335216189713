import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import { DIGITAL_ACCOUNT_SURVEY_PAYLOAD } from 'Constants/DigitalAccountSurvey';
import endpoints from 'Core/Utils/ApiEndpoints';
import usePost from './usePost';

const useDigitalAccountSurvey = () => {
  const clientData = useSelector((state) => state.clientInfo);
  const [payload, setPayload] = useState();
  const { data, callApi } = usePost(
    endpoints.digitalAccountSurvey,
    API_HEADERS(),
  );

  const sendDigitalAccountSurvey = (rating, comment = null) => {
    const surveyPayload = {
      ...DIGITAL_ACCOUNT_SURVEY_PAYLOAD,
      documentType: clientData.documentType,
      documentNumber: clientData.document.replaceAll('-', ''),
      experience: rating,
      comment,
      productType: 'Cuenta ahorro digital',
      process: 'DigitalSell',
    };
    setPayload(surveyPayload);
  };

  useEffect(() => {
    if (payload?.documentNumber !== '') {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data?.status) {
      // console.log('data:', data);
    } else if (data !== undefined) {
      // console.log('Error:', data);
    }
  }, [data]);

  return sendDigitalAccountSurvey;
};

export default useDigitalAccountSurvey;
