import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import LegalProvision from 'Core/Utils/Assets/LegalProvision';
import { palette } from 'Core/Styles/Theme';
import {
  Container,
  PageTitle,
} from './style';

const LEGAL_PROVISION_TEXT = 'La disposición legal de la apertura de este producto, se encuentra establecida en el contrato de servicios bancarios firmado en fecha del ';

const LegalProvisionComponent = ({ date }) => (
  <Container>
    <LegalProvision />
    <PageTitle> Disposición Legal</PageTitle>
    <Typography sx={{
      mt: 2, textAlign: 'center', whiteSpace: 'pre-line', paddingRight: '8px', color: palette.textAndLine.PlataBanreservas700B,
    }}
    >

      {LEGAL_PROVISION_TEXT}
      <b>
        {date}
      </b>

    </Typography>
  </Container>
);

LegalProvisionComponent.propTypes = {
  date: PropTypes.string.isRequired,

};

export default LegalProvisionComponent;
