import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const ListCheckIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.05891 5.19322C9.36826 5.47132 9.39325 5.94315 9.11516 6.25249L6.86538 8.75225C6.72789 8.90536 6.53416 8.99598 6.32793 8.9991C6.1217 9.00222 5.92484 8.92411 5.77798 8.78037L4.52498 7.53049C4.23126 7.23677 4.23126 6.76182 4.52498 6.47122C4.8187 6.18062 5.29365 6.1775 5.58425 6.47122L6.27481 7.16178L7.99651 5.24947C8.27461 4.94012 8.74644 4.91512 9.05579 5.19322H9.05891ZM9.05891 10.1927C9.36826 10.4708 9.39325 10.9427 9.11516 11.252L6.86538 13.7518C6.72789 13.9049 6.53416 13.9955 6.32793 13.9986C6.1217 14.0017 5.92484 13.9236 5.77798 13.7799L4.52498 12.53C4.23126 12.2363 4.23126 11.7613 4.52498 11.4707C4.8187 11.1801 5.29365 11.177 5.58425 11.4707L6.27481 12.1613L7.99651 10.249C8.27461 9.93963 8.74644 9.91464 9.05579 10.1927H9.05891ZM11.3056 6.99929C11.3056 6.44622 11.7524 5.99939 12.3055 5.99939H19.3048C19.8579 5.99939 20.3047 6.44622 20.3047 6.99929C20.3047 7.55237 19.8579 7.9992 19.3048 7.9992H12.3055C11.7524 7.9992 11.3056 7.55237 11.3056 6.99929ZM11.3056 11.9988C11.3056 11.4457 11.7524 10.9989 12.3055 10.9989H19.3048C19.8579 10.9989 20.3047 11.4457 20.3047 11.9988C20.3047 12.5519 19.8579 12.9987 19.3048 12.9987H12.3055C11.7524 12.9987 11.3056 12.5519 11.3056 11.9988ZM9.30576 16.9983C9.30576 16.4452 9.75259 15.9984 10.3057 15.9984H19.3048C19.8579 15.9984 20.3047 16.4452 20.3047 16.9983C20.3047 17.5514 19.8579 17.9982 19.3048 17.9982H10.3057C9.75259 17.9982 9.30576 17.5514 9.30576 16.9983ZM5.8061 18.4982C4.97806 18.4982 4.30625 17.8264 4.30625 16.9983C4.30625 16.1703 4.97806 15.4985 5.8061 15.4985C6.63415 15.4985 7.30596 16.1703 7.30596 16.9983C7.30596 17.8264 6.63415 18.4982 5.8061 18.4982Z"
      fill={color}
    />
  </svg>
);

ListCheckIcon.defaultProps = {
  color: palette.brand.AzulBanreservas100B,
};

ListCheckIcon.propTypes = {
  color: PropTypes.string,
};
export default ListCheckIcon;
