import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Student = ({ color }) => (
  <svg width="36" height="21" viewBox="5 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.84682 12.12C8.92653 12.12 8.99959 12.1067 9.066 12.08C9.13242 12.0533 9.19884 12.0067 9.26525 11.94L11.6364 9.56C11.7825 9.41333 11.8888 9.25 11.9552 9.07C12.0216 8.89 12.0548 8.7 12.0548 8.5C12.0548 8.07333 11.9054 7.71 11.6065 7.41C11.3076 7.11 10.9456 6.96 10.5205 6.96C10.2549 6.96 9.98921 7.04667 9.72354 7.22C9.45787 7.39333 9.16563 7.67333 8.84682 8.06C8.52802 7.67333 8.23578 7.39333 7.97011 7.22C7.70444 7.04667 7.43877 6.96 7.1731 6.96C6.74803 6.96 6.38605 7.11 6.08717 7.41C5.78829 7.71 5.63885 8.07333 5.63885 8.5C5.63885 8.7 5.67206 8.89 5.73848 9.07C5.8049 9.25 5.91117 9.41333 6.05729 9.56L8.42839 11.94C8.49481 12.0067 8.56123 12.0533 8.62765 12.08C8.69406 12.1067 8.76712 12.12 8.84682 12.12ZM8.68742 16C8.52802 16 8.37526 15.9733 8.22914 15.92C8.08302 15.8667 7.94354 15.78 7.81071 15.66L0.33873 8.16C0.219178 8.02667 0.132835 7.88667 0.079701 7.74C0.026567 7.59333 0 7.44 0 7.28V1.2C0 0.853333 0.11291 0.566667 0.33873 0.34C0.56455 0.113333 0.850145 0 1.19552 0H7.2528C7.4122 0 7.57161 0.0233334 7.73101 0.0700001C7.89041 0.116667 8.03653 0.206667 8.16936 0.34L15.6015 7.8C15.8672 8.06667 16 8.36333 16 8.69C16 9.01667 15.8672 9.31333 15.6015 9.58L9.54421 15.66C9.43794 15.7667 9.30843 15.85 9.15567 15.91C9.00291 15.97 8.84682 16 8.68742 16ZM8.68742 14.8L14.7447 8.72L7.2528 1.2H1.19552V7.28L8.68742 14.8ZM3.28767 4.32C3.56663 4.32 3.80905 4.21667 4.01494 4.01C4.22084 3.80333 4.32379 3.56 4.32379 3.28C4.32379 3 4.22084 2.75667 4.01494 2.55C3.80905 2.34333 3.56663 2.24 3.28767 2.24C3.00872 2.24 2.76629 2.34333 2.5604 2.55C2.3545 2.75667 2.25156 3 2.25156 3.28C2.25156 3.56 2.3545 3.80333 2.5604 4.01C2.76629 4.21667 3.00872 4.32 3.28767 4.32Z"
      fill={color}
    />
  </svg>
);

Student.defaultProps = {
  color: palette.brand.AzulBanreservas100B,
};

Student.propTypes = {
  color: PropTypes.string,
};

export default Student;
