import * as React from 'react';
import PropTypes from 'prop-types';
import {
  StyledBox as Box, StyledDrawer as Drawer, CloseButton,
} from './style';

// Existing imports...

const BaseDrawer = ({ children, toggleDrawer, isOpen }) => {
  const openFrom = 'bottom';

  return (
    <React.Fragment key={openFrom}>
      <Drawer
        anchor={openFrom}
        open={isOpen}
        onClose={() => toggleDrawer(false)} // Corrected onClose handler
      >
        <Box
          style={{ BorderTop: '20px' }}
          sx={{ width: 'auto', height: '254px' }}
          role="presentation"
        >
          <CloseButton data-testid="close-button" onClick={() => toggleDrawer(false)} />
          {children}
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

BaseDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default BaseDrawer;
