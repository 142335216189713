/* eslint-disable quote-props */
export const SELECT_CARD_DATA_PAYLOAD = {
  documentType: 'CEDULA',
  documentNumber: '',
  accountId: '',
};

export const SELECT_ACCOUNT_DATA_PAYLOAD = {
  documentType: 'CEDULA',
  documentNumber: '',
  accountId: '',
  process: 'DigitalCertificate',
};

export const SELECT_CARD_DATA_RESPONSE = {
  200: {
    status: true,
  },
  400: {
    description: 'Business error',
  },
  500: {
    description: 'Server error',
  },
};
