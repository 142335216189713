/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import Sell from '@mui/icons-material/SellOutlined';
import { ContinueButton, Paragraph, Subtitle } from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';

export const CardProduct = styled(Card)`
  &.MuiPaper-root {
    border-radius: 20px;
    border-top: 0;
    background-color: ${palette.textAndLine.PlataBanreservas100};
    margin-left: auto;
    margin-right: auto;
  }

  & div.MuiCardActions-root {
    padding-top: 1rem;
  }
`;

export const PageSubtitle = styled(Paragraph)`
  &.MuiTypography-root {
    margin-bottom: 2.5rem;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${palette.brand.AzulBanreservas900};
  }
`;

export const SellIconContainer = styled(Sell)`
  margin-bottom: -8px;
  margin-right: 10px;
  color: ${palette.brand.AzulBanreservas300};
`;

export const OpenNewWindow = styled(OpenInNewIcon)`
  color: ${palette.semantic.InfoBanreservas700};
  padding-left: 5px;
  padding-bottom: 0px;
`;

export const TypographyBullets = styled(Typography)`
  line-height: 26px !important;
  font-size: 14px;
`;

export const AcceptButton = styled(Button)`
  text-underline-offset: auto;
  &.MuiButtonBase-root {
    text-transform: inherit;
    text-decoration: underline 1px !important;
    text-underline-offset: auto;
    font-size: 16px;
    color: ${palette.semantic.InfoBanreservas700};
  }
`;

export const OpenButton = styled(ContinueButton)`
  &.MuiButton-containedPrimary {
    margin-top: 1rem;
  }
  box-shadow: none;
`;

export const CardSubtitle = styled(Subtitle)`
  &.MuiTypography-root {
    margin: 1rem 0 1rem 0.8rem;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CardActionsContainer = styled(CardActions)`
  flex-direction: row-reverse;
  margin-top: -1rem;
  button:focus {
    outline: 0px dotted;
  }
`;

export const BorderSeparatorProduct = styled.div`
  width: 100%;
  border-bottom: 1px solid ${palette.textAndLine.PlataBanreservas300};
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const Disclaimer = styled.div`
  color: ${palette.textAndLine.PlataBanreservas700B};
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
