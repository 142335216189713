/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { palette } from 'Core/Styles/Theme';
import RatingButtons from 'Components/RatingButtons';
import OptionalComment from 'Components/Forms/OptionalComment';
import useDigitalAccountSurvey from 'Services/api/useDigitalAccountSurvey';
import {
  StyledBox, FooterSection, StyledNPSButton, ActionButtons, StyledHr,
} from './style';

const NPSModal = () => {
  const analytics = getAnalytics();
  const [isOpen, setIsOpen] = useState(true);
  const [selectedRating, setSelectedRating] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [disableButton, setDisableButton] = useState(true);
  const [comment, setComment] = useState('');
  const onClose = () => setIsOpen(false);
  const sendDigitalAccountSurvey = useDigitalAccountSurvey();

  const commentRef = useRef();

  const handleRateClick = () => {
    if (selectedRating.id === 1) {
      setDisableButton(true);
      setCurrentStep(2);
    } else {
      sendDigitalAccountSurvey(selectedRating.ratingValue);
      onClose();
    }
    logEvent(analytics, 'NPS_Calificacion', {
      content_type: `NPS_Calificacion_${selectedRating.ratingValue}`,
      content_id: 'Account Open Success Screen - NPS Calificacion',
    });
  };

  const handleSendRating = () => {
    sendDigitalAccountSurvey(selectedRating.ratingValue, comment);
    logEvent(analytics, 'NPS_Comentarios', {
      content_type: 'NPS_Enviar_Comentarios',
      content_id: 'Account Open Success Screen - NPS Calificacion',
    });
    onClose();
  };

  useEffect(() => {
    if (currentStep === 2) {
      const ref = commentRef.current;
      const validationRegex = /^[A-Za-z0-9áéíóúäëïöüÁÉÍÓÚÄËÏÖÜ.,\s]*$/;

      if (validationRegex.test(ref?.values.comment)) {
        if (ref?.dirty) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      } else {
        setDisableButton(true);
      }
    } else if (selectedRating) {
      setDisableButton(false);
    }
  }, [selectedRating, comment]);

  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      data-testid="modal-section"
      onClose={onClose}
      open={isOpen}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: palette.brand.AzulBanreservas700B,
            opacity: '0.8 !important',
          },
        },
      }}
    >
      <StyledBox>
        { currentStep === 1 ? (<RatingButtons setSelectedRating={setSelectedRating} />)
          : (<OptionalComment commentRef={commentRef} setComment={setComment} />)}
        <FooterSection>
          <StyledHr />
          <ActionButtons>
            <StyledNPSButton
              data-testid="close-button"
              variant="text"
              onClick={() => {
                logEvent(analytics, 'NPS_NoCalificacion', {
                  content_type: 'NPS_Boton_Cerrar',
                  content_id: 'Account Open Success Screen - NPS Calificacion',
                });
                onClose();
              }}
            >
              Cerrar
            </StyledNPSButton>
            {currentStep === 1 ? (
              <StyledNPSButton
                data-testid="rate-button"
                variant="contained"
                onClick={handleRateClick}
                disabled={disableButton}
                disableElevation
              >
                Calificar
              </StyledNPSButton>
            )
              : (
                <StyledNPSButton
                  data-testid="send-button"
                  variant="contained"
                  onClick={handleSendRating}
                  disabled={disableButton}
                  disableElevation
                >
                  Enviar
                </StyledNPSButton>
              ) }

          </ActionButtons>
        </FooterSection>
      </StyledBox>
    </Modal>
  );
};

export default NPSModal;
