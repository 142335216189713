/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  BorderSeparator,
  StyledLink,
  BoxBodyContainer,
  BodysubTitle,
} from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';

export const StyledBorderSeparator = styled(BorderSeparator)`
      width: 100%;
      border-bottom: 2px solid ${palette.textAndLine.BlancoBanreservas000};
      margin: 1rem 0 1rem 0;
  `;

export const StyledSeparator = styled.div`
  border: 2px solid ${palette.semantic.InfoBanreservas100};
`;

export const StyledBoxBodyContainer = styled(BoxBodyContainer)`
  border-radius: 8px;
  ${({ hasSquaredBorders }) => hasSquaredBorders && `
    border-radius: 8px 8px 0 0;
  `}
`;

export const Description = styled(BodysubTitle)`
 padding-top: 4px
`;

export const TransactionContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  border: 2px solid ${palette.semantic.InfoBanreservas100};
  border-radius: 0px 0px 8px 8px;
`;

export const TransactionItem = styled.div`
  width: 49%;
  display: flex;
  align-items: center;
  color: ${palette.brand.AzulBanreservas100B};
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  flex-direction: column;
`;

export const BigStyledLink = styled(StyledLink)`
      color: ${palette.brand.AzulBanreservas100B} !important;
      font-size: 1.6rem;
      text-decoration-line: none !important;

      button:focus {
        outline: 0px;
    }
`;

export const CustomStyledLink = styled(StyledLink)`
      color: ${palette.brand.AzulBanreservas100B} !important;
      text-decoration: underline !important;
      text-decoration-color: rgba(0,174,240,0.4) !important;
  `;
