import {
  RadioGroup, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import styled from 'styled-components';
import { ContinueButton } from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';

export const BodyContainer = styled.div`
  padding: 1.5rem 1.5rem 0;
  display: flex;
  min-height: calc(100vh - 8.5rem);
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.textAndLine.PlataBanreservas100};
  padding: 16px;
  background: ${palette.semantic.InfoBanreservas100};
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  position: relative;
`;

export const InfoIconContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 14px; 
`;

export const SummaryTitle = styled.div`
  flex: 1 0 0;
  color: ${palette.semantic.InfoBanreservas700};
  /* Display/SM/Bold */
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 4px;
`;

export const SummaryDescription = styled.div`
  align-self: stretch;
  color: ${palette.semantic.InfoBanreservas700};
  /* Texto/SM/Regular Auto */
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-right: 26px;
`;

export const SummaryAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: 8px;

  & span {
    align-self: stretch;
    color: ${palette.brand.AzulBanreservas100B};
    /* Display/MD/Bold */
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  & span:first-of-type {
    display: flex;
    width: 52px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const DepositContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

export const DepositTitle = styled.div`
  color: ${palette.brand.AzulBanreservas900};
  /* Texto/MD/Bold Auto */
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

export const HelperText = styled(Typography)`
    font-size: 12px;
    line-height: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    color: ${(props) => props.color || palette.textAndLine.PlataBanreservas700B};
    padding: 0;
    margin-bottom: 8px;
`;

export const DepositInput = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  padding: 12px 0 14px 16px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  border: 1px solid var(--text-line-plata-banreserva-300, #cacccd);
`;

export const SectionTitle = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${palette.brand.AzulBanreservas700B};
  margin-bottom: 0.4rem;
  margin-top: 24px;
`;

export const AmountContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${palette.textAndLine.BlancoBanreservas000};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  color: ${palette.brand.AzulBanreservas100B};
  position: relative;

  & > div {
    display: flex;
    align-items: center;
  }

  & .MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 0px;
    max-width: none;
  }

  & .MuiSvgIcon-root {
    position: absolute;
    right: 5px;
    bottom: 0px;
  }

  & fieldset {
    border: none;
  }
  & input {
    text-align: left !important;
    padding: 0;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${palette.textAndLine.PlataBanreservas700B} !important;
    margin-left: 8px;
  }
  & span {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${palette.textAndLine.PlataBanreservas700B} !important;
  }
  & p {
    position: absolute;
    margin: 0;
    top: 42px;
    left: -45px;
    line-height: 1;
    text-align: left !important;
  }
`;

export const TermSection = styled.div`
  margin-top: 1rem;
`;

export const InterestTypeSection = styled.div`
  margin-top: 1rem;
`;

export const InterestTypeContainer = styled(RadioGroup)`
  display: flex !important;
  justify-content: space-between;
  flex-flow: column;
`;

export const InterestType = styled.div`
  border-radius: 8px;
  background: ${palette.semantic.InfoBanreservas100};
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  & div {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: ${palette.textAndLine.PlataBanreservas700B};
  }

  &:nth-of-type(2) {
    margin-top: 1rem;
  }
`;

export const InterestTypeRadio = styled.div`
  display: inline-block;

  & input[type='radio'] {
    display: none;
  }

  & label:before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 15px 0 5px;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #cacccd;
    background-color: transparent;
  }

  & input[type='radio']:checked + label:before {
    border: 2px solid #00aef0;
  }

  & input[type='radio']:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 9px;
    content: ' ';
    display: block;
    background: #00aef0;
  }

  & input {
    margin-right: 8px;
  }

  & label {
    position: relative;
    margin: 0px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: ${palette.brand.AzulBanreservas700B};
  }
`;

export const TermContainer = styled(ToggleButtonGroup)`
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hide;
  padding: 5px 0;
`;

export const TermButton = styled(ToggleButton)`
  border-radius: 8px !important;
  border: 1px solid ${palette.textAndLine.PlataBanreservas300} !important;
  background: ${palette.textAndLine.BlancoBanreservas000} !important;
  height: 72px;
  min-width: 63px;
  margin: 0 12px;
  color: ${palette.semantic.InfoBanreservas700} !important;
  font-family: Open Sans !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  line-height: 26px !important;
  display: block !important;
  cursor: pointer;

  & small {
    display: block;
    text-transform: Capitalize;
    font-size: 10px;
    font-weight: normal;
    font-weight: 400;
    line-height: 14px !important;
  }

  &.Mui-selected {
    border: 1.5px solid ${palette.brand.AzulBanreservas100B} !important;
    background: #d3eefb !important;
    font-weight: 700 !important;

    &.MuiToggleButtonGroup-grouped {
      margin-left: 0 !important;
      border-left: 1px solid ${palette.brand.AzulBanreservas100B} !important;
    }

    & small {
      font-weight: 600;
    }
  }

  &:focus {
    outline: none;
  }

  &.MuiToggleButtonGroup-grouped {
    margin-left: 0 !important;
    border-left: 1px solid ${palette.textAndLine.PlataBanreservas300} !important;
  }
`;

export const PerformanceTableBtnContainer = styled.div`
  margin-top: 16px;
`;

export const PerformanceTableBtn = styled(ContinueButton)`
  border: 1px solid ${palette.brand.AzulBanreservas100B};
  background-color: transparent;
  color: ${palette.brand.AzulBanreservas100B} !important;

  &.Mui-disabled{
       border: 0px;
    }
`;

export const ToggleContainer = styled(SummaryContainer)`
  background-color: transparent;
  padding: 0 0 16px 0;
  box-sizing: content-box;
  gap: 0px;
  position: absolute;
  height: 0px;
  opacity: 0;
  transform: translateY(100%);
  display: flex;
  transition: transform 0.5s 0.2s ease-in, position 0.1s 0.5s ease-in;

  &.toggle-up {
    position: relative;
    height: 68px;
    opacity: 1;
    transition: transform 0.5s 0.1s ease-in, opacity 0.3s 0.1s ease-in, position 0.3s 0.1s ease-in,
      height 0.5s 0.1s ease-in;
    transform: translateY(0%);
    display: flex;
  }
`;

export const ToggleTitle = styled(SummaryTitle)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
`;

export const ToggleAmount = styled(SummaryAmount)`
  font-size: 24px;
  line-height: 32px;
  margin-top: 0px;
`;

export const ToggleDescription = styled(SummaryDescription)`
  font-size: 12px;
  line-height: 16px;
  color: ${palette.textAndLine.PlataBanreservas700B};
  font-weight: 400;
`;
