import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  SAVE_ACCOUNT_DATA_PAYLOAD,
  SAVE_ACCOUNT_DATA_RESPONSE,
} from 'Constants/SaveAccount';
import endpoints from 'Core/Utils/ApiEndpoints';
import {
  setDocument, setDocumentType, setTerminal, setUser,
} from 'Core/Store/Reducers/clientInfoSlice';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import usePost from './usePost';

const { REACT_APP_ENVIRONMENT } = process.env;

const mockDataForDevEnv = {
  documentNumber: '40239753144',
  documentType: 'CEDULA',
  terminal: 'android 13 | 8.151.22.10.18 | Man',
  user: 'sfrances',
};

const useSaveAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    document, documentType, terminal, user,
  } = useSelector((state) => state.clientInfo);
  const [payload, setPayload] = useState();
  const { data, callApi } = usePost(
    endpoints.saveAccount,
    API_HEADERS(),
    REACT_APP_ENVIRONMENT === 'DEV',
    SAVE_ACCOUNT_DATA_RESPONSE[200],
  );

  const SaveAccount = () => {
    const firmaDataPayload = {
      ...SAVE_ACCOUNT_DATA_PAYLOAD,
      documentNumber: document,
      documentType,
      terminal,
      user,
    };
    if (REACT_APP_ENVIRONMENT === 'DEV') {
      setPayload(mockDataForDevEnv);
    } else {
      setPayload({ ...firmaDataPayload });
    }
  };

  useEffect(() => {
    if (payload?.documentNumber) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data) {
      if (REACT_APP_ENVIRONMENT === 'DEV') {
        dispatch(setDocument(mockDataForDevEnv.documentNumber));
        dispatch(setDocumentType(mockDataForDevEnv.documentType));
        dispatch(setTerminal(mockDataForDevEnv.terminal));
        dispatch(setUser(mockDataForDevEnv.user));
      }
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    saveData: data,
    SaveAccount,
  };
};

export default useSaveAccount;
