/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit';
import errorBoundaryReducer from 'Components/ErrorBoundary/ErrorBoundarySlice';
import stepSlice from 'Core/Store/Reducers/appRouterSlice';
import clientInfoSlice from 'Core/Store/Reducers/clientInfoSlice';
import termsAndConditionsSlice from 'Core/Store/Reducers/TermsAndConditionsSlice';
import certificateDepositOfferSlice from 'Core/Store/Reducers/certificateDepositOfferSlice';

const rootReducer = {
  errorBoundary: errorBoundaryReducer,
  routerData: stepSlice,
  clientInfo: clientInfoSlice,
  certificateDepositOffer: certificateDepositOfferSlice,
  termsAndConditions: termsAndConditionsSlice,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
