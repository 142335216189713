/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { useField } from 'formik';

import {
  StyledCheckbox, StyledFormControlLabel,
} from './style';

const Checkbox = ({
  label,
  name,
  onClick,
}) => {
  const [field] = useField({ label, name });
  const { onChange, value: fieldValue } = field;

  return (
    <StyledFormControlLabel
      label={label}
      control={(
        <StyledCheckbox
          checked={fieldValue}
          onChange={onChange}
          onClick={onClick}
          name={name}
        />
      )}
    />
  );
};

Checkbox.defaultProps = {
  onClick: () => {},
};

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Checkbox;
