/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import { palette } from 'Core/Styles/Theme';

export const StyledAnchor = styled.a`
color: ${palette.textAndLine.PlataBanreservas700B};
font-weight: 400;
text-decoration: none;
`;
