import edit from 'Core/Utils/Assets/Edit.svg';
import calendar from 'Core/Utils/Assets/Calendar.svg';
import chevronDown from 'Core/Utils/Assets/Chevron-2 down.svg';
import chevronUp from 'Core/Utils/Assets/Chevron-2 up.svg';
import { palette } from 'Core/Styles/Theme';

const muiTheme = {
  palette: {
    primary: {
      main: palette.brand.AzulBanreservas100B,
    },
    secondary: {
      main: palette.brand.AzulBanreservas700B,
    },
  },
  typography: {
    fontFamily: 'Open Sans, Arial',
  },
  components: {
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          button: {
            textTransform: 'capitalize',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          borderTop: `4px solid ${palette.brand.AzulBanreservas100B}`,
          '.MuiPickersToolbar-root h4': {
            textTransform: 'capitalize',
          },
          '.MuiPickersToolbar-root > .MuiTypography-root': {
            display: 'none',
          },
          '.MuiGrid-container > [aria-label="calendar view is open, go to text input view"]': {
            backgroundImage: `url("${edit}")`,
            backgroundRepeat: 'no-repeat',
            borderRadius: 0,
          },
          '.MuiGrid-container > [aria-label="text input view is open, go to calendar view"]': {
            backgroundImage: `url("${calendar}")`,
            backgroundRepeat: 'no-repeat',
            borderRadius: 0,
          },
          '.MuiGrid-container > .MuiIconButton-root svg': {
            display: 'none',
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          paddingBottom: '15px',
        },
        labelContainer: {
          border: `1px solid ${palette.brand.AzulBanreservas100B}`,
          borderRadius: 4,
          padding: '8px 8px 8px 13px',
          textTransform: 'capitalize',
          '.MuiButtonBase-root': {
            margin: '0 5px',
          },
          '.MuiButtonBase-root svg': {
            display: 'none',
          },
          '[aria-label="year view is open, switch to calendar view"]': {
            backgroundImage: `url("${chevronUp}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: 0,
          },
          '[aria-label="calendar view is open, switch to year view"]': {
            backgroundImage: `url("${chevronDown}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: 0,
          },
        },
      },
    },
    MuiPickersFadeTransitionGroup: {
      styleOverrides: {
        root: {
          'button.Mui-selected, button.Mui-selected:hover': {
            backgroundColor: `${palette.brand.AzulBanreservas100B} !important`,
            borderRadius: 8,
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiCalendarOrClockPicker: {
      styleOverrides: {
        mobileKeyboardInputView: {
          '.MuiInputBase-root svg': {
            display: 'none',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${palette.textAndLine.PlataBanreservas100}`,
          padding: '10px 8px 8px 0px',
          height: '62px',
          justifyContent: 'space-around',
          button: {
            height: '40px',
            fontSize: 0,
            fontWeight: 700,
          },
          'button:first-child': {
            width: '6.25rem',
            color: palette.brand.AzulBanreservas100B,
          },
          'button:last-child': {
            width: '8rem',
            borderRadius: 8,
            backgroundColor: palette.brand.AzulBanreservas100B,
            color: '#FFFFFF',
          },
          'button:first-child .MuiTouchRipple-root:before': {
            content: '"CANCELAR"',
            fontSize: '0.8rem',
            lineHeight: '40px',
          },
          'button:last-child .MuiTouchRipple-root:before': {
            content: '"SELECCIONAR"',
            fontSize: '0.875rem',
            lineHeight: '40px',
          },
        },
      },
    },
  },
};

export default muiTheme;
