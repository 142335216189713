/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useDispatch, useSelector } from 'react-redux';
import useStep from 'Services/useStep';
import Checkbox from 'Components/Checkbox';
import useFirma from 'Services/api/useFirma';
import TermsAndConditionsText from 'Core/Utils/TermsAndConditionsText';
import { acceptTerms } from 'Core/Store/Reducers/TermsAndConditionsSlice';
import {
  BodyContent, ContinueButton, ButtonContainer,
} from 'Core/Styles/CommonStyles';
import {
  PageTitle, BodySection, TextContainer,
  TermBodyContainer,
} from './style';
import LegalProvisionComponent from '../LegalProvision';

const validationSchema = Yup.object().shape({
  termsAndConditions: Yup.boolean()
    .oneOf([true], 'Campo requerido')
    .required('Campo requerido'),
});

const TermsAndConditions = () => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const analytics = getAnalytics();
  const [didAccept, setDidAccept] = useState(false);
  const { getFirma } = useFirma();
  const { hasAContract, contractDate } = useSelector((state) => state.clientInfo);
  const { validatePath, generateToken } = useStep();

  useEffect(() => {
    validatePath();
  }, []);

  useEffect(() => {
    const { termsAndConditions } = formikRef.current.values;
    if (termsAndConditions) {
      formikRef.current.setFieldValue('termsAccepted', true);
      setDidAccept(true);
    } else {
      formikRef.current.setFieldValue('termsAccepted', false);
      setDidAccept(false);
    }
  });

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{
        termsAndConditions: false,
        termsAccepted: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        generateToken();
        dispatch(acceptTerms());
        getFirma();
        logEvent(analytics, 'Accept Terms And Conditions Contract', {
          content_type: 'Button - Accept Terms And Conditions Contract Checkbox',
          content_id: 'Digital Sale Checkbox - Accept Terms And Conditions Contract to Transition into Opening Confirmation Screen',
        });
      }}
    >
      {({
        dirty, isValid, isSubmitting, handleSubmit, setFieldValue,
      }) => (
        <Form>
          <TermBodyContainer>
            <BodyContent style={{ overflow: 'hidden' }}>
              <PageTitle>{ hasAContract ? '' : 'Contrato de servicios bancarios Banreservas'}</PageTitle>
              <BodySection data-testid="body-section">

                { hasAContract
                  ? (
                    <LegalProvisionComponent date={contractDate} />
                  )
                  : (
                    <TextContainer>
                      <TermsAndConditionsText />
                    </TextContainer>
                  )}

              </BodySection>
            </BodyContent>
            <Checkbox
              label={hasAContract ? 'Estoy de acuerdo.' : 'Acepto haber leído y estar de acuerdo con el contrato'}
              name="termsAccepted"
              onClick={() => {
                setFieldValue('termsAndConditions', !didAccept);
                setDidAccept(!didAccept);
              }}
            />
          </TermBodyContainer>
          <ButtonContainer>
            <ContinueButton
              variant="contained"
              disableElevation
              disabled={!(isValid && dirty && !isSubmitting && didAccept)}
              onClick={handleSubmit}
            >
              { hasAContract ? 'ABRIR CUENTA' : 'CONTINUAR'}
            </ContinueButton>

          </ButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default TermsAndConditions;
