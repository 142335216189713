export const palette = {
  brand: {
    AzulBanreservas900: '#1E3E5A',
    AzulBanreservas700B: '#264E72',
    AzulBanreservas500: '#3A78AF',
    AzulBanreservas300: '#0398D1',
    AzulBanreservas100B: '#00AEF0',
    AzulBanreservas000: '#5BC9F2',
    NaranjaBanreservas300: '#E0851C',
    NaranjaBanreservas100B: '#F89420',
    NaranjaBanreservas000: '#F6AD57',
  },
  textAndLine: {
    NegroBanreservas900: '#000000',
    PlataBanreservas700B: '#626266',
    BlancoBanreservas000: '#FFFFFF',
    PlataBanreservas100: '#F4F5F7',
    PlataBanreservas300: '#CACCCD',
    PlataBanreservas500: '#B1B3B5',
  },
  semantic: {
    InfoBanreservas700: '#264E72',
    InfoBanreservas100: '#ECF7FB',
    SuccessBanreservas700: '#34DA8D',
    SuccessBanreservas100: '#E4FFF3',
    WarningBanreservas700: '#FFF0B3',
    WarningBanreservas100: '#FFF6D2',
    ErrorBanreservas700: '#BC2D2C',
    ErrorBanreservas100: '#FFEBE5',
  },
};

const theme = {
  sm: {
    title: {
      fontSize: '20px',
      lineHeight: '26 auto',
    },
    text: {
      fontSize: '10px',
      lineHeight: '14 auto',
    },
    button: {
      fontSize: '5rem',
      buttonSize: '5rem',
    },
  },

  md: {
    title: {
      fontSize: '24px',
      lineHeight: '32 auto',
    },
    text: {
      fontSize: '12px',
      lineHeight: '16 auto',
    },
    button: {
      fontSize: '5rem',
      buttonSize: '5rem',
    },
  },

  lg: {
    title: {
      fontSize: '32px',
      lineHeight: '40 auto',
    },
    text: {
      fontSize: '14px',
      lineHeight: '20 auto',
    },
    button: {
      fontSize: '5rem',
      buttonSize: '5rem',
    },
  },

  xl: {
    title: {
      fontSize: '48px',
      lineHeight: '56 auto',
    },
    text: {
      fontSize: '16px',
      lineHeight: '22 auto',
    },
    button: {
      fontSize: '5rem',
      buttonSize: '5rem',
    },
  },

  typography: {
    fontFamily: {
      primary: 'Freight Sans',
      secundary: 'Open Sans',
    },
    h1: {
      fontSize: '5rem',
      fontFamily: 'Raleway',
      isBold: true,
    },
    h2: {
      fontSize: '3.5rem',
      fontFamily: 'Open Sans',
      isBold: true,
    },
    h3: {
      fontSize: '2.5rem',
      fontFamily: 'Roboto',
      isBold: true,
    },
  },

  messages: {
    information: {
      color: palette.semantic.InfoBanreservas100,
    },
    success: {
      color: palette.semantic.SuccessBanreservas100,
    },
    warning: {
      color: palette.semantic.WarningBanreservas100,
    },
    error: {
      color: palette.semantic.ErrorBanreservas100,
    },
  },
};

export default theme;
