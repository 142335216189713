export const CD_CALCULATOR_CONFIG = {
  terms: [2, 6, 12, 18, 24],
  defaultTerm: 2,
  interestTypes: [
    {
      value: 'CAP',
      name: 'type',
      title: 'Capitalizable',
      description:
        'El interés generado se sumará a tu capital, lo que hará que cada mes tu inversión crezca.',
      hasInput: false,
    },
    {
      value: 'CRED',
      name: 'type',
      title: 'Crédito a cuenta',
      description: 'El interés generado se acreditará a tu cuenta de ahorros o corriente.',
      hasInput: true,
    },
  ],
  defaultInterestType: 'CAP',
  investmentAmount: 10000,
  minInvestmentAmount: 10000,
  maxInvestmentAmount: 2000000,
  defaultPerformanceAmount: 0,
  defaultRate: 0.0,
  fixedInterestRateTerm: process.env.REACT_APP_J6,
};

export const CD_PARAMETERS = {
  200: { minimumAmount: 10000, maximumCumulativeAmount: 2000000 },
  400: {
    description: 'Business error',
  },
  500: {
    description: 'Server error',
  },
};

export const CD_TARIFF_RESPONSE = {
  200: {
    ratesTable: [
      {
        term: 3,
        rates: [
          {
            minAmount: 1000,
            maxAmount: 2000,
            rate: 4.3,
          },
          {
            minAmount: 2001,
            maxAmount: 4000,
            rate: 4.3,
          },
        ],
      },
      {
        term: 6,
        rates: [
          {
            minAmount: 1000,
            maxAmount: 2000,
            rate: 4.3,
          },
          {
            minAmount: 2001,
            maxAmount: 4000,
            rate: 4.3,
          },
        ],
      },
    ],
  },
  400: {
    description: 'Business error',
  },
  500: {
    description: 'Server error',
  },
};

export const CD_MAXIMUM_PRODUCT_AMOUNT = {
  200: {
    currency: 'DOP',
    amount: 2000126241.18,
    available: 234876.0,
  },
  400: {
    description: 'Business error',
  },
  500: {
    description: 'Server error',
  },
};

export default CD_CALCULATOR_CONFIG;
