export const CD_TERMS_COND_DATA_PAYLOAD = {
  user: '',
  documentType: 'CEDULA',
  documentNumber: '',
  hasAcceptedTermAndConditions: false,
  termAndConditionVersion: '1.0.0',
};

export const CD_TERMS_COND_DATA_RESPONSE = {
  200: {
    trnId: 1234050606,
    date: '2023-12-06T12:20:30',
    status: true,
    message: 'Certificate has been opened successfully',
    totalProfitability: 5800,
    monthlyProfitability: 460,
    term: 12,
    rate: 4.6,
    profitType: 'CreditoCuenta',
    productType: 'CuentaAhorro',
    productNumber: '******1234',
    certificateProductNumber: 10439118294,
  },
  400: {
    description: 'Business error',
  },
  500: {
    description: 'Server error',
  },
};
