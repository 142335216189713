import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const ChevronRight = ({ color }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.69531 12C4.69531 7.58169 8.27731 4 12.6953 4C17.1134 4 20.6953 7.58169 20.6953 12C20.6953 16.4178 17.1131 20 12.6953 20C8.27755 20 4.69531 16.4178 4.69531 12ZM5.89531 12C5.89531 15.755 8.94027 18.8 12.6953 18.8C16.4503 18.8 19.4953 15.755 19.4953 12C19.4953 8.24445 16.4506 5.2 12.6953 5.2C8.94003 5.2 5.89531 8.24445 5.89531 12ZM11.0599 9.58104C10.8851 9.34653 10.9037 9.01316 11.1163 8.79969C11.3501 8.56487 11.73 8.56405 11.9648 8.79786L14.7536 11.5746L14.812 11.642C14.9872 11.8773 14.9677 12.2118 14.7536 12.425L11.9648 15.2018L11.8974 15.2598C11.6621 15.4335 11.3288 15.4134 11.1163 15.2L11.0584 15.1326C10.8846 14.8973 10.9047 14.564 11.1181 14.3514L13.4793 11.999L11.1181 9.64822L11.0599 9.58104Z"
      fill={color}
    />
  </svg>
);

ChevronRight.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

ChevronRight.propTypes = {
  color: PropTypes.string,
};

export default ChevronRight;
