import styled from 'styled-components';
import { Box, Drawer } from '@mui/material';
import CloseIcon from 'Core/Utils/Assets/CD/CloseX.svg';

export const StyledBox = styled(Box)`
    border-radius: 80px;
`;

export const CloseButton = styled.image`
  background-image: url(${CloseIcon});
  background-size: 1.8rem;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 18px;
  top: 14px;
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    border: none;
    border-radius: 16px 16px 0 0;
  }
`;
