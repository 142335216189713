import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from 'Core/Utils/Assets/Close_circle.svg';
import { Subtitle } from 'Core/Styles/CommonStyles';

// eslint-disable-next-line import/prefer-default-export
export const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 90%;
  ${({ isSmall }) => isSmall && `
        height: 84%;
        width: 84%;
  `}
  border-top: 0.25rem solid #00AEF0;
  border-radius: 0.5rem;
  background-color: white;
  &:focus {
        outline: none;
        box-shadow: 0px 0px 0px white;
    }


`;

export const CloseButton = styled.image`
  background-image: url(${CloseIcon});
  background-size: 1.8rem;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

export const ModalTitle = styled(Subtitle)`
  text-align: left;
  margin: 0 1.5rem;
  margin-bottom: 0.5rem;
`;

export const ModalText = styled(Subtitle)`
  position: absolute;
  border-top: 4px solid #00AEF0;
  border-radius: 8px;
  box-shadow: 24;
  background-color: white;
`;

export const SubmitButton = styled(Button)`
    width: 90%;
    height: 7%;
    text-align: center;
    background-color: #00AEF0;
    color: #fff;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 0.5rem;
    position: absolute;
    bottom: 1rem;
`;

export const TextContainer = styled.div`
    height: 100%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    overflow-x:hidden;
    overflow-y:auto;
    background: 
      linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
      linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(181,181,181, 0.5), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(181,181,181, 0.5), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 81px, 100% 81px, 100% 27px, 100% 27px;
`;

export const HeaderSection = styled.div`
    margin-top: 3.25rem;
    height: 5%;
    margin-bottom: 1rem;
`;
export const BodySection = styled.div`
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    height: 70%;
`;
export const FooterSection = styled.div`
    height: 15%;
`;
