/*eslint-disable */
import React, { useState } from 'react';
import Header from 'Components/Header';
import {
  BodyContainer,
  DataAmmount,
  DataContainer,
  DataTitle,
  InfoIconContainer,
  PageDescription,
  PageSection,
  PageTitle,
  PerformanceBodyContent,
  PerformanceData,
  PerformanceSection,
  SummaryAmount,
  SummaryContainer,
  SummaryDescription,
  SummaryTitle,
  TableHeaders,
  TableRow,
  TableRows,
  TableSection,
} from './style';
import LoadingScreen from 'Components/LoadingScreen';
import { SpinnerText2 } from 'Constants/SpinnerText';
import { formatCurrency } from 'Core/Utils/UtilityFunctions';
import { useSelector } from 'react-redux';
import BaseDrawer from 'Components/Drawer/Drawer';
import SheetInfo from 'Components/SheetInfo';
import { InfoIconHelper as InfoIcon } from 'Core/Styles/CommonStyles';

const PerformanceTable = () => {
  const { cdOpened, performanceTable, amount, aproxPerformanceAmount } = useSelector(
    (state) => state.certificateDepositOffer,
  );
  const { isloading, setIsLoading } = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return !isloading ? (
    <div>
      <Header title="Tabla de rendimientos" />
      <BodyContainer>
        <PerformanceBodyContent>
          <PageSection>
            <PageTitle>¡Saca el mayor provecho a tu dinero!</PageTitle>
            <PageDescription>Obtén al detalle la rentabilidad de tu dinero</PageDescription>
          </PageSection>
          <PerformanceSection>
            <SummaryContainer>
              <SummaryTitle>Rendimiento</SummaryTitle>
              <SummaryAmount>
                <span>DOP</span>
                <span>{formatCurrency(aproxPerformanceAmount)}</span>
              </SummaryAmount>
              <SummaryDescription>
                Este cálculo es una estimación y no contempla la retención de impuestos.
              </SummaryDescription>
              <InfoIconContainer onClick={() => setIsDrawerOpen(true)} data-testid="info-icon">
                <InfoIcon/>
              </InfoIconContainer>
            </SummaryContainer>
            <PerformanceData>
              <DataContainer>
                <DataTitle>Monto a invertir</DataTitle>
                <DataAmmount>
                  <span>DOP </span>
                  <span>{formatCurrency(amount)}</span>
                </DataAmmount>
              </DataContainer>
              <DataContainer>
                <DataTitle>Tasa anual</DataTitle>
                <DataAmmount>
                  <span>{cdOpened?.rate}%</span>
                </DataAmmount>
              </DataContainer>
            </PerformanceData>
          </PerformanceSection>
          <TableSection>
            <TableHeaders>
              <span>Plazo</span>
              <span>Interés</span>
              <span>Capital acumulado</span>
            </TableHeaders>
            <TableRows>
              {performanceTable.map((performanceItem) => (
                <TableRow
                  key={`perm.${performanceItem.term}`}
                  value={performanceItem}
                  aria-label={performanceItem}
                >
                  <span>
                    {performanceItem.term} {performanceItem.term > 1 ? 'meses' : 'mes'}
                  </span>
                  <span>+ {formatCurrency(performanceItem.profitability)}</span>
                  <span>{formatCurrency(performanceItem.total)}</span>
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableRows>
          </TableSection>
          <BaseDrawer isOpen={isDrawerOpen} toggleDrawer={setIsDrawerOpen}>
            <SheetInfo />
          </ BaseDrawer>
        </PerformanceBodyContent>
      </BodyContainer>
    </div>
  ) : (
    <LoadingScreen isOpen text={SpinnerText2} isWhite />
  );
};

export default PerformanceTable;
