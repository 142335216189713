import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import endpoints from 'Core/Utils/ApiEndpoints';
import {
  CD_TERMS_COND_DATA_RESPONSE,
  CD_TERMS_COND_DATA_PAYLOAD,
} from 'Constants/CDOpenConfirmation';
import { setCDOpened } from 'Core/Store/Reducers/certificateDepositOfferSlice';
import usePost from './usePost';

const { REACT_APP_ENVIRONMENT } = process.env;

const useCDTermsCond = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    document,
    documentType,
    user,
    authorization,
  } = useSelector((state) => state.clientInfo);
  const { amount, cdSelectedTerm, cdOpened } = useSelector(
    (state) => state.certificateDepositOffer,
  );
  const [payload, setPayload] = useState();
  const { data, callApi } = usePost(
    endpoints.cdTermsCond,
    {
      ...API_HEADERS(),
      Authorization: `Bearer ${authorization}`,
    },
    REACT_APP_ENVIRONMENT === 'DEV',
    CD_TERMS_COND_DATA_RESPONSE[200],
  );

  const lowerCaseToTitle = (value) => {
    const lowerCase = value.toLowerCase();
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  };

  const profitTypeMap = {
    CAP: 'Capitalizable',
    CRED: 'CreditoCuenta',
  };

  const getCDTermsCond = () => {
    const OpenConfirmationDataPayload = {
      ...CD_TERMS_COND_DATA_PAYLOAD,
      user,
      documentNumber: document,
      documentType: lowerCaseToTitle(documentType),
      hasAcceptedTermAndConditions: true,
      termAndConditionVersion: '1.0.0',
      amount,
      profitType: profitTypeMap[cdOpened.profitType],
      term: cdSelectedTerm,
    };
    setPayload({ ...OpenConfirmationDataPayload });
  };

  useEffect(() => {
    if (payload?.documentNumber && payload?.hasAcceptedTermAndConditions) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data?.status) {
      dispatch(setCDOpened(data));
      navigate('/certificadoDigitalSummary');
    } else if (data !== undefined) {
      navigate('/clientInfoError');
    }
  }, [data]);

  return {
    data,
    getCDTermsCond,
  };
};

export default useCDTermsCond;
