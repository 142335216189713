import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { palette } from 'Core/Styles/Theme';

export const StyledBox = styled(Box)`
display: flex;
width: 325px;
padding: 24px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
background-color: white;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
position: absolute;
border-radius: 24px;

&:focus {
        outline: none;
        box-shadow: 0px 0px 0px white;
    }
`;

export const ActionButtons = styled.div`
width: 100%;
display: inline-flex;
justify-content: space-between;
`;

export const StyledNPSButton = styled(Button)`
text-align: center;
font-family: Open Sans;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; 
text-transform: uppercase;
color: ${(props) => (props.variant === 'text' ? palette.brand.AzulBanreservas100B
    : palette.textAndLine.BlancoBanreservas000)};

&.Mui-disabled{
        background-color: ${palette.textAndLine.PlataBanreservas100};
        color: ${(props) => props.buttoncolor || palette.textAndLine.PlataBanreservas300} !important;
    }
`;

export const FooterSection = styled.div`
display: flex;
padding: 0px 24px;
flex-direction: column;
align-items: center;
gap: 16px;
align-self: stretch;
`;

export const StyledHr = styled.hr`
width: 277px;
height: 1px;
background: ${palette.textAndLine.PlataBanreservas100};
margin: 0;
`;
