import styled from 'styled-components';
import { Divider } from '@mui/material';
import { BottomParagraph, Subtitle } from 'Core/Styles/CommonStyles';

export const StyledSubtitle = styled(Subtitle)`
    margin: 16px 48px 16px 24px !important;
`;

export const StyledBParagraph = styled(BottomParagraph)`
    margin-top: 0px;
    margin-right: 24px;
    margin-left: 24px;
    line-height: 26px;
`;

export const CustomDivider = styled(Divider)`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`;
