/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { StyledNPSBodyContainer, TitleModalNPS, TitleModalNPSContainer } from 'Core/Styles/CommonStyles';
import INITIAL_RATING from 'Constants/RatingButtonsValues';
import {
  StyledSpan, StyledRatingButtonGroup, StyledButtonContainer,
  StyledAvatar, StyledRatingText,
} from './style';

const RatingButtons = ({ setSelectedRating }) => {
  const [rating, setRating] = useState(INITIAL_RATING);
  const handleRatingChange = (newRate) => {
    const newState = rating.map((rate) => {
      if (rate.id === newRate.id) {
        return { ...rate, isSelected: true };
      }
      return { ...rate, isSelected: false };
    });

    setRating(newState);
    setSelectedRating(newRate);
  };

  return (
    <StyledNPSBodyContainer>
      <TitleModalNPSContainer>
        <TitleModalNPS>
          ¿Cómo calificarías tu experiencia al abrir
          la Cuenta Digital Banreservas?
        </TitleModalNPS>
      </TitleModalNPSContainer>

      <StyledRatingButtonGroup>
        {rating?.map((element) => (
          <StyledButtonContainer key={element.id}>
            <StyledAvatar isSelected={element.isSelected}>
              <IconButton data-testid={`button-${element.id}`} onClick={() => handleRatingChange(element)}>
                <StyledSpan role="img" aria-label={element.ratingValue}>{element.emoji}</StyledSpan>
              </IconButton>
            </StyledAvatar>
            <StyledRatingText>{element.ratingLabel}</StyledRatingText>
          </StyledButtonContainer>
        ))}
      </StyledRatingButtonGroup>
    </StyledNPSBodyContainer>
  );
};

RatingButtons.propTypes = {
  setSelectedRating: PropTypes.func.isRequired,
};

export default RatingButtons;
