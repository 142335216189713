/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { Subtitle, BodyContainer } from 'Core/Styles/CommonStyles';

export const TermBodyContainer = styled(BodyContainer)`
  &>label {
    margin-top: 1.5rem;
  }
  height: calc(100vh - 8.5rem);
`;

export const CardContainer = styled(Card)`
  border-top: none;
  box-shadow: none;
  padding-top: 0;
`;

export const PageTitle = styled(Subtitle)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

export const IconButton = styled.button`
    border: 0;
    background: none;
    text-align: center;
    padding: 0;
    top: 3px;
    position: relative;
    left: 4px;
`;

export const CardFooterActions = styled(CardActions)`
  flex-direction: row-reverse;
`;

export const TextContainer = styled.div`
    height: 85%;
    overflow-x:hidden;
    overflow-y:auto;
    background: 
      linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
      linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(181,181,181, 0.5), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(181,181,181, 0.5), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 81px, 100% 81px, 100% 27px, 100% 27px;
`;

export const BodySection = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    overflow-y:hidden;
    height: 100%;
`;
