import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const BackLinkButton = ({ color }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6953 12C20.6953 7.58169 17.1133 4 12.6953 4C8.27727 4 4.69531 7.58169 4.69531 12C4.69531 16.4178 8.27754 20 12.6953 20C17.1131 20 20.6953 16.4178 20.6953 12ZM19.4953 12C19.4953 15.755 16.4504 18.8 12.6953 18.8C8.94028 18.8 5.89531 15.755 5.89531 12C5.89531 8.24445 8.94 5.2 12.6953 5.2C16.4506 5.2 19.4953 8.24445 19.4953 12ZM14.3308 9.58104C14.5056 9.34653 14.4869 9.01316 14.2744 8.79969C14.0405 8.56487 13.6606 8.56405 13.4258 8.79786L10.637 11.5746L10.5786 11.642C10.4034 11.8773 10.4229 12.2118 10.637 12.425L13.4258 15.2018L13.4932 15.2598C13.7285 15.4335 14.0618 15.4134 14.2744 15.2L14.3323 15.1326C14.506 14.8973 14.486 14.564 14.2725 14.3514L11.9113 11.999L14.2725 9.64822L14.3308 9.58104Z"
      fill={color}
    />
  </svg>
);

BackLinkButton.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

BackLinkButton.propTypes = {
  color: PropTypes.string,
};

export default BackLinkButton;
