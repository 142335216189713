import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const DotIcon = ({ color }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Dot">
      <path
        id="Vector"
        d="M3.99996 6.33335C3.34996 6.33335 2.79857 6.10697 2.34579 5.65419C1.89301 5.20141 1.66663 4.65002 1.66663 4.00002C1.66663 3.35002 1.89301 2.79863 2.34579 2.34585C2.79857 1.89308 3.34996 1.66669 3.99996 1.66669C4.64996 1.66669 5.20135 1.89308 5.65413 2.34585C6.1069 2.79863 6.33329 3.35002 6.33329 4.00002C6.33329 4.65002 6.1069 5.20141 5.65413 5.65419C5.20135 6.10697 4.64996 6.33335 3.99996 6.33335Z"
        fill={color}
      />
    </g>
  </svg>
);

DotIcon.defaultProps = {
  color: palette.brand.AzulBanreservas100B,
};

DotIcon.propTypes = {
  color: PropTypes.string,
};
export default DotIcon;
