import styled from 'styled-components';
import { TableCell } from '@mui/material';
import { palette } from 'Core/Styles/Theme';

// eslint-disable-next-line import/prefer-default-export

export const BodyTableCell = styled(TableCell)`
    padding: 1.125rem 0rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-left: 1.5rem;
    color: ${palette.textAndLine.PlataBanreservas700B};
    max-width: 135px;
    padding-right: ${(props) => (props.description ? '2.5rem' : '0')}
`;

export const FareCell = styled.text`
    color: ${palette.semantic.InfoBanreservas700};
    width: 45%;
`;

export const StrongFareCell = styled.strong`
    font-size: 1rem;
`;

export const InfoDetailText = styled.p`
    font-size: 0.75rem;
    font-family: 'Open Sans';
    padding-right: 2rem;
    margin-left: 0.5rem;
    color: ${palette.textAndLine.PlataBanreservas700B};
`;

export const BlockElement = styled.div`
    position: absolute;
    bottom: 12%;
`;
