import CheckIcon from '@mui/icons-material/Check';
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/Numbers';
import styled from 'styled-components';
import { BodyUserText, Subtitle } from 'Core/Styles/CommonStyles';
import { palette } from 'Core/Styles/Theme';
import Rendimiento from 'Core/Utils/Assets/CD/Rendimiento';
import Coins from 'Core/Utils/Assets/CD/Coins';

export const CDSBodyContainer = styled.div`
  padding: 24px;
`;

export const CDSIconSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CDSIconSubtitle = styled.div`
  align-self: stretch;
  text-align: center;
  color: #264e72;
  font-size: 20px;
  margin-top: 32px;

  & > div {
    margin-top: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #626266;
  }
`;

export const CDSAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 8px;
  background: #ecf7fb;
  margin: 24px 0px;

  & > div:nth-of-type(1) {
    color: #264e72;
    font-weight: 700;
    font-size: 16px;
  }

  & > div:nth-of-type(2) {
    color: #00aef0;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    span {
      font-size: 16px;
    }
  }
`;

export const CDSFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

export const CDSFrameSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

export const CDSFrameIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #264e72;
  }
`;

export const CDSFrameBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 89%;
  border-bottom: 1px solid #f4f5f7;
  padding-bottom: 16px;
`;

export const CDSFrameBodyTitle = styled.div`
  color: #626266;
  /* Texto/MD/Bold Auto */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 137.5% */
`;

export const CDSFrameBodyContent = styled.div`
  color: #264e72;
  /* Texto/MD/Regular Auto */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const NumbersIconContainer = styled(NumbersIcon)`
  color: #0398d1;
  width: 20px !important;
  height: 20px !important;
`;

export const PercentIconContainer = styled(PercentIcon)`
  color: #0398d1;
  width: 20px !important;
  height: 20px !important;
`;

export const CheckIconContainer = styled(CheckIcon)`
  color: #0398d1;
  width: 20px !important;
  height: 20px !important;
`;

export const RendimientoIconContainer = styled(Rendimiento)`
  color: #0398d1;
  width: 20px !important;
  height: 20px !important;
`;

export const CoinsIconContainer = styled(Coins)`
  color: #0398d1;
  width: 20px !important;
  height: 20px !important;
`;

export const BodyBulletPointsText = styled.div`
  color: ${palette.brand.AzulBanreservas700B};
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  li:last-child {
    padding-bottom: 0;
  }

  & svg {
    min-width: 24px;
  }

  li {
    list-style: none;
  }
`;

export const ListTitle = styled(Subtitle)`
  &.MuiTypography-root {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
  }
`;

export const ListContainer = styled(BodyUserText)`
  padding: 16px;
  margin-bottom: 24px;
`;

export const IconContainer = styled.div`
  width: 32px;
`;

export const CurrencyContainer = styled.div`
  color: ${palette.semantic.InfoBanreservas700};
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const MoneyAmount = styled.div`
  color: ${palette.semantic.InfoBanreservas700};
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const ErrorMessage = styled.div`
  color: ${palette.semantic.ErrorBanreservas700};
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  margin-top: 4px;
`;
