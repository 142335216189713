/* eslint-disable quote-props */
export const FIRMA_DATA_PAYLOAD = {
  documentType: 'CEDULA',
  documentNumber: '',
  version: '1',
};

export const FIRMA_DATA_RESPONSE = {
  '200': {
    ok: true,
  },
  '400': {
    description: 'Business error',
  },
  '500': {
    description: 'Server error',
  },
};
