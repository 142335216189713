import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'Core/Styles/Theme';

const Chevron2Down = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1143 10.3646C15.3488 10.1898 15.6821 10.2084 15.8956 10.421C16.1304 10.6548 16.1313 11.0347 15.8974 11.2695L13.1207 14.0583L13.0533 14.1167C12.818 14.2919 12.4835 14.2725 12.2703 14.0583L9.49346 11.2695L9.43554 11.2021C9.26178 10.9668 9.28186 10.6335 9.4953 10.421L9.56274 10.3631C9.79802 10.1893 10.1313 10.2094 10.3439 10.4228L12.6963 12.784L15.0471 10.4228L15.1143 10.3646Z"
      fill={color}
    />
  </svg>

);

Chevron2Down.defaultProps = {
  color: palette.brand.AzulBanreservas700B,
};

Chevron2Down.propTypes = {
  color: PropTypes.string,
};

export default Chevron2Down;
