import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import { VALIDATE_DATA_PAYLOAD, VALIDATE_DATA_RESPONSE } from 'Constants/Validate';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import usePost from './usePost';

const { REACT_APP_ENVIRONMENT } = process.env;

const useValidate = () => {
  const navigate = useNavigate();
  const {
    document,
    documentType,
    authorization,
  } = useSelector((state) => state.clientInfo);
  const [isValidUser, setValidUser] = useState(false);
  const [payload, setPayload] = useState();
  const { data, callApi } = usePost(
    endpoints.validateCertificate,
    {
      ...API_HEADERS(),
      Authorization: `Bearer ${authorization}`,
    },
    REACT_APP_ENVIRONMENT === 'DEV',
    VALIDATE_DATA_RESPONSE[200],
    true,
  );

  const validateUser = () => {
    const validateDataPayload = {
      ...VALIDATE_DATA_PAYLOAD,
      documentNumber: document,
      documentType,
    };
    setPayload({ ...validateDataPayload });
  };

  useEffect(() => {
    if (payload?.documentNumber) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data?.ok) {
      setValidUser(true);
    } else if (data !== undefined) {
      setValidUser(false);
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    isValidUser,
    validateUser,
  };
};

export default useValidate;
