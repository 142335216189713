/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import BackLinkButton from 'Core/Utils/Assets/BackLinkButton';
import { palette } from 'Core/Styles/Theme';
import useStep from 'Services/useStep';
import { truncateText } from 'Core/Utils/UtilityFunctions';
import {
  Container, Title, Divider, ContentContainer,
} from './style';

const mainColor = palette.brand.AzulBanreservas900;
const width = window.innerWidth;

const Header = ({ title, showBackButton }) => {
  const navigate = useNavigate();
  const { generateToken } = useStep();

  const handleClick = () => {
    generateToken();
    navigate(-1);
  };
  const maxLength = width <= 280 ? 43 : 60;
  const truncatedTitle = truncateText(title, maxLength);
  return (
    <Container>
      <Divider />
      <ContentContainer style={{ marginLeft: showBackButton ? '' : '55px' }}>
        {showBackButton && (
          <IconButton sx={{ marginTop: '2px' }} onClick={handleClick}>
            <BackLinkButton color={mainColor} />
          </IconButton>
        )}
        <Title>{truncatedTitle}</Title>
      </ContentContainer>
    </Container>
  );
};

Header.defaultProps = {
  title: '',
  showBackButton: true,
};

Header.propTypes = {
  title: PropTypes.string,
  showBackButton: PropTypes.bool,
};

export default Header;
