import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { Paragraph } from 'Core/Styles/CommonStyles';
import {
  BodySection,
  CloseButton,
  FooterSection,
  HeaderSection,
  ModalTitle,
  StyledBox as Box,
  SubmitButton,
  TextContainer,
} from './style';

const InfoModal = ({
  buttonText,
  children,
  modalTitle,
  modalSubtitle,
  onButtonClick,
  onClose,
  isOpen,
  isSmall,
}) => (
  <Modal
    aria-describedby="modal-modal-description"
    aria-labelledby="modal-modal-title"
    data-testid="modal-section"
    onClose={onClose}
    open={isOpen}
  >
    <Box isSmall={isSmall}>
      <CloseButton data-testid="close-button" onClick={onClose} />
      <HeaderSection data-testid="header-section">
        <ModalTitle>{modalTitle}</ModalTitle>
        {modalSubtitle && (
          <Paragraph sx={{ textAlign: 'left', marginLeft: '1.5rem' }}>{modalSubtitle}</Paragraph>
        )}
      </HeaderSection>

      <BodySection data-testid="body-section">
        <TextContainer>{children}</TextContainer>
      </BodySection>

      {!!onButtonClick && !!buttonText && (
        <FooterSection data-testid="footer-section">
          <SubmitButton data-testid="confirm-button" onClick={onButtonClick}>
            {buttonText}
          </SubmitButton>
        </FooterSection>
      )}
    </Box>
  </Modal>
);

InfoModal.defaultProps = {
  buttonText: '',
  modalTitle: '',
  modalSubtitle: '',
  onButtonClick: null,
  isOpen: false,
  isSmall: false,
};

InfoModal.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  modalTitle: PropTypes.string,
  modalSubtitle: PropTypes.string,
  onButtonClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default InfoModal;
