import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API_HEADERS from 'Core/Utils/ApiHeaders';
import {
  FIRMA_DATA_PAYLOAD,
  FIRMA_DATA_RESPONSE,
} from 'Constants/Firma';
import endpoints from 'Core/Utils/ApiEndpoints';
import { searchErrorCodeRoute } from 'Core/Utils/UtilityFunctions';
import usePost from './usePost';

const useFirma = () => {
  const navigate = useNavigate();
  const { document, documentType } = useSelector((state) => state.clientInfo);
  const [payload, setPayload] = useState();
  const { data, callApi } = usePost(
    endpoints.firma,
    API_HEADERS(),
    false,
    FIRMA_DATA_RESPONSE[200],
  );

  const getFirma = () => {
    const firmaDataPayload = {
      ...FIRMA_DATA_PAYLOAD,
      documentNumber: document,
      documentType,
    };
    setPayload({ ...firmaDataPayload });
  };

  useEffect(() => {
    if (payload?.documentNumber) {
      callApi(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (data?.ok === false || data?.ok) {
      navigate('/OpeningConfirmation');
    } else if (data !== undefined) {
      const responseError = searchErrorCodeRoute(data);
      navigate(responseError.errorRoute);
    }
  }, [data]);

  return {
    data,
    getFirma,
  };
};

export default useFirma;
