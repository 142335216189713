import React from 'react';

const MoneyExchange = () => (
  <svg width="171" height="104" viewBox="0 0 171 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1979_13873)">
      <path d="M165.98 45.4646H140.58C138.04 45.4646 135.98 43.4046 135.98 40.8646C135.98 38.3246 138.04 36.2646 140.58 36.2646H165.98C168.52 36.2646 170.58 38.3246 170.58 40.8646C170.58 43.4046 168.52 45.4646 165.98 45.4646Z" fill="#F89420" />
      <path d="M152.461 58.9646C151.281 58.9646 150.101 58.5246 149.201 57.6246C147.401 55.8246 147.401 52.9246 149.201 51.1246L162.721 37.6046C164.521 35.8046 167.421 35.8046 169.221 37.6046C171.021 39.4046 171.021 42.3046 169.221 44.1046L155.701 57.6246C154.801 58.5246 153.621 58.9646 152.441 58.9646H152.461Z" fill="#F89420" />
      <path d="M165.981 45.4647C164.801 45.4647 163.621 45.0247 162.721 44.1247L149.201 30.6247C147.401 28.8247 147.401 25.9247 149.201 24.1247C151.001 22.3247 153.901 22.3247 155.701 24.1247L169.221 37.6247C171.021 39.4247 171.021 42.3247 169.221 44.1247C168.321 45.0247 167.141 45.4647 165.961 45.4647H165.981Z" fill="#F89420" />
      <path d="M30 45.4646H4.6C2.06 45.4646 0 43.4046 0 40.8646C0 38.3246 2.06 36.2646 4.6 36.2646H30C32.54 36.2646 34.6 38.3246 34.6 40.8646C34.6 43.4046 32.54 45.4646 30 45.4646Z" fill="#F89420" />
      <path d="M4.60023 45.4646C3.42023 45.4646 2.24023 45.0246 1.34023 44.1246C-0.459766 42.3246 -0.459766 39.4246 1.34023 37.6246L14.8602 24.1046C16.6602 22.3046 19.5602 22.3046 21.3602 24.1046C23.1602 25.9046 23.1602 28.8046 21.3602 30.6046L7.84023 44.1246C6.94023 45.0246 5.76023 45.4646 4.58023 45.4646H4.60023Z" fill="#F89420" />
      <path d="M18.1002 58.9647C16.9202 58.9647 15.7402 58.5247 14.8402 57.6247L1.34023 44.1247C-0.459766 42.3247 -0.459766 39.4247 1.34023 37.6247C3.14023 35.8247 6.04023 35.8247 7.84023 37.6247L21.3602 51.1247C23.1602 52.9247 23.1602 55.8247 21.3602 57.6247C20.4602 58.5247 19.2802 58.9647 18.1002 58.9647Z" fill="#F89420" />
      <path d="M85.2798 80.8647C63.2198 80.8647 45.2798 62.9247 45.2798 40.8647C45.2798 18.8047 63.2398 0.864746 85.2798 0.864746C107.32 0.864746 125.28 18.8047 125.28 40.8647C125.28 62.9247 107.34 80.8647 85.2798 80.8647ZM85.2798 10.0647C68.2998 10.0647 54.4798 23.8847 54.4798 40.8647C54.4798 57.8447 68.2998 71.6647 85.2798 71.6647C102.26 71.6647 116.08 57.8447 116.08 40.8647C116.08 23.8847 102.26 10.0647 85.2798 10.0647Z" fill="#00AEF0" />
      <path d="M85.2796 17.1047C87.2996 17.1047 88.9596 18.7447 88.9596 20.7847V26.5447C88.9596 28.5647 87.3196 30.2247 85.2796 30.2247C83.2596 30.2247 81.5996 28.5847 81.5996 26.5447V20.7847C81.5996 18.7647 83.2396 17.1047 85.2796 17.1047Z" fill="#00AEF0" />
      <path d="M88.9796 54.5447C88.9796 52.5123 87.332 50.8647 85.2996 50.8647C83.2672 50.8647 81.6196 52.5123 81.6196 54.5447V60.2847C81.6196 62.3172 83.2672 63.9647 85.2996 63.9647C87.332 63.9647 88.9796 62.3172 88.9796 60.2847V54.5447Z" fill="#00AEF0" />
      <path d="M89.1 58.3248H74.66C72.38 58.3248 70.52 56.4648 70.52 54.1848C70.52 51.9048 72.38 50.0448 74.66 50.0448H89.1C89.82 50.0448 90.5 49.7648 91 49.2448C91.5 48.7448 91.78 48.0648 91.78 47.3448C91.78 45.8648 90.56 44.6448 89.08 44.6448H81.94C75.88 44.6448 70.96 39.7248 70.96 33.6648C70.96 27.6048 75.88 22.6848 81.94 22.6848C82 22.6848 82.08 22.6848 82.14 22.6848H94.22C96.5 22.6848 98.36 24.5448 98.36 26.8248C98.36 29.1048 96.5 30.9648 94.22 30.9648H81.92C81.92 30.9648 81.84 30.9648 81.8 30.9648C80.38 31.0248 79.24 32.2248 79.24 33.6648C79.24 35.1048 80.46 36.3648 81.94 36.3648H89.08C95.14 36.3648 100.06 41.2848 100.06 47.3248C100.06 53.3648 95.14 58.2848 89.08 58.2848L89.1 58.3248Z" fill="#00AEF0" />
    </g>
    <ellipse cx="85.29" cy="100.365" rx="45" ry="3.5" fill="#F4F5F7" />
    <defs>
      <clipPath id="clip0_1979_13873">
        <rect width="170.58" height="80" fill="white" transform="translate(0 0.864746)" />
      </clipPath>
    </defs>
  </svg>
);

export default MoneyExchange;
