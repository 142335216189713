/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Dialog, DialogActions } from '@mui/material';
import { palette } from 'Core/Styles/Theme';

export const CustomDialog = styled(Dialog)`
    text-align: center;
    .MuiDialog-paperScrollPaper {
        border: none;
        border-radius: 24px;
        margin: 24px;
        position: relative;
        width: 327px;
        height: 373px;
    }
    button:first-child 
        .MuiTouchRipple-root::before {
            content: "CERRAR";
            font-size: 0.8rem;
            line-height: 40px;
        }
    button:last-child 
        .MuiTouchRipple-root::before {
            content: "CONTINUAR";
            font-size: 0.875rem;
            line-height: 40px;
        }
`;

export const IconContainer = styled.div`
    margin: auto;
    margin-top: 24px;
`;

export const DialogTitle = styled.h2`
    margin: 0px;
        font-family: "Open Sans", Arial;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.6;
        color: ${palette.brand.AzulBanreservas700B};
        padding: 20px 16px 0 16px;
`;

export const DialogSubTitle = styled.h2`
    margin: 0px;
    font-family: "Open Sans", Arial;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    color: ${palette.brand.AzulBanreservas700B};
`;

export const CustomDialogActions = styled(DialogActions)`
    margin-bottom: 16px;
`;
