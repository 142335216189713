/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { useIMask } from 'react-imask';
import Check from '@mui/icons-material/Check';
import { palette } from 'Core/Styles/Theme';
import TextField from './style';

const CDInputText = ({
  addCheck,
  countCharacters,
  countCharactersRegexReplace,
  Icon,
  label,
  margin,
  mask,
  customMask,
  name,
  placeholder,
  size,
  type,
  defaultHelperText,
  onChange,
  inputRef,
}) => {
  const [field, meta, helpers] = useField({ label, name, type });
  const { onBlur, value: fieldValue } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;
  const hasError = (touched || !!fieldValue) && !!error;
  const { ref } = useIMask(customMask === null ? { mask } : customMask, {
    onAccept: (value, maskVal, e) => {
      maskVal.updateValue();
      setValue(value);
      onChange(e, value);
    },
  });
  const [helperText, setHelperText] = useState(' ');
  const [firstBlur, setFirstBlur] = useState(false);
  const [inputLabelClassName, setInputLabelClassName] = useState('');
  const [inputProps, setInputProps] = useState({
    ref: inputRef,
  });
  const [maskRef, setMaskRef] = useState(null);
  const [shrink, setShrink] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (fieldValue) {
      setShrink(true);
      setFirstBlur(true);
    }
  }, []);

  useEffect(() => {
    const isMask = mask || customMask;
    if (isMask) {
      setMaskRef(ref);
    }
  }, []);

  useEffect(() => {
    if (countCharacters > 0) {
      const lengthValue = countCharactersRegexReplace
        ? fieldValue?.replaceAll(countCharactersRegexReplace, '').length
        : fieldValue?.length;
      const displayText = hasError ? error : defaultHelperText;
      const text = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{displayText}</div>
          <div>{`${lengthValue}/${countCharacters}`}</div>
        </div>
      );
      setHelperText(text);
    } else if (countCharacters === 0 && defaultHelperText) {
      setHelperText(hasError ? error : defaultHelperText);
    } else {
      setHelperText(hasError ? error : ' ');
    }
  }, [countCharacters, fieldValue, hasError, meta]);

  useEffect(() => {
    if (Icon) {
      setInputLabelClassName('MuiLabelWithIcon');
      setInputProps({
        startAdornment: (
          <Icon fontSize="small" color={hasError ? 'error' : palette.brand.AzulBanreservas100B} />
        ),
      });
    }
  }, [Icon, hasError]);

  useEffect(() => {
    if (addCheck && !hasError && fieldValue && firstBlur) {
      setInputProps({
        startAdornment: <Check fontSize="small" color="primary" />,
      });
    } else if (addCheck && hasError && fieldValue && firstBlur) {
      setInputProps({
        startAdornment: null,
        // <Error color={palette.semantic.ErrorBanreservas700} />
      });
    } else {
      firstBlur && setFirstBlur(false);
      inputProps?.startAdornment
        && setInputProps({
          startAdornment: null,
        });
    }
  }, [Icon, addCheck, hasError, fieldValue, firstBlur]);

  return (
    <TextField
      InputLabelProps={{ shrink, className: inputLabelClassName }}
      InputProps={inputProps}
      error={hasError}
      fullWidth
      helperText={helperText}
      inputRef={maskRef}
      label={label}
      margin={margin}
      name={name}
      sx={{ marginBottom: '20px' }}
      onFocus={() => {
        if (!dirty) {
          setDirty(true);
        }
        setShrink(true);
      }}
      onBlur={(e) => {
        if (fieldValue?.length === 0) {
          setShrink(false);
        } else {
          setShrink(true);
        }
        setFirstBlur(true);
        onBlur(e);
      }}
      onChange={(e) => {
        if (dirty) {
          setValue(e.target.value);
        }
      }}
      placeholder={placeholder}
      size={size}
      type={type}
      value={fieldValue}
      variant="outlined"
      autoComplete="off"
    />
  );
};

CDInputText.defaultProps = {
  addCheck: false,
  countCharacters: 0,
  countCharactersRegexReplace: null,
  Icon: null,
  margin: 'dense',
  mask: '',
  customMask: null,
  placeholder: '',
  size: 'small',
  defaultHelperText: '',
  onChange: null,
  inputRef: null,
};

CDInputText.propTypes = {
  addCheck: PropTypes.bool,
  countCharacters: PropTypes.number,
  countCharactersRegexReplace: PropTypes.instanceOf(RegExp),
  Icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  mask: PropTypes.string,
  customMask: PropTypes.shape({
    mask: PropTypes.string.isRequired,
    blocks: PropTypes.shape({
      num: PropTypes.shape({
        mask: PropTypes.object,
        scale: PropTypes.number,
        signed: PropTypes.bool,
        thousandsSeparator: PropTypes.string,
        padFractionalZeros: PropTypes.bool,
        normalizeZeros: PropTypes.bool,
        radix: PropTypes.string,
        mapToRadix: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
  }),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string.isRequired,
  defaultHelperText: PropTypes.string,
  onChange: PropTypes.func,
  inputRef: PropTypes.any,
};

export default CDInputText;
