import errorDictionary from 'Constants/ErrorDictionary';

/* eslint-disable no-plusplus */
const toTitleCase = (str) => str
  .toLowerCase()
  .split(' ')
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');

export const validateEnv = (token = false) => {
  const {
    REACT_APP_J4, // Token OTP APIKEY
    REACT_APP_J5, // BFF APIKEY
  } = process.env;

  if (token) {
    return REACT_APP_J4;
  }
  return REACT_APP_J5;
};

export const searchErrorCodeRoute = (response) => {
  const errorMessage = response.data
  || response.validationErrorMessage
   || response.internalErrorMessage;

  if (!errorMessage) {
    return {
      errorCode: '500',
      errorRoute: '/fatalErrorCuenta',
    };
  }

  const errorCodeFromResponse = errorMessage.split('|')[0];

  // eslint-disable-next-line max-len
  const error = errorDictionary.find(({ fullErrorCode }) => fullErrorCode === errorCodeFromResponse);

  if (error) {
    return {
      errorCode: error.errorCode,
      errorRoute: error.errorRoute,
    };
  }
  return {
    errorCode: '500',
    errorRoute: '/fatalErrorCuenta',
  };
};

/* eslint-disable-next-line max-len */
export const isObjectEmpty = (objectName) => objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;

export function formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
  return value?.toLocaleString('en-US', { minimumFractionDigits, maximumFractionDigits });
}

export const calculatePerformanceAmmount = (
  selectedAmount,
  rateValue,
  selectedTermValue,
  selectedTypeValue,
) => {
  /* This calculation can be found in the ticket LDICFD-7:
  C: Represents the amount typed by the client that wants to deposit e.g DOP 100000
  t: Represents the rateValue in float point e.g 3% => 0.03
  n: Is the time period of return constant value 360
  m: Is the time of interest applied constant value 30
  u: Is the Term period selected by the client e.g client 3 months, 6 months */
  const C = selectedAmount;
  const t = rateValue / 100;
  const n = 360;
  const m = 30;
  const u = selectedTermValue;
  let returnValue = 0;

  if (selectedTypeValue === 'CAP') {
    const x = C * (1 + t / n) ** (m * u);
    returnValue = x;
  }
  if (selectedTypeValue === 'CRED') {
    const y = C * (1 + (t / 360) * (m * u));
    returnValue = y;
  }

  return returnValue;
};

export const formatData = (data) => {
  const cardsFormat = data.map(({
    accountId, accountNumber, productLine, currency,
  }) => {
    const cardType = productLine.indexOf('horro') >= 0 ? 'Cuenta de ahorro' : 'Cuenta corriente';
    return {
      value: accountId,
      label: `${accountNumber.slice(4)} ${cardType}`,
      accountCurrency: currency || 'DOP',
    };
  });

  return cardsFormat;
};

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  const truncatedText = text.slice(0, maxLength);
  const lastSpaceIndex = truncatedText.lastIndexOf(' ');
  if (lastSpaceIndex === -1) return `${truncatedText}...`;
  return `${truncatedText.slice(0, lastSpaceIndex)}...`;
};

export function formatNumber(amount, includeDecimals = false) {
  const [integerPartRaw, decimalPart] = amount.toFixed(2).split('.');

  const integerPart = integerPartRaw.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (!includeDecimals && decimalPart === '00') {
    return integerPart;
  }
  return `${integerPart}.${decimalPart}`;
}

export default toTitleCase;
